<template lang="pug">
.selectSwitch.d-flex.flex-column
  v-label(v-if="title") {{ title }}
  .selectSwitch__switch_container.rounded-pill.d-flex.justify-center.pa-1.align-center
    .selectSwitch__switch_values_selected.d-flex.justify-space-between.align-center.rounded-pill(
      :style="selectSwitch__switch_values_selected__style()"
    )
      .selectSwitch__switch_value.rounded-pill.d-flex.justify-center.align-center(
        :class="[`selectSwitch__switch_value${actualValue}`]",
        :style="selectSwitch__switch_values_selected__switch_value__style(actualValue)",
        v-if="title && val.name != ''",
        v-for="val in valuesList"
      ) {{ val.name }}
    .selectSwitch__switch_values.d-flex.justify-space-between.align-center
      .selectSwitch__switch_value.rounded-pill.d-flex.justify-center.align-center(
        :class="[`selectSwitch__switch_value${actualValue}`]",
        :style="selectSwitch__switch_values__switch_value__style(actualValue)",
        v-if="title && val.name != ''",
        v-for="val in valuesList",
        @click="commandToSend(val)"
      ) {{ val.name }}
</template>

<script>
import Vuex from "vuex";
export default {
  name: "Switch",
  data: () => ({ actualValue: 3 }),
  props: {
    title: {
      type: String,
      required: true,
    },
    valuesList: {
      type: Array,
      required: true,
    },
    command: {
      type: String,
      required: true,
    },
    onClick: {
      type: [Function],
      required: true,
    },
    widthSelectedBox: {
      type: String,
      default: "58",
    },
  },
  computed: {
    ...Vuex.mapGetters({
      getValueInStringCommand: "communication/commands/getValueInStringCommand",
    }),
    selectSwitch__switch_values_selected__style() {
      return () => {
        return {
          "--width-switch_values_selected": `${this.widthSelectedBox}px`,
          "--min-width-switch_values_selected": `${this.widthSelectedBox}px`,
          "--max-width-switch_values_selected": `${this.widthSelectedBox}px`,
        };
      };
    },
    selectSwitch__switch_values_selected__switch_value__style() {
      return (value) => {
        const widthSelectedBox = this.widthSelectedBox;
        let finalLeft = 0;
        switch (value) {
          case 1:
          case 0:
            finalLeft = `calc(${widthSelectedBox}px * 0)`;
            break;
          case 2:
          case 25:
            finalLeft = `calc(${widthSelectedBox}px * (-1))`;
            break;
          case 3:
          case 45:
            finalLeft = `calc(${widthSelectedBox}px * (-2))`;
            break;
          case 4:
          case 65:
            finalLeft = `calc(${widthSelectedBox}px * (-3))`;
            break;
          case 5:
          case 90:
            finalLeft = `calc(${widthSelectedBox}px * (-4))`;
            break;
          case 6:
            finalLeft = `calc(${widthSelectedBox}px * (-5))`;
            break;
          case 7:
            finalLeft = `calc(${widthSelectedBox}px * (-6))`;
            break;
          case 8:
            finalLeft = `calc(${widthSelectedBox}px * (-7))`;
            break;
          case 9:
            finalLeft = `calc(${widthSelectedBox}px * (-8))`;
            break;
          case 10:
            finalLeft = `calc(${widthSelectedBox}px * (-9))`;
            break;
          case 11:
            finalLeft = `calc(${widthSelectedBox}px * (-10))`;
            break;
          case 12:
            finalLeft = `calc(${widthSelectedBox}px * (-11))`;
            break;
          case 13:
            finalLeft = `calc(${widthSelectedBox}px * (-12))`;
            break;
          case 14:
            finalLeft = `calc(${widthSelectedBox}px * (-13))`;
            break;
          case 15:
            finalLeft = `calc(${widthSelectedBox}px * (-14))`;
            break;
          case 16:
            finalLeft = `calc(${widthSelectedBox}px * (-15))`;
            break;
          case 17:
            finalLeft = `calc(${widthSelectedBox}px * (-16))`;
            break;
          case 18:
            finalLeft = `calc(${widthSelectedBox}px * (-17))`;
            break;
          case 19:
            finalLeft = `calc(${widthSelectedBox}px * (-18))`;
            break;
          case 20:
            finalLeft = `calc(${widthSelectedBox}px * (-19))`;
            break;
          case 21:
            finalLeft = `calc(${widthSelectedBox}px * (-20))`;
            break;
          case 22:
            finalLeft = `calc(${widthSelectedBox}px * (-21))`;
            break;
          case 23:
            finalLeft = `calc(${widthSelectedBox}px * (-22))`;
            break;
          case 24:
            finalLeft = `calc(${widthSelectedBox}px * (-23))`;
            break;

          default:
            break;
        }
        const widthBox = this.selectSwitch__switch_values_selected__style();
        return {
          ...widthBox,
          "--left-switch_values_selected__switch_value": finalLeft,
        };
      };
    },
    selectSwitch__switch_values__switch_value__style() {
      return (value) => {
        const widthSelectedBox = this.widthSelectedBox;
        let finalLeft = 0;
        switch (value) {
          case 1:
          case 0:
            finalLeft = `calc(${widthSelectedBox}px * 2)`;
            break;
          case 2:
          case 25:
            finalLeft = `calc(${widthSelectedBox}px * 1)`;
            break;
          case 3:
          case 45:
            finalLeft = `calc(${widthSelectedBox}px * 0)`;
            break;
          case 4:
          case 65:
            finalLeft = `calc(${widthSelectedBox}px * (-1))`;
            break;
          case 5:
          case 90:
            finalLeft = `calc(${widthSelectedBox}px * (-2))`;
            break;
          case 6:
            finalLeft = `calc(${widthSelectedBox}px * (-3))`;
            break;
          case 7:
            finalLeft = `calc(${widthSelectedBox}px * (-4))`;
            break;
          case 8:
            finalLeft = `calc(${widthSelectedBox}px * (-5))`;
            break;
          case 9:
            finalLeft = `calc(${widthSelectedBox}px * (-6))`;
            break;
          case 10:
            finalLeft = `calc(${widthSelectedBox}px *  (-7))`;
            break;
          case 11:
            finalLeft = `calc(${widthSelectedBox}px *  (-8))`;
            break;
          case 12:
            finalLeft = `calc(${widthSelectedBox}px * (-9))`;
            break;
          case 13:
            finalLeft = `calc(${widthSelectedBox}px * (-10))`;
            break;
          case 14:
            finalLeft = `calc(${widthSelectedBox}px * (-11))`;
            break;
          case 15:
            finalLeft = `calc(${widthSelectedBox}px * (-12))`;
            break;
          case 16:
            finalLeft = `calc(${widthSelectedBox}px * (-13))`;
            break;
          case 17:
            finalLeft = `calc(${widthSelectedBox}px * (-14))`;
            break;
          case 18:
            finalLeft = `calc(${widthSelectedBox}px * (-15))`;
            break;
          case 19:
            finalLeft = `calc(${widthSelectedBox}px * (-16))`;
            break;
          case 20:
            finalLeft = `calc(${widthSelectedBox}px * (-17))`;
            break;
          case 21:
            finalLeft = `calc(${widthSelectedBox}px * (-18))`;
            break;
          case 22:
            finalLeft = `calc(${widthSelectedBox}px * (-19))`;
            break;
          case 23:
            finalLeft = `calc(${widthSelectedBox}px * (-20))`;
            break;
          case 24:
            finalLeft = `calc(${widthSelectedBox}px * (-21))`;
            break;
          default:
            break;
        }
        const widthBox = this.selectSwitch__switch_values_selected__style();
        return {
          ...widthBox,
          "--left-switch_values__switch_value": finalLeft,
        };
      };
    },
    commandValue() {
      return this.getValueInStringCommand(this.command);
    },
  },
  methods: {
    commandToSend(command) {
      this.actualValue = command.command.value;
      this.onClick(command);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
$selector_selcted_switch_width: 58px;

.selectSwitch {
  width: 100%;
  overflow: hidden;

  > .v-label {
    width: 100%;
    text-align: center;
  }
  > .selectSwitch__switch_container {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: var(--v-lGrey-base);
    * {
      font-size: 16px;
    }
    .selectSwitch__switch_values,
    .selectSwitch__switch_values_selected {
      position: absolute;
      width: 100%;
      height: 75%;
      color: var(--v-dGrey-base);
      font-weight: bold;
      background-color: transparent;
      .selectSwitch__switch_value {
        position: relative;
      }
    }
    // .selectSwitch__switch_values_selected,
    // .selectSwitch__switch_values_selected .selectSwitch__switch_value,
    // .selectSwitch__switch_values .selectSwitch__switch_value {
    //   min-width: $selector_selcted_switch_width;
    //   max-width: $selector_selcted_switch_width;
    //   width: $selector_selcted_switch_width;
    // }
    .selectSwitch__switch_values_selected,
    .selectSwitch__switch_values_selected .selectSwitch__switch_value,
    .selectSwitch__switch_values .selectSwitch__switch_value {
      min-width: var(--min-width-switch_values_selected);
      max-width: var(--max-width-switch_values_selected);
      width: var(--width-switch_values_selected);
    }
    .selectSwitch__switch_values {
      cursor: pointer;
      // .selectSwitch__switch_value9,
      // .selectSwitch__switch_value0 {
      //   left: calc(#{$selector_selcted_switch_width} * 2);
      // }
      // .selectSwitch__switch_value10,
      // .selectSwitch__switch_value25 {
      //   left: calc(#{$selector_selcted_switch_width} * 1);
      // }
      // .selectSwitch__switch_value11,
      // .selectSwitch__switch_value45 {
      //   left: calc(#{$selector_selcted_switch_width} * 0);
      // }
      // .selectSwitch__switch_value12,
      // .selectSwitch__switch_value65 {
      //   left: calc(#{$selector_selcted_switch_width} * (-1));
      // }
      // .selectSwitch__switch_value1,
      // .selectSwitch__switch_value90 {
      //   left: calc(#{$selector_selcted_switch_width} * (-2));
      // }
      // .selectSwitch__switch_value2 {
      //   left: calc(#{$selector_selcted_switch_width} * (-3));
      // }
      // .selectSwitch__switch_value3 {
      //   left: calc(#{$selector_selcted_switch_width} * (-4));
      // }
      .selectSwitch__switch_value9,
      .selectSwitch__switch_value0 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value10,
      .selectSwitch__switch_value25 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value11,
      .selectSwitch__switch_value45 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value12,
      .selectSwitch__switch_value65 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value1,
      .selectSwitch__switch_value90 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value2 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value3 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value4 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value5 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value6 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value7 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value8 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value13 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value14 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value15 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value16 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value17 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value18 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value19 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value20 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value21 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value22 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value23 {
        left: var(--left-switch_values__switch_value);
      }
      .selectSwitch__switch_value24 {
        left: var(--left-switch_values__switch_value);
      }
    }
    .selectSwitch__switch_values_selected {
      overflow: hidden;
      color: var(--v-lGrey-base);
      background-color: var(--v-dGrey-base);
      z-index: 10;
      // .selectSwitch__switch_value9,
      // .selectSwitch__switch_value0 {
      //   left: calc(#{$selector_selcted_switch_width} * 0);
      // }
      // .selectSwitch__switch_value10,
      // .selectSwitch__switch_value25 {
      //   left: calc(#{$selector_selcted_switch_width} * (-1));
      // }
      // .selectSwitch__switch_value11,
      // .selectSwitch__switch_value45 {
      //   left: calc(#{$selector_selcted_switch_width} * (-2));
      // }
      // .selectSwitch__switch_value12,
      // .selectSwitch__switch_value65 {
      //   left: calc(#{$selector_selcted_switch_width} * (-3));
      // }
      // .selectSwitch__switch_value1,
      // .selectSwitch__switch_value90 {
      //   left: calc(#{$selector_selcted_switch_width} * (-4));
      // }
      // .selectSwitch__switch_value2 {
      //   left: calc(#{$selector_selcted_switch_width} * (-5));
      // }
      // .selectSwitch__switch_value3 {
      //   left: calc(#{$selector_selcted_switch_width} * (-6));
      // }
      .selectSwitch__switch_value9,
      .selectSwitch__switch_value0 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value10,
      .selectSwitch__switch_value25 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value11,
      .selectSwitch__switch_value45 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value12,
      .selectSwitch__switch_value65 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value1,
      .selectSwitch__switch_value90 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value2 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value3 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value4 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value5 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value6 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value7 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value8 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value13 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value14 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value15 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value16 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value17 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value18 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value19 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value20 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value21 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value22 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value23 {
        left: var(--left-switch_values_selected__switch_value);
      }
      .selectSwitch__switch_value24 {
        left: var(--left-switch_values_selected__switch_value);
      }
    }
  }
}
</style>
