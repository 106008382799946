<template lang="pug">
BoxRoundedGrey.sceneSettings__.sceneSettings__diffuseDaylight
  v-card-title.pt-0 {{$t('sideBarLeft.scene.naturalDiffuseLight.name')}}
  SelfSwitch(
    :title="$t('sideBarLeft.scene.naturalDiffuseLight.withSky')",
    :valuesList="getValuesListDiffuseDaylight('WithSky')",
    :command="getCommandDiffuseDaylight('WithSky')",
    :onClick="setSceneCommand"
  )
  SelfSwitch(
    :title="$t('sideBarLeft.scene.naturalDiffuseLight.withHourAngle')",
    :valuesList="getValuesListDiffuseDaylight('WithHourAngle')",
    :command="getCommandDiffuseDaylight('WithHourAngle')",
    :onClick="setSceneCommand"
  )
  template(v-if="getValueDiffuseDaylight('WithHourAngle') == 'true'")
    SelectSwitch.mt-3(
      :title="$t('sideBarLeft.scene.hourAngle')",
      :valuesList="getValuesListDiffuseDaylight('SetHourAngle')",
      :command="getCommandDiffuseDaylight('SetHourAngle')",
      :onClick="setSceneCommand",
      :widthSelectedBox= "windowWidth > 1850 ? '66' : '50'"
    )
  
</template>

<script>
const inflection = require("inflection");
import Vuex from "vuex";

import SelfSwitch from "@/components/_actions/Switch.component";
import SelectSwitch from "@/components/_actions/SelectSwitch.component";
import BoxRoundedGrey from "@/components/_design/BoxRoundedGrey.component";

export default {
  name: "SceneSettings__DiffuseDaylight",

  components: { SelectSwitch, SelfSwitch, BoxRoundedGrey },
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  methods: {
    ...Vuex.mapActions({
      setSceneCommand: "scenes/setSceneCommand",
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      getInfoCommandScene: "scenes/getInfoCommand",
      getCommand: "communication/commands/getCommand",
    }),

    // DiffuseDaylightWithSky
    getValuesListDiffuseDaylight() {
      return (shortCommand) =>
        this.getInfoCommandScene(`diffuseDaylight${shortCommand}`).valuesList;
    },
    getCommandDiffuseDaylight() {
      return (shortCommand) =>
        this.getCommand(
          inflection.underscore(`diffuseDaylight${shortCommand}`)
        );
    },

    getValueDiffuseDaylight() {
      return (shortCommand) =>
        this.getCommand(
          inflection.underscore(`diffuseDaylight${shortCommand}`)
        ).replace(/^.+::(true|false)$/, "$1");
    },
  },
  async mounted() {},
};
</script>

<style lang="scss">
.sceneSettings__diffuseDaylight {
}
</style>