<template lang="pug">
v-slider(
  v-if="/right/.test(side)",
  persistent-hint,
  width="100%",
  min="-10",
  max="10",
  ticks,
  tick-size="",
  thumb-label,
  :thumb-size="24",
  dense,
  color="dGrey",
  track-color="gGrey",
  track-fill-color="gGrey",
  v-model="wearingParam.params.right",
  @change="changeParamsValuesRight",
  @mousedown="setCurrentParam(wearingParam)"
)
  template(v-slot:label)
    span.caption {{ label }}

v-slider(
  v-else-if="/left/.test(side)",
  persistent-hint,
  width="100%",
  min="-10",
  max="10",
  ticks,
  tick-size="1",
  thumb-label,
  :thumb-size="24",
  dense,
  color="dGrey",
  track-color="gGrey",
  track-fill-color="gGrey",
  v-model="wearingParam.params.left",
  @change="changeParamsValuesLeft",
  @mousedown="setCurrentParam(wearingParam)"
)
  template(v-slot:label)
    span.caption {{ label }}

v-slider(
  v-else-if="/both/.test(side)",
  persistent-hint,
  width="100%",
  min="-10",
  max="10",
  ticks,
  tick-size="1",
  thumb-label,
  :thumb-size="24",
  dense,
  color="dGrey",
  track-color="gGrey",
  track-fill-color="gGrey",
  v-model="wearingParam.params.both",
  @change="changeParamsValuesBoth",
  @mousedown="setCurrentParam(wearingParam)"
)
  template(v-slot:label)
    span.caption {{ label }}
</template>

<script>
import Vuex from "vuex";

export default {
  name: "SliderParam",

  components: {},
  props: {
    label: {
      type: String,
      require: true,
    },
    side: {
      type: String,
    },
    wearingParam: {
      type: Object,
    },
  },
  data: function () {
    return {
      // config: {
      //   "v-model": 8,
      //   "@change": "cPropsChangeParamsValues",
      //   "@mousedown": "setCurrentParam(cPropsWearingParam)",
      // }
    };
  },
  methods: {
    ...Vuex.mapActions({
      changeParamsValuesLeft: "clothes/changeParamsValuesLeft",
      changeParamsValuesRight: "clothes/changeParamsValuesRight",
      changeParamsValuesBoth: "clothes/changeParamsValuesBoth",
      setCurrentParam: "clothes/setCurrentParam",
    }),
  },
  computed: {},
};
</script>

<style lang="scss" scopped>
.caption {
  width: 70px;
  font-size: 11px;
  font-weight: bold;
}
</style>
