import utils from "@/utils/utils";
import OwnReplayService from "@/services/own/replays.service";

const rs = new OwnReplayService();


const state = {
  replay: {},
  replays: [],
  modeReplayRef: false, // false: modeSelectReplay | true: modeSelectReference
};

const getters = {
  /** REPLAYS */
  list: (state) => state.replays,
  newestReplay: (state) => (utils.sorting(state.replays, 'desc', { key: 'createdAt' }))[0],

  /** REPLAY */
  current: (state) => state.replay,
};

const mutations = {
  /** REPLAYS */
  ADD_REPLAY_TO_REPLAYS: (state, replay) => {
    state.replays.push(replay);
    state.replays = utils.sorting(state.replays, 'desc', { key: 'createdAt' })
  },
  SET_REPLAYS: (state, replays) => {
    state.replays = replays
    state.replays = state.replays && state.replays.length > 0
      ? utils.sorting(state.replays, 'desc', { key: 'createdAt' })
      : []
  },

  /** REPLAY */
  SET_EMPTY_REPLAY: (state) => { state.replay = {} },
  SET_CURRENT_REPLAY: (state, replay) => { state.replay = replay },
};


const actions = {
  addAReplay: async (state) => {
    const isAutoSelectRef = state.rootGetters['users/profils/replays/isAutoSelect']
    const replay = {
      name: utils.dateToCompactString(new Date()),
      createdAt: utils.dateToISOString(new Date())
    }

    const replayDbRes = await state.dispatch('postReplay', replay)
    const replayDb = replayDbRes.sessionReplays
    state.commit('ADD_REPLAY_TO_REPLAYS', replayDb)
    if (isAutoSelectRef) state.dispatch('users/profils/replays/setCurrentReference', replayDb, { root: true })
  },
  setListReplays: (state, replays) => {
    state.commit('SET_EMPTY_REPLAY')
    state.commit('users/profils/replays/SET_EMPTY_REFERENCE', null, { root: true })
    state.commit('SET_REPLAYS', replays)
    if (replays && replays.length > 0) state.dispatch('users/profils/replays/setCurrentReferenceByArray', replays, { root: true })
  },
  setCurrentReplay: (state, replay) => { state.commit('SET_CURRENT_REPLAY', replay) },

  /** DB REQUEST */
  postReplay: (state, replay) => {
    return new Promise((resolve, reject) => {
      const { id: idProfil } = state.rootGetters['users/profils/current']
      const typeReplay = 'session'

      rs.postByTypeReplay(idProfil, typeReplay, replay)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteReplays: async (state) => {
    const { id: idProfil } = state.rootGetters['users/profils/current']
    const replays = state.rootGetters['users/profils/replays/sessions/list']
    const typeReplay = 'session'

    for (const replay of replays)
      await rs.delete(idProfil, typeReplay, replay.id)

    state.commit('SET_REPLAYS', [])
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};