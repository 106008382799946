<template lang="pug">
.replays__wraper
  v-card-title.d-flex
    span.mr-auto Replays
    v-icon.mr-3(@click="addASessionReplay") mdi-plus-thick
    v-icon(v-if="sessionReplayList.length > 0", @click="toggleModeReplayRef") {{ modeReplayRef ? `mdi-movie-open-edit` : `mdi-movie-open-edit-outline` }}
    v-icon(v-else, color="lGrey") mdi-movie-open-off-outline

  template(v-if="modeReplayRef")
    .replays-content.d-flex.flex-column.justify-space-between.align-center.px-4
      AutoSelectRefSwitch(:onClick="toggleAutoReference")
      v-divider.pt-2.pb-5(width="75%")
      template(v-for="(replay, n) in sessionReplayList")
        CardWithChipReplay(
          :replay="replay",
          :key="n",
          @mousedown="replay == sessionReplayCurrent ? setCurrentSessionReplay(replay) : null"
        )

  template(v-else)
    .replays-content.d-flex.flex-wrap.justify-space-between.px-4(
      v-if="sessionReplayList && sessionReplayList.length > 0"
    )
      template(v-for="(replay, n) in sessionReplayList") 
        CardWithChipReplay(
          :replay="replay",
          :key="n",
          @mousedown="replay == sessionReplayCurrent ? setCurrentSessionReplay(replay) : null"
        )

    .replays-content__defaultMsg.d-flex.align-center.justify-center.px-4(
      v-else
    )
      v-card-text.text-center {{ $t('sideBarLeft.emptyReplay') }}
</template>

<script>
import Vuex from "vuex";

import CardWithChipReplay from "@/components/_design/CardWithChipReplay.component";
import AutoSelectRefSwitch from "@/components/visualisation/replays/AutoSelectRefSwitch.component";

const rootStateReplay = "users/profils/replays";
const rootStateSessionReplay = `${rootStateReplay}/sessions`;
// const rootStateSavedReplay = `${rootStateReplay}/saved`

export default {
  name: "Replays",

  components: { CardWithChipReplay, AutoSelectRefSwitch },
  data: () => ({}),
  methods: {
    ...Vuex.mapActions({
      addASessionReplay: `${rootStateSessionReplay}/addAReplay`,
      setCurrentSessionReplay: `${rootStateSessionReplay}/setCurrentReplay`,
      toggleModeReplayRef: `${rootStateReplay}/toggleModeReplayRef`,
      toggleModeReplayRefOff: `${rootStateReplay}/toggleModeReplayRefOff`,
      toggleAutoReference: `${rootStateReplay}/toggleAutoReference`,
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      sessionReplayList: `${rootStateSessionReplay}/list`,
      sessionReplayCurrent: `${rootStateSessionReplay}/current`,
      modeReplayRef: `${rootStateReplay}/modeReplayRef`,
    }),
  },
  beforeRouteLeave(to, from, next) {
    this.toggleModeReplayRefOff();
    next();
  },
};
</script>

<style lang="scss">
.replays__wraper {
  height: calc(100% - 58px);
  overflow: hidden;
  .replays-content__defaultMsg {
    height: calc(100% - 56px);
  }
  .replays-content {
    .v-divider {
      flex: none;
    }
  }
  .replay {
    width: 100%;
    margin: 0 0 4% 0;
    .v-image,
    .v-icon {
      height: 100px;
    }
  }
}
</style>


