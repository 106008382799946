<template lang="pug">
  div.d-flex.flex-column.justify-center.align-center 
      v-img.mb-8( src="@/assets/logos/MODALIVE-2021_RVB_VERSION-M.png" width="255px" )
      v-card-title {{$t("login.title")}}
      v-card-actions.d-flex.flex-column 
        v-form.d-flex.flex-column.justify-center.align-center
          v-text-field.rounded-lg(
            v-model="dataToLogin.email"
            type="email"
            label="Email"
            :rules="rules.email"
            outlined 
            dense
            required
          )
          v-text-field.rounded-lg(
            v-model="dataToLogin.password"
            label="Password"
            :rules="rules.password"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            outlined 
            dense
            required
            :error="globalError ? true : false"
            :error-messages="globalError ? globalError.name : null"
            @click:append="passwordShow = !passwordShow"
          )
          Button(
            :onClick="validation"
          )
            span {{Connexion}}
          p.forgot_password {{$t("login.forgetPassword")}}
            router-link(to="home") {{$t("login.clickHere")}}
        v-divider.my-3(width="100%" )
        v-card-text.d-flex.flex-column.justify-center.align-center.countRequest
          | {{$t("login.noAccount")}}
          br
          router-link(to="home") {{$t("login.request")}}
          
  </template>
  
  <script>
    import Vuex from 'vuex';
  
  import Button from '@/components/_actions/Button.component';
  
  export default {
    name: 'Login',
    components: {
      Button
    },
    data: () => ({
      Connexion: "Connexion",
      passwordShow: false,
      validCredential: false,
      dataToLogin: {
        email: localStorage.getItem("userEmail"),
        password: ""
      },
      rules: {
        email: [
          (v) => !!v || 'Identifiant obligatoire',
        ],
        mdp: [
          (v) => !!v || 'Mot de passe obligatoire',
        ]
      }
      
    }),
    computed: {
      ...Vuex.mapGetters({
        globalError: 'globalError',
        statusUser: 'users/status'
      })
    },
    methods: {
      validation() {
        try {
          this.$store.dispatch('users/login', this.dataToLogin);
          this.$router.push({ name: "Visualisation" });
        }
        catch(err){
          console.log("----------error in login------------:", err);
        }
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .forgot_password{ font-size: 12px; }
  .v-text-field{ width: 350px; }
  .v-btn{ width: 250px; }
  .countRequest{ text-align: center; }
  a{
    color: black !important;
    font-weight: bold;
  }
  .v-card__title { //mise sur une ligne du texte
    text-align: center;
    width: 300px;
  }
  @media (max-width: 500px) { //adaptation pour les petits écrans (téléphone) du cadre d'email et password
      .v-text-field{
        width: 300px;
      }
  }
  @media (max-height: 700px) { //adaptation pour les petits écrans (téléphone) du cadre d'email et password en paysage
      .v-text-field{
        width: 300px;
      }
      .mb-8 { //ne pas afficher le logo par manque de place
        display:none;
      }
      div {
        height:50px;
      }
  }
  </style>
  