<template lang="pug">
  v-btn.rounded-pill.elevation-0(
    :class="[size]"
    v-if="onClick"
    color="secondery3"
    @click="onClick"
  )
    slot
  v-btn.rounded-pill.elevation-0( 
    :class="[size]"
    v-else
    color="secondery3"
  )
    slot
</template>

<script>


export default {
  name: 'Button',
  props:{
    onClick:{
      type: Function
    },
    size: {
      type: String,
    }
  },
  data: () => ({}),
  methods: {}
};
</script>

<style lang="scss" scoped>
  .xSmall{
    border-radius: 8px !important;
    font-size: 10px !important;
    height: 16px !important;
  }
  .small{
    border-radius: 12px !important;
    font-size: 12px !important;
    height: 24px !important;
  }
  // .medium{ }
  .large{
    border-radius: 27px !important;
    font-size: 16px !important;
    height: 54px !important;
  }
  .xLarge{
    border-radius: 33px !important;
    font-size: 18px !important;
    height: 66px !important;
  }
</style>
