<template lang="pug">
BorderGradient.clothesParams.ma-2(noPadding)
    v-tabs(show-arrows)
      v-tab(
        v-for="cloth in selectedClothProducts",
        :key="cloth.id",
        @mousedown="saveParamsBeforeChangeTab",
        @click="setCurrentCloth(cloth)"
      )
        span {{ cloth.name }}

    div(v-if="selectedClothes.length > 0")
      v-card.elevation-0.info.mb-5(width="80%", outlined)
        .size
          h3.ml-3 Couleur
          .items
            .item(
              v-for="color in availableSizesAndColors.colors",
              :key="color",
              :style="colorStyle(color)",
              @click="setSelectedColor(color)"
            )
          h3.ml-3 Taille
          .items
            .item(
              v-for="size in availableSizesAndColors.sizes",
              :key="size",
              :style="sizeStyle(size)",
              @click="setSelectedSize(size)"
            )
              .size-item(:style="{ color: textSizeStyle(size) }") {{ size }}
        v-switch.ml-3(:color="colors.magenta", v-model="settingsMove", label="déplacer le vêtement", @change="onChange")

    div(v-else class="d-flex align-center justify-center px-5")
      v-card-text.text-center {{$t('sideBarRight.noSelection')}}
</template>

<script>
import Vuex from "vuex";
// import Button from "@/components/_actions/Button.component";

import ClothParamsSelector from "@/components/visualisation/cloakroom/clothesParams/ClothParamsSelector.component";

import BorderGradient from "@/components/_design/BorderGradient.component";

export default {
  name: "ClothParamsCard",

  components: { ClothParamsSelector, BorderGradient },
  data: () => ({
    colors: {
      bad: '#E84B65',
      good: '#00F6A8',
      // magenta: '#ff00ff',
      // black: '#000000',
      blackalt: '#010220',
      // white: '#FFFFFF',
  red: '#FF0000',
  green: '#008000',
  blue: '#0000FF',
  bleu: '#0000FF',
  yellow: '#FFFF00',
  cyan: '#00FFFF',
  magenta: '#FF00FF',
  white: '#FFFFFF',
  blanc: '#FFFFFF',
  black: '#000000',
  gray: '#808080',
  orange: '#FFA500',
  pink: '#FFC0CB',
  purple: '#800080',
  teal: '#008080',
  lime: '#00FF00',
  brown: '#A52A2A',
  lavender: '#E6E6FA',
  coral: '#FF7F50',
  olive: '#808000',
  turquoise: '#40E0D0',
  indigo: '#4B0082',
  maroon: '#800000',
  gold: '#FFD700',
  navy: '#000080',
  silver: '#C0C0C0',
  beige: '#F5F5DC',
  sienna: '#A0522D',
  plum: '#DDA0DD',
  tan: '#D2B48C',
  violet: '#EE82EE',
  aqua: '#00FFFF',
  chocolate: '#D2691E',
  khaki: '#F0E68C',
  salmon: '#FA8072',
  tomato: '#FF6347',
  honeydew: '#F0FFF0',
  moccasin: '#FFE4B5',
  bisque: '#FFE4C4'
},


    // availableColors: ['#E84B65', '#00F6A8', '#010220'],
    selectedColor: '#E84B65',
    // availableSizes: ['XS', 'S', 'L'],
    selectedSize: 'S',
    CurrentWorkingCloth: null,

  }),
  methods: {
    colorStyle(color) {
      return {
        backgroundColor: color,
        border: color === this.selectedColor ? `${this.colors.magenta} solid 3px` : 'black solid 3px',
      };
    },
    sizeStyle(size) {
      return {
        border: size === this.selectedSize ? `${this.colors.magenta} solid 3px` : 'black solid 3px',
      };
    },
    textSizeStyle(size) {
      return size === this.selectedSize ? this.colors.magenta : 'black';
    },
    // setSelectedColor(color) {
    //   // Set selected color
    // },
    // setSelectedSize(size) {
    //   // Set selected size
    // },
    ...Vuex.mapActions({
      setCurrentCloth: "clothes/setCurrentCloth",
      saveParamsBeforeChangeTab: "clothes/saveParamsBeforeChangeTab",
      sizePlus: "clothes/upSize", //TODO
      sizeLess: "clothes/downSize", //TODO
      setSettingsMove: "states/setSettingsMove",
      setSettingsZoom: "states/setSettingsZoom",
      setAsSelectedCloth: 'clothes/selectCloth',
    }),

    ...Vuex.mapActions([
      'addToShoppingList',
      'deleteFromShoppingList',
      'updateInShoppingList'
    ]),

    setCurrentWorkingCloth(size, color) {
      console.log('size : ' + size);
      console.log('color : ' + color);

        this.selectedClothProducts.forEach(product => {
          product.variants.forEach(variant => {
            const [variantSize, , variantColorName] = variant.title.split(' ');
            // alert(variantColorName)
            const variantColor = this.colors[variantColorName.toLowerCase()];
            if (variantSize === size || variantColor === color) {
              this.CurrentWorkingCloth = variant; // Set the current working cloth to this variant
              this.$store.dispatch('updateCurrentWorkingClothStore', variant);
              console.log("==================currentWorkingCloth==============", this.CurrentWorkingClothStore);
            } else {
              // alert("================")
              console.log('variantSize : ' + variantSize);
              // console.log('size : ' + size);
              console.log('variantColor : ' + variantColor);
              // console.log('color : ' + color);
            }
          });
        });
      },

      setSelectedColor(color) {
        this.selectedColor = color;
        this.setCurrentWorkingCloth(this.selectedSize, color);
      },

      setSelectedSize(size) {
        this.selectedSize = size;
        this.setCurrentWorkingCloth(size, this.selectedColor);
      },

    onChange() {
      if (this.settingsZoom && !this.settingsMove) {
        this.setSettingsZoom(!this.settingsZoom);
      }
      this.setSettingsMove(!this.settingsMove);
    },
    addToShopify(variant) {
    this.$store.dispatch('addProduct', variant);
  },

  },
  computed: {
    ...Vuex.mapGetters({
      currentCloth: "clothes/current",
      selectedClothes: "clothes/selectedList",
      settingsMove: "states/settingsMove",
      settingsZoom: "states/settingsZoom",
    }),
    ...Vuex.mapState(['productList', 'fromShop', 'shopCustomer', 'CurrentWorkingClothStore', 'ShoppingList']),

  selectedClothProducts() {
    // alert("ok");
    return this.selectedClothes.map(cloth => {
      // alert("ok 2");
      const productKey = Object.keys(this.productList).find(key => {
        return this.productList[key].variants.some(variant => variant.sku === cloth.barCode);
      });

      // alert("ok 2");
      console.log('====================================');
      console.log(productKey);
      console.log('====================================');

      const product = this.productList[productKey];
      // alert("ok 3");

      console.log('====================================');
      console.log("product -> ", product);
      console.log('====================================');

      // alert("ok 4");

      if(product) {
        return {
          ...cloth,
          ...product,
          size: product.variants.map(variant => variant.title.split(' ')[0]), // Extract size
          color: product.variants.map(variant => this.colors[variant.title.split(' ')[2].toLowerCase()]) // Map color name to hex
        };
      }
      // alert("ok 5");

      console.log('====================================');
      console.log('cloth -> ', cloth);
      console.log('====================================');
      return cloth;
    });
  },

  availableSizesAndColors() {
    let sizes = new Set();
    let colors = new Set();

    this.selectedClothProducts.forEach(product => {
      product.variants.forEach(variant => {
        const [size, , colorName] = variant.title.split(' ');


        if (!size) {
          // size = 'defaultSize';
          sizes.add('DS')
        } else sizes.add(size);

        if (!colorName) {
          colors.add('white');
        } else colors.add(this.colors[colorName.toLowerCase()]);

        // sizes.add(size);
        // if (this.colors[colorName.toLowerCase()]) {
        //   colors.add(this.colors[colorName.toLowerCase()]);
        // }
      });
    });

    if (this.CurrentWorkingCloth == null) {
      this.setSelectedColor(Array.from(colors)[0])
      this.setSelectedSize(Array.from(sizes)[0])
      this.setCurrentWorkingCloth(Array.from(sizes)[0], Array.from(colors)[0])
    }

    return {
      sizes: Array.from(sizes),
      colors: Array.from(colors),
    };
  },
  },
  watch: {
  selectedClothes(newValue, oldValue) {
    // Perform actions whenever selectedClothes changes
    console.log('selectedClothes changed', newValue);
    console.log('selectedClothes changed - oldValue', oldValue);
    // You can perform any check or action here
  }
},

};
</script>




<style lang="scss" scoped>


$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

.clothesParams {
  height: calc((100% * 3 / 4) - 16px);
  .tabContent {
    height: 100%;
  }
  .undressBtn {
    width: 95%;
    position: absolute;
    bottom: 10px;
    left: calc((100% - 95%) / 2);
  }
}

.items {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 10px;

  .item {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 5px;
    cursor: pointer;
  }
}

.container {
  display: inline-block;
  background-color: $blackalt;
  color: $white;
  padding: 33px;
  border-radius: 10px;
  text-align: center;

  .title {
    text-align: center;
  }

  .sections {
    display: flex;
    align-items: center;
    padding: 10px;

  }

  p {
    max-height: 100px;
    overflow: auto;
  }


  .close {
    text-align: end;
  }

  .right {
    margin-left: 30px;
    max-width: 200px;
    text-align: left;
  }

  .close {
    cursor: pointer;
  }
}

.overlay {
  z-index: 9999;
}

.size-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sizeLeft  {
    left: 20%;
    position: relative;
  }
  .sizeRight {
    left:40%;
    position: relative;
  }
</style>
