import ApiService from './api.service';

class ClothesService extends ApiService {
  constructor() {
    super({})
  }

  getTypes() {
    return super.doRequest('get', '/clothTypes');
  }


  gets() {
    return super.doRequest('get', '/clothes');
  }
  getsByBarCode(barCode) { //TODO
    return super.doRequest('get', `/clothe/${barCode}`);
  }

  getsByClothTypes(type) {
    return super.doRequest('get', `/clothes/${type}`);
  }

  getCloakroom(idProfil) {
    // return super.doRequest('get', `/user/${idProfil}/cloakroom/clothes`);
    // return super.doRequest('get', `/user/is/exist?idUser=${idProfil}`)

    return super.doRequest('get', `/own/profil/${idProfil}/cloakroom/clothes`);
  }

  postCloakroom(idProfil, idClothe) { //TODO
    return super.doRequest('post', `/own/profil/${idProfil}/cloakroom/cloth/${idClothe}`);
  }
  
  deleteClotheInCloakroom(idProfil,clothe) { //TODO
    return super.doRequest('delete', `/own/profil/${idProfil}/cloakroom/cloth/${clothe.id}`)
  }

  patchParams(idProfil, cloth) {
    return super.doRequest('patch', `/own/profil/${idProfil}/cloakroom/cloth/${cloth.id}`, { data: { wearingParams: cloth.wearingParams } });
  }

  get(id) {
    return super.doRequest('get', `/cloth/${id}`);
  }

  post(data) {
    return super.doRequest('post', '/clothes', { data });
  }

  put(id, data) {
    return super.doRequest('put', `/cloth/${id}`, { data });
  }

  delete(id) {
    return super.doRequest('delete', `/cloth/${id}`);
  }

  deleteByBarCode(barCode) { //TODO
    return super.doRequest('delete', `/cloth/barCode/${barCode}`);
  }

  getsFake() {
    return super.doRequest('get', '/fakeDatas/clothes.json');
  }

  getSizes(garmentReference) { //TODO
    return super.doRequest('get', `/clothes/garmentReference/${garmentReference}`);
  }

}

export default ClothesService;
