<template lang="pug">
BoxRoundedGrey.sceneSettings__.sceneSettings__directSunligth
  v-card-title.pt-0 {{$t('sideBarLeft.scene.directSunlight')}}
  SelectSwitch(
    :title="$t('sideBarLeft.scene.inclination')",
    :valuesList="getValuesListDirectSunlight('SetInclinason')",
    :command="getCommandDirectSunlight('SetInclinason')",
    :onClick="setSceneCommand",
    :widthSelectedBox= "windowWidth > 1850 ? '66' : '50'"
  )
  SelectSwitch.mt-3(
    :title="$t('sideBarLeft.scene.hourAngle')",
    :valuesList="getValuesListDirectSunlight('SetHourAngle')",
    :command="getCommandDirectSunlight('SetHourAngle')",
    :onClick="setSceneCommand",
    :widthSelectedBox= "windowWidth > 1850 ? '66' : '50'"
  )
</template>

<script>
const inflection = require("inflection");

import Vuex from "vuex";

import SelfSwitch from "@/components/_actions/Switch.component";
import SelectSwitch from "@/components/_actions/SelectSwitch.component";
import BoxRoundedGrey from "@/components/_design/BoxRoundedGrey.component";

export default {
  name: "SceneSettings__DirectSunligth",

  components: { SelectSwitch, SelfSwitch, BoxRoundedGrey },
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  methods: {
    ...Vuex.mapActions({
      setSceneCommand: "scenes/setSceneCommand",
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      getInfoCommandScene: "scenes/getInfoCommand",
      getCommand: "communication/commands/getCommand",
    }),

    // DirectSunlightSetInclinason
    getValuesListDirectSunlight() {
      return (shortCommand) =>
        this.getInfoCommandScene(`directSunlight${shortCommand}`).valuesList;
    },
    getCommandDirectSunlight() {
      return (shortCommand) =>
        this.getCommand(inflection.underscore(`directSunlight${shortCommand}`));
    },
  },
  async mounted() {},
};
</script>

<style lang="scss">
</style>