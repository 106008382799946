<template lang="pug"> <!--TODO-->
.expositionAll
  BoxRoundedGrey
    p Exposition 
    .expositionDiv
      v-slider(
        v-model="expositionCurseur"
        ticks="always"
        step="1"
        :min="-6"
        :max="0"
        tick-size="5"
        width="100%"
        @change="change"
        )
          
    p {{exposition}} 
    br
    p {{explication}}

</template>

<script>
import BoxRoundedGrey from "@/components/_design/BoxRoundedGrey.component";

export default {
  name: "ExpositionLight",
  components: { BoxRoundedGrey },
  data: () => ({
      expositionCurseur:-3,
      exposition:0.25,
      explication:"pièce lumineurse murs blancs",
  }),
  methods: {
    change() { 
      this.exposition = 250*(Math.pow(10,this.expositionCurseur)) 
      switch (this.exposition) {
        case 0.00025 : this.explication = "pièce sombre faible éclairage"; break;
        case 0.0025 : this.explication = "pièce sombre, lumière moyenne"; break;
        case 0.025 : this.explication = "pièce lumineuse murs sombres"; break;
        case 0.25 : this.explication = "pièce lumineuse murs blancs"; break;
        case 2.5 : this.explication = "éclairage extérieur ciel nuageux"; break;
        case 25 : this.explication = "lumière du zénith ville"; break;
        case 250 : this.explication = "lumière du zénith sur plage de sable fin"; break;
      }
      
      }
  }
}

</script>

<style lang="scss">

.expositionAll {
  width : 95%;
  top:30px;
  position:relative;
}
</style>