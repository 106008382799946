<template lang="pug">
.card-chip.disabled(
  v-if="modeReplayRef && referenceCurrent.isAutoSelect == true"
)
  v-chip.disabled(
    v-if="referenceCurrent.ref.isReference && referenceCurrent.ref.createdAt == replay.createdAt",
    x-small,
    color="dGrey",
    text-color="white"
  )
    span Référence

  v-card.pl-4.mb-5.d-flex(
    outlined,
    :class="[referenceCurrent.ref.isReference && referenceCurrent.ref.createdAt == replay.createdAt ? 'referenceActive' : '']"
  )
    v-icon mdi-video
    v-card-title.py-1 {{ replay.name }}

.card-chip(v-else-if="modeReplayRef && referenceCurrent.isAutoSelect == false")
  v-chip(
    v-if="referenceCurrent.ref.isReference && referenceCurrent.ref.createdAt == replay.createdAt",
    x-small,
    color="dGrey",
    text-color="white"
  )
    span Référence

  v-card.pl-4.mb-5.d-flex(
    outlined,
    :class="[referenceCurrent.ref.isReference && referenceCurrent.ref.createdAt == replay.createdAt ? 'referenceActive' : '']",
    @click="selectReference(replay)"
  )
    v-icon mdi-video
    v-card-title.py-1 {{ replay.name }}
    v-icon mdi-play

.card-chip(v-else)
  v-chip(
    v-if="referenceCurrent.ref.isReference && referenceCurrent.ref.createdAt == replay.createdAt",
    x-small,
    color="dGrey",
    text-color="white"
  )
    span Référence

  v-icon.rounded-circle.selectedMark {{ replayCurrent && replayCurrent.createdAt == replay.createdAt ? 'mdi-play-circle-outline' : '' }}
  v-card.pl-4.mb-5.d-flex(
    outlined,
    :class="[referenceCurrent.ref.isReference && referenceCurrent.ref.createdAt == replay.createdAt ? 'referenceActive' : '', replayCurrent && replayCurrent.createdAt == replay.createdAt ? ['elevation-5', 'replayActive'] : '']",
    @click="selectReplay(replay)"
  )
    v-icon mdi-video
    v-card-title.py-1 {{ replay.name }}
</template>

<script>
import Vuex from "vuex";

const rootStateReplay = "users/profils/replays";
const rootStateSessionReplay = `${rootStateReplay}/sessions`;
// const rootStateSavedReplay = `${rootStateReplay}/saved`

export default {
  name: "CardWithChipReplay",
  props: {
    replay: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    ...Vuex.mapActions({
      selectReference: `${rootStateReplay}/setCurrentReference`,
      selectReplay: `${rootStateSessionReplay}/setCurrentReplay`,
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      replayCurrent: `${rootStateSessionReplay}/current`,
      referenceCurrent: `${rootStateReplay}/reference`,
      modeReplayRef: `${rootStateReplay}/modeReplayRef`,
    }),
  },
  async mounted() {
    if (this.replay.isReference)
      this.$store.dispatch(
        "users/profils/replays/setCurrentReference",
        this.replay,
        { root: true }
      );
  },
};
</script>

<style lang="scss" scopped>
// .disabled{
//     position: relative;
//     &::after{
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       background-color: rgba(255,255,255,.75);
//     }
//   }

.card-chip {
  width: 100%;
  position: relative;
  .v-chip {
    * {
      font-weight: bold;
    }
    position: absolute;
    z-index: 100;
    top: -10px;
    left: 16px;
  }
  .selectedMark {
    background-color: var(--v-white-base);
    position: absolute;
    z-index: 100;
    top: -8px;
    right: -8px;
  }
  .referenceActive {
    border: 1px solid var(--v-dGrey-base);
  }
  .replayActive {
    * {
      font-weight: bolder;
    }
    border: 2px solid var(--v-dGrey-base);
  }
}
</style>
