<template lang="pug">
  div.autoSelectRef.mb-5.d-flex.flex-column
    v-label Auto Select Reference
    div.autoSelectRef__switch.rounded-pill.d-flex.justify-space-between.pa-1(
      @click="onClick"
    )
      div.switch.rounded-pill.d-flex.justify-center.align-center On
      div.switch.rounded-pill.d-flex.justify-center.align-center Off
      div.switch.selector.rounded-pill(
        :class="[`selector__${isAutoSelectRef ? 'on' : 'off'}`]"
      )

</template>

<script>
  import Vuex from 'vuex';

  export default {
    name: 'AutoSelectRefSwitch',
    props:{
      onClick: {
        type: Function
      }
    },
    data: () => ({ }),
    methods: {}, 
    computed: {
      ...Vuex.mapGetters({
        isAutoSelectRef: 'users/profils/replays/isAutoSelect'
      })
    }
  };
</script>

<style lang="scss" scoped>
  .autoSelectRef{
    width: 100%;
    >.v-label{
      width: 100%;
      text-align: center;
    }
    >.autoSelectRef__switch{
      position: relative;
      width: 100%;
      height: 38px;
      background-color: var(--v-lGrey-base);
      .switch{
        position: relative;
        z-index: 10;
        width: 49%;
        height: 100%;
        color: var(--v-lGrey-base);
        font-weight: bold;
        background-color: transparent;
        // background-color: var(--v-dGrey-base);
        &.selector{
          z-index: 5;
          width: calc(49% - 4px);
          height: calc(100% - (2 * 4px));
          position: absolute;
          background-color: var(--v-dGrey-base);
          &.selector__on{ left: 4px; }
          &.selector__off{ right: 4px; }
        }
      }
    }
  }
</style>
