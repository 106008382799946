import { render, staticRenderFns } from "./clothParams.component.vue?vue&type=template&id=70d48709&scoped=true&lang=pug&"
import script from "./clothParams.component.vue?vue&type=script&lang=js&"
export * from "./clothParams.component.vue?vue&type=script&lang=js&"
import style0 from "./clothParams.component.vue?vue&type=style&index=0&id=70d48709&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70d48709",
  null
  
)

export default component.exports