//import getFromLocalStorage from "../../../utils/utils";
const state = {
    webrtcREADY: null,
    processingIdle: true,
    morphoIdle: true,
    FPS: null,
    lightOK: null,
    framingOK: null,

    allAiInitialized: true,
    results: 'badResults',
    connexionLoading: true,
    timerCompute: 10,
    timer: 30,
    timerMin: 25,
    timerMax: 35,
    errorFraming: false,
    errorLight: true,
    errorNetwork: false,
    xyMoveNormalized: { x: 0, y: 0 },
    xyZoomNormalized: { x: 0, y: 0 },
};
  
const getters = {
    GET_WEBRTCREADY: state => state.webrtcREADY,
    GET_PROCESSINGIDLE: state => state.processingIdle,
    GET_MORPHOIDLE: state => state.morphoIdle,
    GET_FPS: state => state.FPS,
    GET_LIGHTOK: state => state.lightOK,
    GET_FRAMINGOK: state => state.framingOK,

    allAiInitialized: state => state.allAiInitialized,
    results: state => state.results,
    connexionLoading: state => state.connexionLoading,
    timerCompute: state => state.timerCompute,
    timer: state => state.timer,
    timerMin: state => state.timerMin,
    timerMax: state => state.timerMax,
    errorFraming: state => state.errorFraming,
    errorLight: state => state.errorLight,
    errorNetwork: state => state.errorNetwork,
    xyMoveNormalized: state => state.xyMoveNormalized,
    xyZoomNormalized: state => state.xyZoomNormalized,

};
  
const mutations = {
    SET_WEBRTCREADY: (state,newState) => {state.webrtcREADY = newState},
    SET_PROCESSINGIDLE: (state,newState) => {state.processingIdle = newState},
    SET_MORPHOIDLE: (state,newState) => {state.morphoIdle = newState},
    SET_FPS: state => state.FPS,
    SET_LIGHTOK: (state,newState) => {state.lightOK = newState},
    SET_FRAMINGOK: (state, newState) => { state.framingOK = newState },
    
    SET_ALL_AI_INITIALIZED: (state, allAiInitialized) => { state.allAiInitialized = allAiInitialized },
    SET_RESULTS: (state, results) => { state.results = results },
    SET_CONNEXION_LOADING: (state, connexionLoading) => { state.connexionLoading = connexionLoading },
    SET_TIMER_COMPUTE: (state, timerCompute) => { state.timerCompute = timerCompute },
    SET_TIMER: (state, timer) => { state.timer = timer },
    SET_TIMER_MIN: (state, timerMin) => { state.timerMin = timerMin },
    SET_TIMER_MAX: (state, timerMax) => { state.timerMax = timerMax },
    SET_ERROR_FRAMING: (state, errorFraming) => { state.errorFraming = errorFraming },
    SET_ERROR_LIGHT: (state, errorLight) => { state.errorLight = errorLight },
    SET_ERROR_NETWORK: (state, errorNetwork) => { state.errorNetwork = errorNetwork },
    SET_XY_MOVE_NORMALIZED: (state, xyMoveNormalized) => { state.xyMoveNormalized = xyMoveNormalized },
    SET_XY_ZOOM_NORMALIZED: (state, xyZoomNormalized) => { state.xyZoomNormalized = xyZoomNormalized },
};

const actions = {
    setConnexionLoading({ commit }, connexionLoading) {
        commit('SET_CONNEXION_LOADING', connexionLoading)
    },

    setTimerCompute({ commit }, timerCompute) {
        commit('SET_TIMER_COMPUTE', timerCompute)
    },

    setTimer({ commit }, timer) {
        commit('SET_TIMER', timer)
    },

    setErrorLight({ commit }, errorLight) {
        commit('SET_ERROR_LIGHT', errorLight)
    },

    setXyMoveNormalized({ commit }, xyMoveNormalized) {
        commit('SET_XY_MOVE_NORMALIZED', xyMoveNormalized)
        // TODO: envoyer les coordonnées au serveur
    },

    setXyZoomNormalized({ commit }, xyZoomNormalized) {
        commit('SET_XY_ZOOM_NORMALIZED', xyZoomNormalized)
        // TODO: envoyer les coordonnées au serveur
    }


};
  
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};