<template lang="pug">
.all
  BorderGradient(noPadding=true).all
    .instructions
      .container 
        .box
          h3 {{ upperCase($t("results.title")) }}
          br
          span {{ this.getMessage() }}
            .link(@click="remind()" v-if="this.state === 'badResults'") {{ $t('results.remind') }}
          br
          br
          .btn-group 
            v-btn(v-if="this.state === 'badResults'" @click="retry()") {{ $t('results.retry') }}
            v-btn(v-if="this.state === 'inactivity'" @click="retry()") {{ $t('results.resume') }}
                    

</template>

<script>
import BorderGradient from "@/components/_design/BorderGradient.component";


export default {
  name: "results",
  props: ['state', 'upperCase'],


  components: {
    BorderGradient,
  },
  data: () => ({
    messages: {
      success: "Félicitations ! Vos résultats ont bien été enregistrés !",
      errorImages: "Oups ! Nous ne parvenons pas à traiter vos images correctement. Besoin d’aide ? ",
      errorTimeout: "Etes-vous toujours là ? La capture a été mise en pause due à votre inactivité."
    }

  }),

  methods: {
    getMessage() {
      return this.state === 'resultsOk' ? this.messages.success : this.state === 'badResults' ? this.messages.errorImages : this.messages.errorTimeout
    },

    retry() {
      this.$emit('retry-event')
    },

    remind() {
      this.$emit('remind-event')
    }
  },

}


</script>



<style lang="scss" scoped>
$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

@mixin indicator {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin timer {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

@mixin timervariant {
  font-style: normal;
  font-weight: 500;
  font-size: 96px;
  line-height: 144px;
}

.instructions {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: $blackalt;
  color: $white;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);

    .box {
      text-align: center;

      .link {
        cursor: pointer;
        color: $magenta;
        display: inline-block;
      }

      .btn-group {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

    }
  }
}
</style>




<!-- <template>
    <div>
        <button @click="toggle">Toggle</button>
        <p>Booléen: {{ bool }}</p>
        <p>Autre booléen: {{ otherBool }}</p>
    </div>
</template>

<script>
export default {
  data() {
    return {
      bool: true,
      otherBool: false,
      interval: null,
      seconds: 0
    }
  },
  methods: {
    toggle() {
      this.bool = !this.bool;
    },
    startChecking() {
      this.interval = setInterval(() => {
        if (this.bool === false) {
          this.seconds++;
          if (this.seconds >= 10) {
            this.otherBool = true;
            this.stopChecking();
          }
        } else {
          this.seconds = 0;
        }
      }, 1000);
    },
    stopChecking() {
      clearInterval(this.interval);
    }
  },
  mounted() {
    this.startChecking();
  },
  beforeDestroy() {
    this.stopChecking();
  }
}
</script> -->
