<!-- <template lang="pug">
v-app
  v-app-bar.d-flex.align-center(app, short, color="black")
    h1.mr-auto
      router-link(:to="{ name: 'Main' }") 
        v-img(
          src="@/assets/logos/MODALIVE-HORIZONTAL-2021_RVB_SOMBRE-VERSION-S.png",
          contain,
          max-width="175"
        )
    .userMenu
      template(v-if="!!user")
        span(color="white") {{ profil.name }} - {{ lastActivityTime }}
        v-icon.ml-2.mt-n2(color="lGrey", @click="logout") mdi-power
      //- template( v-else )
      //-   router-link( :to="{ name: 'Login'}")
      //-     Button
      //-       span Connexion

    .langagesSelect
      select(v-model="$i18n.locale")
        option(value="fr") 🇫🇷
        option(value="en") 🇬🇧

  v-main
    router-view
</template> -->



<template lang="pug">
v-app  
    v-app-bar.d-flex.align-center(app, short, color="black")
      //- h1.mr-auto
      //-   //- router-link(:to="{ name: 'Main' }") 
      //-   v-img(v-if="!isMobile",
      //-     src="@/assets/logos/MODALIVE-HORIZONTAL-2021_RVB_SOMBRE-VERSION-S.png",
      //-     contain,
      //-     max-width="175"
      //-   )
      //-   v-img(v-if="isMobile",
      //-     src="@/assets/logos/MODALIVE-HORIZONTAL-2021_RVB_SOMBRE-VERSION-S-J.png",
      //-     contain,
      //-     max-width="40"
      //-   )
      //-   v-btn(icon, @click="toggleDrawer", :style="{ color: 'white' }")
      //-     v-icon mdi-menu
      v-row(align="center" class="mr-auto")
        v-col(cols="auto")
          h1
            //- router-link(:to="{ name: 'Main' }") 
            v-img(v-if="!isScreenMobile",
              src="@/assets/logos/MODALIVE-HORIZONTAL-2021_RVB_SOMBRE-VERSION-S.png",
              contain,
              max-width="175"
            )
            v-img(v-if="isScreenMobile",
              src="@/assets/logos/MODALIVE-HORIZONTAL-2021_RVB_SOMBRE-VERSION-S-J.png",
              max-width="50"
            )
        v-col(cols="auto", v-if="isScreenMobile",)
          //- v-btn(icon, @click="toggleDrawer", :style="{ color: 'white' }")
          //-   v-icon mdi-menu
          v-menu(offset-y :close-on-content-click="false" :nudge-width="200" :z-index="9999")
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon v-bind="attrs" v-on="on")
                v-icon(:style="{ color: 'white' }") mdi-menu
            v-list(style="z-index: 2000; background-color: white;")
              v-list-item(v-for="item in menuItems", :key="item.title", @click="navigateTo(item.link)")
                v-list-item-title {{ item.title }}
        v-col(cols="auto", v-if="!isScreenMobile",)
        
        //- v-navigation-drawer(v-model="drawer", temporary)
        //-   v-list
        //-     v-list-item(v-for="item in menuItems", :key="item.title", @click="navigateTo(item.link)")
        //-       v-list-item-title {{ item.title }}
  
  
  
      template(v-if="isLogin")
        .userMenu
          v-menu(offset-y open-on-hover :close-on-content-click="false" :nudge-width="200" :z-index="9999")
            template(v-slot:activator="{ on }")
              v-btn(v-on="on", text, x-large)
                v-icon(color="white") mdi-shopping
                v-badge(color="red", :content="ShoppingList.length == 0 ? '0' : ShoppingList.length")
            v-card(class="pa-3", style="z-index: 9999; width:500px")
              v-row
                v-col(cols="6")
                  div(style="margin-bottom: 10px") 
                    div.image-and-title(v-if="CurrentWorkingClothStore", style="margin-left: -100px")
                      v-img(:src="CurrentWorkingClothStore.image" height="50" width="50" class="image" contain)
                      span(style="margin-left: -130px") {{ CurrentWorkingClothStore.title }}
                    div(v-else, style="align-items: center") No cloth selected
                  v-btn-toggle(v-if="CurrentWorkingClothStore", v-model="toggle_exclusive" rounded)
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on }")
                        v-btn(v-on="on", @click="addToShoppingList(CurrentWorkingClothStore)")
                          v-icon mdi-cart-plus
                      span Add to Cart
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on }")
                        v-btn(v-on="on", @click="deleteFromShoppingList(CurrentWorkingClothStore)")
                          v-icon mdi-cart-remove
                      span Remove from Cart
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on }")
                        v-btn(v-on="on", @click="updateInShoppingList(CurrentWorkingClothStore)")
                          v-icon mdi-cart-arrow-up
                      span Update the product
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on }")
                        v-btn(v-on="on", @click="sendToShopify()")
                          v-icon mdi-cart-arrow-right
                      span Proceed shop cart
                v-col(cols="6")
                  div.left-border
                    h5(style="align-items: center;") Your Shopping cart
                    div(v-if="ShoppingList.length === 0")
                      p The shopping cart is empty.
                    div(v-for="item in ShoppingList" :key="item.barCode")
                      div.image-and-title(style="border-bottom: 1px solid #60555563; padding: 2px;")
                        v-img(:src="item.image" height="50" width="50" class="image" contain)
                        span {{ item.title }}
                        v-btn(text, @click="deleteFromShoppingList(item)")
                          v-icon(color='red') mdi-delete
  
        v-menu(offset-y open-on-hover :close-on-content-click="false" :nudge-width="200" :z-index="9999")
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon v-bind="attrs" v-on="on")
              span(:style="{ color: 'white' }") {{ profil.name }}
              v-icon(color="white") mdi-account
          v-list(style="z-index: 2000; background-color: white;")
            v-list-item(v-for="item in profileMenuItems", :key="item.title", @click="navigateTo(item.link)")
              v-list-item-title {{ item.title }}
        span(:style="{ color: 'white', marginLeft: '10px'  }") 
        v-icon.ml-2(color="lGrey", @click="logout", v-if="!fromShop") mdi-power
        v-icon.ml-2(v-else, color="lGrey", @click="backToShop()") mdi-arrow-left-circle-outline
  
      .langagesSelect
        select(v-model="$i18n.locale")
          option(value="fr") 🇫🇷
          option(value="en") 🇬🇧
  
    v-main
      router-view    
</template>
  <script>
  import Vuex from "vuex";
  import Main from "@/views/visualisation/Visualisation.view";
  import Button from "@/components/_actions/Button.component";
  
  export default {
    name: "App",
    metaInfo: {
      titleTemplate: "%s | ModaLive",
    },
  
    components: {
      Main,
      Button,
    },
  
    data: () => ({
      isUser: !localStorage.getItem('userToken'),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      inactivityThreshold: 180000, // 3 min in sec
      lastActivityTimestamp: Date.now(),
  
      currentItem: null, // The current item being acted upon
      cartItems: [], // Items in the cart
  
      drawer: false,
      toggle_exclusive: null,
      appBarColor: 'black',
        menuItems: [
          { title: 'Home', link: '#' },
          { title: 'My account', link: '#' },
          // Add more menu items as needed
        ],
        profileMenuItems: [
          // { title: 'Home', link: '#' },
          { title: 'My account', link: '#' },
          // Add more menu items as needed
        ],
    }),
    computed: {
      ...Vuex.mapGetters({
        isDialog: "isDialog",
        isLogin: "users/isLogin",
        user: "users/current",
        profil: "users/profils/current",
      }),
      ...Vuex.mapState(["ShopName", "ToShopNameProducts", 'productList', 'fromShop', 'shopCustomer', 'CurrentWorkingClothStore', 'ShoppingList']),
      lastActivityTime() {
        return new Date(this.lastActivityTimestamp).toLocaleTimeString();
      },
      // ...Vuex.mapState(['shopCustomer']),
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      isScreenMobile() {
        return this.windowWidth <= 600; // Define your mobile screen width threshold here
      },
  
  
    },
    methods: {
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      navigateTo(link) {
        this.$router.push(link);
        this.drawer = false;
      },
      ...Vuex.mapActions({
        logout: "users/logout",
        initBaseCommand: "communication/commands/initBaseCommand",
        getBaseCommands: "communication/commands/getBaseCommands",
        deleteAllCommand: "communication/commands/deleteAllCommand",
        // setConfigCommand: 'communication/commands/setConfigCommand'
        modal: false,
      }),
      ...Vuex.mapActions([
        "deleteProduct", 
        "emptyProductList", 
        'initBaseCommand', 
        'getBaseCommands', 
        'deleteAllCommand',
        'addToShoppingList',
        'deleteFromShoppingList',
        'updateInShoppingList'
      ]),
    sendToShopify() {
      // Implement API call to Shopify here
      // try {
        // console.log(document.referrer);  // This will log the URL of the parent page.
        window.parent.postMessage({productsModa: this.ShoppingList}, `https://${this.shopCustomer.shopD}`);
        window.parent.postMessage({productsModa: this.ShoppingList}, `http://${this.shopCustomer.shopD}`);
        // alert(document.referrer)
      // } catch (error) {
      //   alert(error)
      // }
    },
  
    backToShop(){
      // try {
        // console.log(document.referrer);  // This will log the URL of the parent page.
        window.parent.postMessage({productsModa: this.ShoppingList}, `https://${this.shopCustomer.shopD}`);
        window.parent.postMessage({returnModa: true}, `http://${this.shopCustomer.shopD}`);
        // alert(document.referrer)
      // } catch (error) {
      //   alert(error)
      // }
    },
  
    emptyList() {
      this.emptyProductList();
    },
    deleteProductFromList(item) {
      this.deleteProduct(item.sku);
    },
      receiveMessage(event) {
        // alert("Message")
        // Check the origin of the message to ensure it's coming from the expected domain
  
        // if (event.origin !== 'https://quickstart-1f0b9557.myshopify.com') {
        //   this.$store.dispatch('updateFromShop', false);
        //     return;
        // }
  
        // The data sent via postMessage is stored in event.data
        var productListA = event.data.productList;
        var shopCustomerA = event.data.shopCustomer;
  
              // You can now use this.productList in your component
        this.$store.dispatch('updateProductList', productListA);
        this.$store.dispatch('updateShopCustomer', shopCustomerA);
        this.$store.dispatch('updateShopName', "SHOPIFY");
        this.$store.dispatch('updateFromShop', true);

  
        if (productListA == null) {
          // alert("productListA - null")
        this.$store.dispatch('updateFromShop', false);
  
        var productToModalive = {
    "26": {
      "image": "https://storage.googleapis.com/storage-app-modalive/clothes_files/thumbnails_files/veste.jpeg",
      "title": "Veste",
      "variants": [
        {
          "id": "1",
          "product_id": "26",
          "title": "Veste",
          "price": "30,99€",
          "sku": "Veste23",
          "image": "https://storage.googleapis.com/storage-app-modalive/clothes_files/thumbnails_files/veste.jpeg"
        }
      ]
    }
  };
  
      productListA = JSON.stringify(productToModalive)
  
  
          // productListA = '"26": {"image": "https://storage.googleapis.com/storage-app-modalive/clothes_files/thumbnails_files/veste.jpeg","title": "Veste","variants": [{"id": "1","product_id": "26","title": "Veste","price": "30,99€","sku": "Veste23","image": "https://storage.googleapis.com/storage-app-modalive/clothes_files/thumbnails_files/veste.jpeg"}]}'
          // console.log('====================================');
          // console.log('productListA: ', productListA);
          // console.log('====================================');
          this.$store.dispatch('updateProductList', productListA);
        }
        if (shopCustomerA == null) {
          // alert("shopCustomerA - null")
          shopCustomerA = '{"email": "lucas@modalive.fr", "name": "shop", "shopD": "shop_domain", "shopE": "shop_email", "shopName": "shop_name"}'
          this.$store.dispatch('updateShopCustomer', shopCustomerA);
        }
  
        // console.log("==================productList==============", productListA);
        // console.log("==================shopCustomer==============", shopCustomerA);
  
  
  
        // console.log("==================productList==============", this.productList);
        // console.log("==================shopCustomer==============", this.shopCustomer);
        console.log(this.fromShop)
  
        // console.log("/////////////////////////////////");
        // console.log(this.shopCustomer.email);
        // console.log("/////////////////////////////////");
  
  
  
        // // this.$store.dispatch('updateShopCustomer', shopCustomer);
      },
      resetInactivityTimer() {
        this.lastActivityTimestamp = Date.now();
      },
      checkInactivity() {
        const currentTime = Date.now();
        const timeSinceLastActivity = currentTime - this.lastActivityTimestamp;
        if (timeSinceLastActivity >= this.inactivityThreshold) {
          // Perform action for inactivity (e.g., display a message or log out the user)
          console.log("User is inactive!");
          this.logout();
        }
      },
    },
    async mounted() {
      // this.logout();
  
      console.log('================APP BAR this.isUser====================');
      // if (this.isLogin == false) alert('is not');
      // if (this.isLogin == true) alert('exists : ', localStorage.getItem('userToken'));
      console.log('====================================');
  
      console.log('=================APP BAR this.user===================');
      console.log(this.user);
      console.log('====================================');
      await this.initBaseCommand();
  
      window.addEventListener('message', this.receiveMessage, false);
  
      // // await this.setConfigCommand()
      // await this.setConfigCommand({key: 'canal', value: 'referenceVideo'})
  
      // Add event listeners for user interactions
      window.addEventListener("mousemove", this.resetInactivityTimer);
      window.addEventListener("keydown", this.resetInactivityTimer);
      window.addEventListener("scroll", this.resetInactivityTimer);
  
      // Check for inactivity at regular intervals
      //this.inactivityInterval = setInterval(this.checkInactivity, 1000); // Check every second (adjust the interval as needed)
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    async beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
      await this.deleteAllCommand();
          // Clean up event listeners and interval
      window.removeEventListener("mousemove", this.resetInactivityTimer);
      window.removeEventListener("keydown", this.resetInactivityTimer);
      window.removeEventListener("scroll", this.resetInactivityTimer);
      clearInterval(this.inactivityInterval);
    },
  
    // async beforeMount() {
    //  console.log("++++++++++++",this.isLogin);
    //  try {
    //  }
    //  catch(err){
    //   console.log(err)
    //  }
    //  console.log("------------",this.isLogin);
    // },
  };
  </script>
  
  <style lang="scss">
  @import url("./../assets/fonts/HK_Grotesk/stylesheet.css");
  $font-family: "HK Grotesk";
  * {
    font-family: $font-family, sans-serif;
  }
  .v-app {
    overflow: hidden;
  }
  .v-app-bar {
    position: relative;
    z-index: 1000;
    .v-toolbar__content {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      h1 {
        * {
          color: white;
          text-decoration: none;
        }
      }
      .langagesSelect {
        select {
          font-size: 22px;
          padding: 0 0 0 36px;
        }
      }
      .userMenu {
        & > span {
          color: #ffffff;
        }
      }
    }
  }
  
  
  .left-border {
    border-left: 1px solid #60555563; /* Adjust thickness and color as needed */
    padding-left: 10px; /* Optional padding */
    margin-left: 8px;
  }
  .image-and-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .image {
    margin-right: 10px; /* Adds some space between the image and the title */
  }
  
  
  .xSmall {
    border-radius: 8px !important;
    font-size: 10px !important;
    height: 16px !important;
  }
  .small {
    border-radius: 12px !important;
    font-size: 12px !important;
    height: 24px !important;
  }
  // .medium{ }
  .large {
    border-radius: 27px !important;
    font-size: 16px !important;
    height: 54px !important;
  }
  .xLarge {
    border-radius: 33px !important;
    font-size: 18px !important;
    height: 66px !important;
  }
  .disabled {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 50;
    }
    // &.v-chip::after{
    //   z-index: 115;
    // }
  
  }
  </style>