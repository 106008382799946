<template lang="pug">
.d-flex
  v-overlay(opacity="0.9", color="white", :dark="false")
    BorderGradient
      Login 
</template>

<script>
import SideBarL from "@/components/SideBarL.component";
import SideBarR from "@/components/SideBarR.component";
import Visualisation from "@/components/Visualisation.component";
import BorderGradient from "@/components/_design/BorderGradient.component";
import Login from "@/components/login/Login.component";

export default {
  name: "Main",
  metaInfo: {
    title: "Login",
  },
  components: {
    Login,
    SideBarL,
    SideBarR,
    Visualisation,
    BorderGradient,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.borderGradient {
  background: linear-gradient(
    135deg,
    #00ffff 0%,
    #ff00ff 33%,
    #ff48b7 67%,
    #ffff00 100%
  );
}

@media (max-width: 720px) {
  .borderGradient {
    width: 300px;
  }
}
@media (max-width: 505px) {
  //adaptation pour les petits écrans (téléphone) du cadre de connexion
  .borderGradient {
    width: 350px;
  }
}
@media (max-height: 700px) {
  //adaptation pour les petits écrans (téléphone) du cadre de connexion format paysage
  .borderGradient {
    height: 350px;
  }
}

</style>
