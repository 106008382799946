// require("dotenv").config('./../../.env.development.local');
// const { VUE_APP_URL_SIGNALING, VUE_APP_PORT_SIGNALING } = process.env
import io from 'socket.io-client'
//import peerService from 'peer.service'

export default class SignalingClient {
  constructor() {
    console.log('Construction of Connection between client and signaling server')//on y passe (log dans le front)
    // console.log(`${VUE_APP_URL_SIGNALING}:${VUE_APP_PORT_SIGNALING}/`)

    this._socket = this._createSocket()
  }

  getSocket() { return this._socket }
  getPeer() { return this._peer }
  setPeer(value) { this._peer = value }

  // _createSocket() { return io(`${VUE_APP_URL_SIGNALING}:${VUE_APP_PORT_SIGNALING}/`) }
  _createSocket() { 
    return io('wss://r2d2.modalive.io:9000', {
      transports: ['websocket'],
      secure: true,
      withCredentials: true
    })
  }
  //_createSocket() { return io('https://35.197.103.57:9000') }
  clientReady() { this.getSocket().emit('emitter ready') }
}