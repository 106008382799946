<template lang="pug">
.all
  BorderGradient(noPadding=true).all
    .instructions
      .container 
        .box
          h3 {{ upperCase($t('home.title')) }}
          br
          ul.icon-list
            li {{ $t('home.list.1.title') }}
            ul
              li {{ $t('home.list.1.list.1') }}
              li {{ $t('home.list.1.list.2') }}
              li {{ $t('home.list.1.list.3') }}
              li {{ $t('home.list.1.list.4') }}
              li {{ $t('home.list.1.list.5') }}
            li {{ $t('home.list.2') }}
            li {{ $t('home.list.1.list.3') }}
            li {{ $t('home.list.1.list.4') }}
          br
          .btn-group 
            v-btn(@click="next()") {{ $t('home.next') }}
  
</template>



<script>
import BorderGradient from "@/components/_design/BorderGradient.component";


export default {
  name: "home",

  props: ['upperCase'],

  components : {
    BorderGradient,
  },

  methods: {
    next() {
      this.$emit('next-event')
    }
  },
  
  data: () => ({
  
  })


}


</script>



<style lang="scss" scoped>

$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

@mixin indicator {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

@mixin timer {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

@mixin timervariant {
  font-style: normal;
  font-weight: 500;
  font-size: 96px;
  line-height: 144px;
}

.icon-list {
  list-style: none;
  counter-reset: icon-counter;
}

.icon-list > li {
  counter-increment: icon-counter;
  position: relative;
}

.icon-list > li:before {
  content: counter(icon-counter);
  position: absolute;
  left: -30px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: $magenta;
  color: white;
}



.instructions {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: $blackalt;
  color: $white;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30%;
    transform: translate(-50%, -50%);

    @media (min-width: 600px) {
      width: 60%;
    }

    @media (min-width: 900px) {
      width: 40%;
    }

    @media (max-width: 599px) {
      width: 80%;
    }

    .box {
      text-align: center;

      ul {
        text-align: left;
      }

      .link {
        color: $magenta;
        display: inline-block;
      }

      .btn-group {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

    }
  }
}

</style>
