import utils from "@/utils/utils";

import replays from '@/plugins/store/modules/users/profils/replays.fake';
import clothes from '@/plugins/store/modules/clothes';
import avatars from '@/plugins/store/modules/users/profils/avatars';

import ProfilService from '@/services/own/profils.service';
const ps = new ProfilService()


const state = {
  profil: {},
  profils: [],
};

const getters = {
  current: (state) => state.profil,
  list: (state) => state.profils,
};

const mutations = {
  SET_LIST_PROFIL: (state, profils) => { state.profils = utils.sorting(profils, null, { key: 'name' }) },
  SET_CURRENT_PROFIL: (state, profil) => { state.profil = profil; localStorage.setItem('profileId', profil.id) },
  CHECKOUT_ERROR: (state) => { state.status = 'error'; state.isLogin = false; },
  CHECKOUT_SUCCESS: (state) => { state.status = 'success'; state.isLogin = true; },
};


const actions = {
  setListProfil: (state, profils) => {
    state.commit('SET_LIST_PROFIL', profils)
  },
  setCurrentProfil: (state, profil) => {
    state.commit('SET_CURRENT_PROFIL', profil)
    state.dispatch('users/profils/replays/sessions/setListReplays', profil.SessionReplays, { root: true })
    state.dispatch('clothes/undressAllClothes')
    //state.dispatch('clothes/setCloakroom', null, { root: true })
  },
  setCurrentReference: (state, ref) => {
    state.commit('SET_CURRENT_REFERENCE', ref)
  },

  createProfil: async (state, user) => {
    const param = {
      createdAt : utils.dateToISOString(new Date()),
      updatedAt : utils.dateToISOString(new Date()),
      name : 'default',
      UserId : user.id,
    }
    const set = await state.dispatch('post', param)
    console.log("id profil",set.profils.id)
    
    state.commit('CHECKOUT_SUCCESS');
    state.dispatch('setListProfil',set.profils)
  },

  // DB CALL

  getById: async (state, id) => { //TODO
    const profileGet = await ps.getByProfilId(id) 
    const profile = profileGet.data
    console.log(profile)
    return profile
  },

  post: (state, data) => {
    return new Promise((resolve, reject) => {
      ps.post(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => { reject(error) })
    })
  },

  patch: async (state, data) => {
    const profil = state.rootGetters['users/profils/current']
    await ps.patch(profil.id, data)
      .catch(error => {
        state.commit('CHECKOUT_ERROR', error, { root: true })
      })
    state.commit('CHECKOUT_SUCCESS', null, { root: true })
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    clothes,
    replays,
    avatars
  }
};