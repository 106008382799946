

import ApiService from '../api.service';


class OwnReplayService extends ApiService {
  constructor() {
    super()
  }


  gets(idProfil) {
    return super.doRequest('get', `/own/profil/${idProfil}/replays`);
  }

  getsByTypeReplay(idProfil, typeReplay) {
    return super.doRequest('post', `/own/profil/${idProfil}/replays/${typeReplay}`);
  }

  postByTypeReplay(idProfil, typeReplay, data) {
    return super.doRequest('post', `/own/profil/${idProfil}/replays/${typeReplay}`, { data });
  }

  patch(idProfil, typeReplay, idReplay, data) {
    return super.doRequest('patch', `/own/profil/${idProfil}/replays/${typeReplay}/${idReplay}`, { data });
  }

  delete(idProfil, typeReplay, idReplay) {
    return super.doRequest('delete', `/own/profil/${idProfil}/replays/${typeReplay}/${idReplay}`);
  }
}

export default OwnReplayService;
