module.exports = {
  groupBy: (items, key) => {
    const paramsItems = items ? items : []
    const newItems = {}
    for (const item of paramsItems) {
      if (!newItems[item[key]]) newItems[item[key]] = []
      newItems[item[key]].push(item)
    }
    return newItems
  },
  sorting: (array, direction, options = {}) => {
    let arraySorted = []
    const { key = null } = options
    arraySorted = array.sort((elA, elB) => {
      let a = elA, b = elB;
      if (key) { a = a[key]; b = b[key]; }

      if (a < b) return /desc/i.test(direction) ? 1 : -1
      if (a > b) return /desc/i.test(direction) ? -1 : 1
      if (a == b) return 0
    })
    return arraySorted
  },
  pad(number) {
    if (number < 10) return '0' + number;
    return number;
  },
  dateToISOString: function (date) {
    return date.getUTCFullYear() +
      '-' + this.pad(date.getUTCMonth() + 1) +
      '-' + this.pad(date.getUTCDate()) +
      'T' + this.pad(date.getUTCHours()) +
      ':' + this.pad(date.getUTCMinutes()) +
      ':' + this.pad(date.getUTCSeconds()) +
      '.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      'Z';
  },
  dateToCompactString: function (date, options = {}) {
    const { milliseconds } = options
    let isoDate = date.getUTCFullYear() + this.pad(date.getUTCMonth() + 1) + this.pad(date.getUTCDate()) + this.pad(date.getUTCHours()) + this.pad(date.getUTCMinutes()) + this.pad(date.getUTCSeconds());
    if (milliseconds) isoDate = isoDate + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
    return isoDate;
  },
  updateItemOfArray: (array, elItemToUpdate, options = {}) => {
    const newArray = array
    const { key = null } = options
    const indexItem = newArray.findIndex((elItem) => {
      let item = elItem, itemToUpdate = elItemToUpdate
      if (key) { item = item[key]; itemToUpdate = itemToUpdate[key] }
      return item == itemToUpdate
    })

    newArray.splice(indexItem, 1, elItemToUpdate)
    return newArray
  },
  isJson: (item) => {
    item = typeof item !== "string"
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  },
  isEmptyObject: (object) => Object.keys(object).length === 0 ? true : false,
  getFromLocalStorage: (key) => JSON.parse(localStorage.getItem(key))
}

