<template lang="pug">
  div#clothParams
    template(v-if="/^bottoms?$/i.test(cloth.clothType)")
      BottomsParams
    template(v-else-if="/^tops?$/i.test(cloth.clothType)")
      TopsParams
    template(v-else)
      v-card-text.text-center {{$t("sideBarRight.noReference")}}
</template>

<script>
  import Vuex from 'vuex';

  import BottomsParams from '@/components/visualisation/cloakroom/clothesParams/BottomsParams.component';
  import TopsParams from '@/components/visualisation/cloakroom/clothesParams/TopsParams.component';

  export default {
    name: 'ClothParamsSelector',

    components: {TopsParams, BottomsParams},
    props: {},
    data: () => ({ }),
    methods: {},
    computed: {
      ...Vuex.mapGetters({
        cloth: 'clothes/current'
      }),
    }
  };
</script>

<style lang="scss">
</style>
