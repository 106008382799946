

import ApiService from '../api.service';


class OwnProfilService extends ApiService {
  constructor() {
    super()
  }


  gets() {
    return super.doRequest('get', `/own/profils`);
  }

  post(data) {
    return super.doRequest('post', `/own/profils`, { data });
  }

  getByProfilId(idProfil) {
    return super.doRequest('get', `/own/profil/${idProfil}`);
  }

  patch(idProfil, data) {
    return super.doRequest('patch', `/own/profil/${idProfil}`, { data });
  }

  delete(idProfil) {
    return super.doRequest('delete', `/own/profil/${idProfil}`);
  }
}

export default OwnProfilService;
