<template lang="pug">
#mainView
    SideBarL(:class="[isVisibleSideBarL ? '' : 'hidden-md-and-down']")
    BorderGradient.responsiveButton.responsiveButtonL.hidden-lg-and-up.side-bar-s(
      noPadding,
      forButton,
      :class="[isVisibleSideBarL ? 'sideBarLShow' : '', isVisibleSideBarR ? 'hidden-xxs-and-down' : '']"
    ) 
      p.mx-2.d-flex.justify-center.align-center(@click="toggleSideBarL")
        span
          v-icon(v-if="isVisibleSideBarL", color="black", size="18px") mdi-arrow-left
          v-icon(v-else, color="black", size="18px") mdi-arrow-right
         
        // {{ isVisibleSideBarL ? '<-' : '->' }}
  
    Visualisation
  
    SideBarR(:class="[isVisibleSideBarR ? '' : 'hidden-md-and-down']")
    BorderGradient.responsiveButton.responsiveButtonR.hidden-lg-and-up.side-bar-s(
      noPadding,
      forButton,
      :class="[isVisibleSideBarR ? 'sideBarRShow' : '', isVisibleSideBarL ? 'hidden-xxs-and-down' : '']"
    ) 
      p.mx-2.d-flex.justify-center.align-center(@click="toggleSideBarR")
        //- span {{ isVisibleSideBarR ? '->' : '<-' }}
        span
          v-icon(v-if="isVisibleSideBarR", color="black", size="18px") mdi-arrow-right
          v-icon(v-else, color="black", size="18px") mdi-arrow-left
</template>
  
  <script>
  import BorderGradient from "@/components/_design/BorderGradient.component";
  
  import SideBarL from "@/components/SideBarL.component";
  import SideBarR from "@/components/SideBarR.component";
  import Visualisation from "@/components/Visualisation.component";
  
  export default {
    name: "Main",
    metaInfo: {
      title: "Visualisation",
    },
    components: {
      SideBarL,
      SideBarR,
      Visualisation,
      BorderGradient,
    },
    data: () => ({
      isVisibleSideBarL: false,
      isVisibleSideBarR: false,
    }),
    methods: {
      toggleSideBarL() {
        if (this.isVisibleSideBarR)
          this.isVisibleSideBarR = !this.isVisibleSideBarR;
        this.isVisibleSideBarL = !this.isVisibleSideBarL;
      },
      toggleSideBarR() {
        if (this.isVisibleSideBarL)
          this.isVisibleSideBarL = !this.isVisibleSideBarL;
        this.isVisibleSideBarR = !this.isVisibleSideBarR;
      },
    },
  };
  </script>
  
  <style lang="scss">
  #mainView {
    position: relative;
    background-color: var(--v-lGrey-base);
    height: calc(100vh - 56px);
    #visualisation {
      position: fixed;
      width: 100%;
      // background-color: red;
    }
    .responsiveButton,
    .side-bar {
      position: absolute;
      z-index: 50;
    }
    .side-bar {
      height: calc(100vh - 56px);
      width: 350px;
  
    }
    .side-bar-s {
      height: calc(100vh - 56px);
      width: 20px;
      opacity: 0.7;
    }
    .size-s{
      font-size: 1px;
    }
    .responsiveButton {
      cursor: pointer;
      top: 15%;
      height: 50%;
      p {
        height: 100%;
      }
      * {
        font-weight: bolder;
      }
    }
    .responsiveButtonL {
      left: 5px;
      &.sideBarLShow {
        left: 350px;
      }
    }
    .responsiveButtonR {
      right: 5px;
      &.sideBarRShow {
        right: 350px;
      }
    }
    @media (min-width: 1850px) {
    .side-bar {
      width:450px;
    }
  }
  }
  
  
  
  @media (max-width: 600px) {
    .responsiveButtonL.sideBarLShow {
      left: 350px !important;
    }
    .responsiveButtonR.sideBarRShow {
      right: 350px !important;
    }
  }
  
  @media (max-width: 450px) {
    .responsiveButton {
      &.hidden-xxs-and-down {
        display: none;
      }
    }
  }
  
  
  </style>
  