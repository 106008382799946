
import AvatarService from '@/services/own/avatars.service';

const as = new AvatarService()

const state = {
  avatar : {},
};

const getters = {
  current: (state) => state.avatar,
};

const mutations = {
  SET_AVATAR: (state, avatar) => { state.avatar = avatar },
};

const actions = {

    get: async (state, id) => {
      console.log("dans le get")
      const avatarGet = await as.get(id)
      console.log("passe le get dans le get")
      const avatar = avatarGet.data
      state.commit('SET_AVATAR',avatar)
      console.log("avatar",avatar)
      return avatar
    },

    post:  (state, id, data) => {
      return new Promise((resolve, reject) => {
        as.post(id, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => { reject(error)})
      })
    },

    patch: async (state, id, data) => {

      await as.patch(id, data)
    },

    delete: (state, id) => {
      return new Promise((resolve,reject) => {
        as.delete(id)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {reject(error)})
      })
    }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};