<template lang="pug">
BorderGradient.framingParams.ma-2(noPadding)
  v-card.d-flex.flex-column.elevation-0
    v-card-title {{ $t('sideBarRight.framingBox.title') }}
    v-switch.ml-3(:color="this.colors.magenta" v-model="settingsZoom" label="zoomer" @change="onChange")
    .d-flex.justify-space-around.align-center.px-2
      template(v-for="(framing, n) in listFramings")
        //- v-if="framing == getCommandC",
        .px-2.framing.framingActive.d-flex.flex-column.align-center(
          v-if="new RegExp(`${framing.command.key}`).test(getCommandC) && new RegExp(`${framing.command.value}`).test(getCommandC)",
          :key="n"
        )
          v-img(:src="framing.img")
          span {{ framing.name }}

        .px-2.framing.d-flex.flex-column.align-center(
          v-else,
          :key="n",
          @click="setAsSelectedFraming(framing)"
        )
          v-img(:src="framing.img")
          span {{ framing.name }}
    img.img.EnPieds(src="../../../assets/framings/framingsModelEnPieds.jpg")
    img.img.Entier(src="../../../assets/framings/framingsModelEnPieds.jpg") 
    img.img.Haut(src="../../../assets/framings/framingsModelHaut.jpg")
    img.img.Bas(src="../../../assets/framings/framingsModelBas.jpg")

  
</template>

<script>
import Vuex from "vuex";

import BorderGradient from "@/components/_design/BorderGradient.component";

export default {
  name: "FramingCard",

  components: { BorderGradient },
  data: () => ({
    colors: {
      bad: '#E84B65',
      good: '#00F6A8',
      magenta: '#ff00ff',
      black: '#000000',
      blackalt: '#010220',
      white: '#FFFFFF',
    },
  }),
  methods: {
    ...Vuex.mapActions({
      setAsSelectedFraming: "framings/selectFraming",
      setSettingsZoom: "states/setSettingsZoom",
      setSettingsMove: "states/setSettingsMove"
    }),

    onChange() {
      if (this.settingsMove && !this.settingsZoom) {
        this.setSettingsMove(!this.settingsMove);
      }
      this.setSettingsZoom(!this.settingsZoom);
    },
  },

  mounted() {

  },
  computed: {
    ...Vuex.mapGetters({
      getCommand: "communication/commands/getCommand",
      listFramings: "framings/list",
      settingsZoom: "states/settingsZoom",
      settingsMove: "states/settingsMove"

    }),
    getCommandC() {
      return this.getCommand("setFraming");
    },
  },
};
</script>

<style lang="scss">
.framingParams {
  height: calc((100% / 4) - 8px);
  .framing {
    width: calc(100% / 4);
    cursor: pointer;
    z-index:5;
    .v-image {
      width: 100%;
      height: 80px;
      border: 1px solid #000000;
    }
    @media (min-height: 870px) {
      .v-image {
        height: 100px;
      }
    }
  }
  .framingActive {
    * {
      font-weight: bolder;
    }
  }
  
}

.img {
  width:70px;
  height:80px;
  bottom:24px;
  position:absolute;
}
.EnPieds { //TODO
  left:85px;
}
.Bas { //TODO
  left:239px;
}
.Haut { //TODO
  left:162px;
}
.Entier {
  left:8px;
}
@media (min-width: 1850px) {
  .img {
  width:87px;
  bottom:24px;
  position:absolute;
  }
  .EnPieds { //TODO
    left:118px;
  }
  .Bas { //TODO
    left:323px;
  }
  .Haut { //TODO
    left:221px;
  }
  .Entier {
    left:15px;
  }
}
@media (min-height: 870px) {
  .img {
    height:100px;
  }
}
</style>
