<template lang="pug">
.testSelectSwitch
</template>

<script>
// const inflection = require("inflection");

// import Vuex from "vuex";

import SelectSwitch from "@/components/_actions/SelectSwitch.component";
import BoxRoundedGrey from "@/components/_design/BoxRoundedGrey.component";

export default {
  name: "SceneSettings__ArtificialLigths",

  components: { SelectSwitch, BoxRoundedGrey },
  data: () => ({}),
  computed: {},
  methods: {},
  async mounted() {},
};
</script>

<style lang="scss">
.testSelectSwitch {
  width: 100%;
}
</style>
