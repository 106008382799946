const state = {
  nbArtificialLight: 0,
  artificialLigths: [],
  artificialLigthActive: {},
  haveArtificialLight: false,
};



const getters = {
  nbArtificialLight: state => state.nbArtificialLight,

  isMainLight: state => state.isMainLight,
  haveArtificialLight: state => state.haveArtificialLight,
  artificialLigths: state => state.artificialLigths,
  artificialLigthsCount: state => state.artificialLigths.length,
  artificialLigthActive: state => state.artificialLigthActive,
  // artificialLigthKey: state => state.artificialLigthKey,

};

const mutations = {
  SET_ZERO_NB_ARTIFICIAL_LIGHT: state => { state.nbArtificialLight = 0 },
  INCREMENT_NB_ARTIFICIAL_LIGHT: state => { state.nbArtificialLight += 1 },

  TOGGLE_IS_MAIN_LIGHT: state => { state.artificialLigthActive.isMainLight = !state.artificialLigthActive.isMainLight },
  TOGGLE_IS_DIFFUSED_LIGHT: state => { state.artificialLigthActive.isDiffusedLight = !state.artificialLigthActive.isDiffusedLight },
  TOGGLE_HAVE_ARTIFICIAL_LIGHT: state => { state.haveArtificialLight = !state.haveArtificialLight },
  ADD_ARTIFICIAL_LIGHT: (state, artificialLigthInit) => {
    const index = state.artificialLigths.length
    state.artificialLigths.push(JSON.parse(JSON.stringify({
      id: index + 1,
      name: `Lumiere ${index + 1}`,
      ...artificialLigthInit
    })))
  },
  UPDATE_VALUE: (state, { stateToUpdate, value }) => {
    state.artificialLigthActive[stateToUpdate] = value
  },
  SET_ARTIFICIAL_LIGHT: (state, value) => {
    state.artificialLigthActive = value
  },
  SAVE_ARTIFICIAL_LIGTH: (state, value) => {
    const idLigthFound = state.artificialLigths.findIndex(el => el.id === value.id)
    if (idLigthFound) state.artificialLigths.splice(idLigthFound, 1, value)
    else if (typeof idLigthFound !== 'number') state.artificialLigths.push(value)
  },
};


const actions = {
  setZeroNbArtificialLight: state => { state.commit('SET_ZERO_NB_ARTIFICIAL_LIGHT') },
  incrementNbArtificialLight: state => { state.commit('INCREMENT_NB_ARTIFICIAL_LIGHT') },

  updateValue: (state, options) => { console.log(options); state.commit('UPDATE_VALUE', options) },
  toggleIsMainLight: state => { state.commit('TOGGLE_IS_MAIN_LIGHT') },
  toggleIsDiffusedLight: state => { state.commit('TOGGLE_IS_DIFFUSED_LIGHT') },
  toggleHaveArtificialLight: async state => {
    const ligths = state.rootGetters['scenes/artificialLights/artificialLigths']
    if (ligths.length === 0) {
      state.dispatch('addArtificialLight')
      const ligth = state.rootGetters['scenes/artificialLights/artificialLigths'][0]
      state.dispatch('setArtificialLight', ligth.name)
    }
    state.commit('TOGGLE_HAVE_ARTIFICIAL_LIGHT')
  },
  addArtificialLight: (state) => {
    // const artificialLigthsCount = state.rootGetters['scenes/artificialLights/artificialLigthsCount']
    // if (artificialLigthsCount >= 0 && artificialLigthsCount < 4) {
    const artificialLigthInit = state.rootGetters['scenes/artificialLigthInit']

    // const artificialLigthInit = {}
    // const artificialLigthKey = state.rootGetters['scenes/artificialLights/artificialLigthKey']
    // for (const key of artificialLigthKey) {
    //   artificialLigthInit[key] = state.rootGetters[`scenes/${key}`]
    // }
    state.commit('ADD_ARTIFICIAL_LIGHT', artificialLigthInit)
    return artificialLigthInit
    // }
  },
  setArtificialLight: (state, value) => {
    const artificialLigths = state.rootGetters['scenes/artificialLights/artificialLigths']
    const [, id] = value.match(/^lumi(?:e|è)re (\d)$/i)
    const ligthFound = artificialLigths.find(el => el.id == id)
    state.commit('SET_ARTIFICIAL_LIGHT', ligthFound)
  },
  saveArtificialLigth: (state, value) => {
    console.log(value)
    state.commit('SAVE_ARTIFICIAL_LIGTH', value)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};