import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        black: '#000000',
        dGrey: '#666666',
        lGrey: '#e0e0e0',
        white: '#ffffff',

        primary1: '#00ffff',
        primary2: '#ff00ff',
        primary3: '#ff48b7',
        primary4: '#ffff00',

        secondery3: '#ffa4db'
      }
    }
  }
});
