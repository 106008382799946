<template lang="pug">
div
  v-card.rounded-xl.d-flex.justify-space-between.flex-column.elevation-0.pa-1.py-2(color="lGrey")
    slot
</template>

<script>
  export default {
    name: 'BoxRoundedGrey',

    components: {},
    data: () => ({}),
    methods: {},
    computed: { },
    async mounted() {},
  }
</script>

<style lang="scss">
  
</style>