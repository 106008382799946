import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'

// import UsersService from "@/services/users.service";

import { store } from "@/plugins/store/index.js";

// import UserProfil from '@/views/UserProfil.view'
// import UserProfil__Settings from '@/views/UserProfil__Settings.view'
import AuthWrapper from '@/AuthWrapper'
import Login from '@/views/login/Login.view'

import Visualisation from '@/views/visualisation/Visualisation.view'
import Cloakroom from '@/views/visualisation/cloakroom/Cloakroom.view'
import Profils from '@/views/visualisation/profiles/Profils.view'
import Replays from '@/views/visualisation/replays/Replays.view'
import SceneSettings from '@/views/visualisation/sceneSettings/SceneSettings.view'
import AddClothes from '@/components/AddClothes.component'
import firstConnection from '@/views/visualisation/firstConnection/firstConnection.view'

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    name: 'AppGard',
    props: route => ({
      accessToken: route.query.accessToken,
      // email: route.query.email,
      // password: route.query.password
    }),
    component: AuthWrapper,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresInit: true }
  },
  {
    path: '/visualisation',
    name: 'Visualisation',
    component: Visualisation,
    redirect: { name: 'Cloakroom' },
    meta: { requiresInit: true },
    children: [
      {
        path: 'cloakroom',
        name: 'Cloakroom',
        component: Cloakroom,
        meta: { requiresAuth: true, requiresInit: true }

      },
      {
        path: 'profils',
        name: 'Profils',
        component: Profils,
        meta: { requiresAuth: true, requiresInit: true }
      },
      {
        path: 'replays',
        name: 'Replays',
        component: Replays,
        meta: { requiresAuth: true, requiresInit: true }
      },
      {
        path: 'sceneSettings',
        name: 'SceneSettings',
        component: SceneSettings,
        meta: { requiresAuth: true, requiresInit: true }
      }
    ]
  },
  { //à supprimer 
    path: '/addClothes',
    name: 'AddClothes',
    component : AddClothes,
    meta: { requiresAuth: true, requiresInit: true },
    children: [
      {
        path: '',
        name: 'ProfilsAdmin',
        component: Profils,
        meta: { requiresAuth: true, requiresInit: true }
      },
      {
        path: '',
        name: 'CloakroomAdmin',
        component: Cloakroom,
        meta: { requiresAuth: true, requiresInit: true }
      },
    ]
  },
  {
    path: '/firstConnection',
    name: 'firstConnection',
    component: firstConnection,
    meta: { requiresAuth: true, requiresInit: true }
  }
  // {
  //   path: '/profil/:userName',
  //   name: 'UserProfil',
  //   component: UserProfil,
  //   meta: { requiresAuth: true },
  //   children: [
  //     {
  //       path: 'settings',
  //       name: 'UserProfil__Settings',
  //       component: UserProfil__Settings,
  //       meta: { requiresAuth: true }
  //     }
  //   ]
  // }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.beforeEach(async (to, from, next) => {
//   if (
//     from.matched.some(record => /AppGard/i.test(record.name)) ||
//     to.matched.some(record => /AppGard/i.test(record.name))
//   ) return next()

//   if (from.name === null && to.name) {
//     console.log('Get Session Token');
//     const accessToken = localStorage.getItem("accessToken")
//     if (accessToken) {
//       await store.dispatch('initialisation', accessToken)
//       const initData = store.getters["initData"]
//       if (initData) {
//         if (
//           from.matched.some(record => /Login/i.test(record.name)) ||
//           to.matched.some(record => /Login/i.test(record.name))
//         ) {
//           const userToken = localStorage.getItem("userToken")
//           if (!userToken) return next()
//           return next({ name: 'Profils' })
//         }
//         const userToken = localStorage.getItem("userToken")
//         if (userToken) await store.dispatch('users/localLogin', userToken)
//         else next({ name: 'Login' })
//       } else next({ name: 'AppGard' })
//     } else next({ name: 'AppGard' })
//   }
//   next()
// })

const logBeforeEachFromTo = (from, to) => {
  console.log('----------------------------------')
  console.log('beforeEach - FROM : ', from)
  console.log('⬇')
  console.log('beforeEach - TO : ', to)
  console.log('----------------------------------')
}

// router.beforeEach(async (to, from, next) => {
//   logBeforeEachFromTo(from, to)
//   if (
//     from.matched.some(record => /AppGard/i.test(record.name)) ||
//     to.matched.some(record => /AppGard/i.test(record.name))
//   ) {
//     const accessToken = localStorage.getItem("accessToken")
//     if (accessToken == "" || accessToken == null) {
//       console.log('AppGard next');
//       return next()
//     }
//   }
router.beforeEach(async (to, from, next) => {
  logBeforeEachFromTo(from, to)
  if (
    from.matched.some(record => /AppGard/i.test(record.name)) ||
    to.matched.some(record => /AppGard/i.test(record.name))
  ) {
    console.log('AppGard next');
    return next()
  }

  
  const accessToken = localStorage.getItem("accessToken")
  // console.log("==============", accessToken);
  if (from.name === null && to.name) {
    console.log('Get Session Token');
    if (accessToken) {

      await store.dispatch('initialisation', accessToken)
      const initData = store.getters["initData"]
     
      console.log('====================================');
      // console.log();
      console.log("------------", initData)

      console.log('====================================');
      if (initData) {
        console.log('Initialisation Data - OK');
        const userToken = localStorage.getItem("userToken")
        // console.log("+++++++++++",userToken)
        if (to.matched.some(record => /Login/i.test(record.name))) {
          logBeforeEachFromTo(from, to)
          if (!userToken) return next()
          return next({ name: 'Profils' })
        }
        if (userToken) await store.dispatch('users/localLogin', userToken)
        else next({ name: 'Login' })
      } else {
        console.log('Initialisation Data - ERROR');
        next({ name: 'AppGard' })
      }
    } else {
      console.log('AccessToken - NOT ACCESS');
      next({ name: 'AppGard' })
    }
  }
  next()
})
