<template lang="pug">
.autoSelectRef.d-flex.flex-column
  v-label(v-if="title") {{ title }}
  .autoSelectRef__switch.rounded-pill.d-flex.justify-space-between.pa-1
    .switch.rounded-pill.d-flex.justify-center.align-center(
      v-if="title && val.name != ''",
      v-for="val in valuesList",
      @click="commandToSend(val)"
    ) {{ val.name }}

    .switch.selector.rounded-pill.d-flex.justify-center.align-center(
      v-if="commandValueName != ''",
      :class="[`selector__${commandValue}`, `selector__col${valuesList[0].command.value == null ? valuesList.length - 1 : valuesList.length}`]"
    ) {{ `${commandValueName}` }}
</template>

<script>
import Vuex from "vuex";
export default {
  name: "Switch",
  data: () => ({}),
  props: {
    title: {
      type: String,
      required: true,
    },
    valuesList: {
      type: Array,
      required: true,
    },
    command: {
      type: String,
      required: true,
    },
    onClick: {
      type: [Function],
      required: true,
    },
  },
  computed: {
    ...Vuex.mapGetters({
      getValueInStringCommand: "communication/commands/getValueInStringCommand",
    }),
    commandValue() {
      return this.getValueInStringCommand(this.command);
    },
    commandValueName() {
      const valuesList = this.valuesList;
      const valueInStringCommand = this.getValueInStringCommand(this.command);
      const value = valuesList.find((el) => {
        return `${el.command.value}` == valueInStringCommand;
      });
      return value.name;
    },
  },
  methods: {
    ...Vuex.mapActions({
      // getValueInStringCommand: "communication/commands/getValueInStringCommand",
    }),
    // getValueInStringCommand() {
    //   const { commandValue } = this.command.match(
    //     /^(?<commandNamespace>.+)::(?<commandKey>.+)::(?<commandValue>.+)$/
    //   ).groups;
    //   return commandValue;
    // },
    commandToSend(command) {
      console.log("command", command);
      return this.onClick(command);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.autoSelectRef {
  width: 100%;

  > .v-label {
    width: 100%;
    text-align: center;
  }
  > .autoSelectRef__switch {
    position: relative;
    width: 100%;
    height: 38px;
    background-color: var(--v-lGrey-base);
    * {
      font-size: 16px;
    }
    .switch {
      position: relative;
      z-index: 10;
      width: 49%;
      height: 100%;
      color: var(--v-dGrey-base);
      font-weight: bold;
      background-color: transparent;
      cursor: pointer;
      // background-color: var(--v-dGrey-base);
      &.selector {
        z-index: 20;
        height: calc(100% - (2 * 4px));
        position: absolute;
        color: var(--v-lGrey-base);
        background-color: var(--v-dGrey-base);
        &.selector__col1 {
          width: calc(100% - 2%);
        }
        &.selector__col2 {
          width: calc(((100% - 2%) / 2));
        }
        &.selector__col3 {
          width: calc(((100% - 2%) / 3));
        }
        &.selector__col4 {
          width: calc(((100% - 2%) / 4));
        }
        &.selector__col5 {
          width: calc(((100% - 2%) / 5));
        }
        &.selector__col6 {
          width: calc(((100% - 2%) / 6));
        }
        &.selector__col7 {
          width: calc(((100% - 2%) / 7));
        }
        &.selector__col8 {
          width: calc(((100% - 2%) / 8));
        }

        &.selector__true {
          left: 4px;
        }
        &.selector__false {
          right: 4px;
        }

        &.selector__direct_sunlight {
          left: 4px;
        }
        &.selector__no_natural_light {
          left: calc(100% / 3);
        }
        &.selector__diffuse_daylight {
          right: 4px;
        }

        &.selector__Artificielle {
          right: 4px;
        }

        &.selector__9 {
          left: 4px;
        }
        &.selector__10 {
          left: calc(100% / 7);
        }
        &.selector__11 {
          left: calc((100% * 2) / 7);
        }
        &.selector__12 {
          right: calc((100% * 3) / 7);
        }
        &.selector__1 {
          right: calc((100% * 2) / 7);
        }
        &.selector__2 {
          right: calc(100% / 7);
        }
        &.selector__3 {
          right: 4px;
        }

        // &.selector__null {
        //   left: 4px;
        // }
        &.selector__0 {
          left: 4px;
        }
        &.selector__25 {
          left: calc(100% / 5);
        }
        &.selector__45 {
          left: calc((100% * 2) / 5);
        }
        &.selector__65 {
          right: calc(100% / 5);
        }
        &.selector__90 {
          right: 4px;
        }

        &.selector__zénithal {
          left: 4px;
        }
        &.selector__haut-mural {
          left: calc(100% / 4);
        }
        &.selector__table-commode {
          right: calc(100% / 4);
        }
        &.selector__bas {
          right: 4px;
        }

        &.selector__5800 {
          left: 4px;
        }
        &.selector__7200 {
          right: 4px;
        }

        &.selector__2700 {
          left: 4px;
        }
        &.selector__Orange {
          left: 4px;
        }
        &.selector__3000 {
          left: calc(100% / 3);
        }
        &.selector__4000 {
          right: 4px;
        }
        &.selector__Neutre {
          right: 4px;
        }
      }
    }
  }
}
</style>
