//import getFromLocalStorage from "../../../utils/utils";
const state = {
    states: {
        namespace: 'State',
        key: 'setState',
        defaultValue: 'connected',
        value: null,

        settingsZoom: false,
        settingsMove: false,

        valuesList: [
            { name: "Connected", command: { key: "setState", value: "connected"} },
            { name: "PoseTaking", command: { key: "setState", value: "fitting" } },
            { name: "TakeMeasure", command: { key: "setState", value: "tunnelMorpho"} },
            { name: "MorphoTaking", command: { key: "setState", value: "morpho" } },
            { name: "Disconnected", command: { key: "setState", value: "disconnected"} }
        ]
    },
};
  
const getters = {
    list: state => state.states.valuesList,
    states: state => state.states,
    settingsZoom: state => state.states.settingsZoom,
    settingsMove: state => state.states.settingsMove
};
  
const mutations = {
    SET_SETTINGS_ZOOM: (state, settingsZoom) => { state.states.settingsZoom = settingsZoom },
    SET_SETTINGS_MOVE: (state, settingsMove) => { state.states.settingsMove = settingsMove }
};
    
const actions = {
    selectState: (state, stat) => {
      console.log(state)
      state.dispatch('communication/commands/updateListOfCommand', [stat.command], { root: true })
      state.dispatch('communication/commands/sendCommand', null, { root: true })
    },
    setSettingsZoom: (state, settingsZoom) => {
        state.commit('SET_SETTINGS_ZOOM', settingsZoom)
    },
    setSettingsMove: (state, settingsMove) => { 
        state.commit('SET_SETTINGS_MOVE', settingsMove)
    }
    
};
  
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};