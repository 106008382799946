//import { isEmptyObject, isJson } from "../../../../utils/utils";
import { isJson, getFromLocalStorage } from "../../../../utils/utils";
const inflect = require("inflection");

const state = {
  baseCommands: {
    setDecor: {
      name: 'Scene::Decor::set',
      defaultValue: 'no_decor',
      value: null,
    },

    startVisualisation: {
      name: 'Visualisation::control_visualisation',
      defaultValue: false,
      value: null,
      valuesList: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ]
    },
    canal: {
      name: 'Visualisation::canal',
      defaultValue: 'camera',
      value: null,
    },
    selectPeer: {
      name: 'Communication::select_peer',
      defaultValue: null,
      value: null,
    },
    addPeer: {
      name: 'Communication::add_peer',
      defaultValue: null,
      value: null,
    },
    hasVisualisation: {
      name: 'General::has_visualisation',
      defaultValue: true,
      value: null,
      valuesList: [
        { name: 'Oui', value: true },
        { name: 'Non', value: false },
      ]
    },
  },

  topClothCommands: {
    haveButton: {
      namespace: 'Clothes:Parameters',
      key: 'have_button',
      defaultValue: null,
      value: null,
      valuesList: [
        { name: 'Oui', value: true },
        { name: 'Non', value: false },
      ]
    },
    setNeck: {
      namespace: 'Clothes:Parameters',
      key: 'set_neck',
      defaultValue: null,
      value: null,
    },
    isInPant: {
      namespace: 'Clothes:Parameters',
      key: 'is_in_pant',
      defaultValue: null,
      value: null,
      valuesList: [
        { name: 'Oui', value: true },
        { name: 'Non', value: false },
      ]
    },
    setLeftWrist: {
      namespace: 'Clothes:Parameters',
      key: 'set_left_wrist',
      defaultValue: null,
      value: null,
    },
    setRightWrist: {
      namespace: 'Clothes:Parameters',
      key: 'set_right_wrist',
      defaultValue: null,
      value: null,
    },
  },

  bottomClothCommands: {
    setWearing: {
      namespace: 'Clothes:Parameters',
      key: 'set_wearing',
      defaultValue: 'waist',
      value: null,
    },
    setLeftHip: {
      namespace: 'Clothes:Parameters',
      key: 'set_left_hip',
      defaultValue: null,
      value: null,
    },
    setRightHip: {
      namespace: 'Clothes:Parameters',
      key: 'set_right_hip',
      defaultValue: null,
      value: null,
    },
    setLeftAnkle: {
      namespace: 'Clothes:Parameters',
      key: 'set_left_ankle',
      defaultValue: null,
      value: null,
    },
    setRightAnkle: {
      namespace: 'Clothes:Parameters',
      key: 'set_right_ankle',
      defaultValue: null,
      value: null,
    },
    risePants: {
      namespace: 'Clothes:Parameters',
      key: 'rise_pants',
      defaultValue: null,
      value: null,
    },
  },

  actualCommand: null,
};


const getters = {
  getAllCommands: () => JSON.parse(localStorage.getItem('commands')),

  getClothCommands: state => clothType => state[`${clothType}ClothCommands`],

  getCommand: state => key => {
    const commands = state.actualCommand.find(el => new RegExp(`${key}`).test(el));
    return commands
  },

  getValueInStringCommand: () => command => {
    const { commandValue } = command.match(
      /^(?<commandNamespace>.+)::(?<commandKey>.+)::(?<commandValue>.+)$/
    ).groups;
    return commandValue;
  },
  getCommandWhithoutGarmentByBarCode: () => barCode => {
    return JSON.parse(localStorage.getItem('commands')).filter(el => !(new RegExp(`"garmentBarCode":"${barCode}"`).test(el)))
  }
};

const mutations = {
  PUSH_LIST_OF_COMMAND: (state, newList) => {
    localStorage.setItem('commands', JSON.stringify([
      ...JSON.parse(localStorage.getItem('commands')),
      ...newList
    ]))
  },
  UPDATE_LIST_OF_COMMAND: (state, newList) => {
    localStorage.setItem('commands', JSON.stringify(newList))
  },
  SET_ACTUAL_COMMAND: (state, listCommands) => {
    state.actualCommand = JSON.parse(JSON.stringify(listCommands))
  }
};

const actions = {
  getBaseCommands: state => {
    return {
      framings: state.rootGetters["framings/framings"],
      autoScene: state.rootGetters["scenes/getInfoCommand"]('autoScene'),
      selectNaturalLight: state.rootGetters["scenes/getInfoCommand"]('selectNaturalLight'),
      selectArtificialLight: state.rootGetters["scenes/getInfoCommand"]('selectArtificialLight'),
      diffuseDaylightWithSky: state.rootGetters["scenes/getInfoCommand"]('diffuseDaylightWithSky'),
      diffuseDaylightWithHourAngle: state.rootGetters["scenes/getInfoCommand"]('diffuseDaylightWithHourAngle'),
      diffuseDaylightSetHourAngle: state.rootGetters["scenes/getInfoCommand"]('diffuseDaylightSetHourAngle'),
      directSunlightSetInclinason: state.rootGetters["scenes/getInfoCommand"]('directSunlightSetInclinason'),
      directSunlightSetHourAngle: state.rootGetters["scenes/getInfoCommand"]('directSunlightSetHourAngle'),
      artificialLight1SetColorTemperature: state.rootGetters["scenes/getInfoCommand"]('artificialLight1SetColorTemperature'),
      artificialLight1IsDiffuse: state.rootGetters["scenes/getInfoCommand"]('artificialLight1IsDiffuse'),
      artificialLight1IsMain: state.rootGetters["scenes/getInfoCommand"]('artificialLight1IsMain'),
      artificialLight1SetInclinason: state.rootGetters["scenes/getInfoCommand"]('artificialLight1SetInclinason'),
      artificialLight1SetHourAngle: state.rootGetters["scenes/getInfoCommand"]('artificialLight1SetHourAngle'),
      artificialLight2SetColorTemperature: state.rootGetters["scenes/getInfoCommand"]('artificialLight2SetColorTemperature'),
      artificialLight2IsDiffuse: state.rootGetters["scenes/getInfoCommand"]('artificialLight2IsDiffuse'),
      artificialLight2IsMain: state.rootGetters["scenes/getInfoCommand"]('artificialLight2IsMain'),
      artificialLight2SetInclinason: state.rootGetters["scenes/getInfoCommand"]('artificialLight2SetInclinason'),
      artificialLight2SetHourAngle: state.rootGetters["scenes/getInfoCommand"]('artificialLight2SetHourAngle'),
      artificialLight3SetColorTemperature: state.rootGetters["scenes/getInfoCommand"]('artificialLight3SetColorTemperature'),
      artificialLight3IsDiffuse: state.rootGetters["scenes/getInfoCommand"]('artificialLight3IsDiffuse'),
      artificialLight3IsMain: state.rootGetters["scenes/getInfoCommand"]('artificialLight3IsMain'),
      artificialLight3SetInclinason: state.rootGetters["scenes/getInfoCommand"]('artificialLight3SetInclinason'),
      artificialLight3SetHourAngle: state.rootGetters["scenes/getInfoCommand"]('artificialLight3SetHourAngle'),
      artificialLight4SetColorTemperature: state.rootGetters["scenes/getInfoCommand"]('artificialLight4SetColorTemperature'),
      artificialLight4IsDiffuse: state.rootGetters["scenes/getInfoCommand"]('artificialLight4IsDiffuse'),
      artificialLight4IsMain: state.rootGetters["scenes/getInfoCommand"]('artificialLight4IsMain'),
      artificialLight4SetInclinason: state.rootGetters["scenes/getInfoCommand"]('artificialLight4SetInclinason'),
      artificialLight4SetHourAngle: state.rootGetters["scenes/getInfoCommand"]('artificialLight4SetHourAngle'),
    }
  },
  initBaseCommand: async (state) => {
    const stringCommands = []
    const baseCommands = await state.dispatch('getBaseCommands')
    const baseCommandKey = Object.keys(baseCommands)

    for (const key of baseCommandKey) stringCommands.push(await state.dispatch('constructStringCommand', baseCommands[key]))
    state.commit('UPDATE_LIST_OF_COMMAND', stringCommands)
    state.commit('SET_ACTUAL_COMMAND', stringCommands)
  },
  deleteAllCommand: () => {
    localStorage.removeItem('commands')
  },
  updateListOfCommand: (state, commands) => {
    let actualListOfCommand = getFromLocalStorage('commands')
    const newCommands = commands
    let foundCommandIndex = null

    const toAddCommands = newCommands.map(c => {
      foundCommandIndex = actualListOfCommand.findIndex(el => new RegExp(`::${c.key}::`).test(el))
      if (foundCommandIndex === -1) {
        console.log("La commande n'est pas dans la liste !!!");
      }
      const foundCommand = actualListOfCommand.find(el => new RegExp(`::${c.key}::`).test(el))
      return foundCommand.replace(new RegExp(`(::${c.key}::).+$`), `$1${c.value}`)
    })

    actualListOfCommand.splice(foundCommandIndex, 1)

    state.commit('UPDATE_LIST_OF_COMMAND', [...toAddCommands, ...actualListOfCommand])
    state.commit('SET_ACTUAL_COMMAND', [...toAddCommands, ...actualListOfCommand])
  },
  sendCommand: () => {
    const commands = getFromLocalStorage('commands')
    console.log('commandToSend', commands)
     const peer = state.rootGetters['communication/peerClient']//c'etait mute
     //peer.sendCommand(commandToSend)//c'etait mute
     peer.sendCommand(commands)
  },
  constructStringCommand: (state, { namespace, key, value, defaultValue }) => {
    if (value !== null && (defaultValue !== undefined || defaultValue === undefined)) {
      return `${namespace}::${key}::${typeof value === 'object' && isJson(value)
        ? JSON.stringify(value)
        : value}`
    }
    else if ((value === undefined || value === null) && defaultValue !== undefined) {
      return `${namespace}::${key}::${typeof defaultValue === 'object' && isJson(defaultValue)
        ? JSON.stringify(defaultValue)
        : defaultValue}`
    }
  },
  initClothCommandWhenAClothWasAdded: async (state, { cloth, clothBarCode, recommandedClothParams, clothType }) => {
    const recommandedParams = JSON.parse(recommandedClothParams)

    let commandCloth = {}
    switch (true) {
      case /bottom/.test(clothType): commandCloth = state.rootGetters['communication/commands/getClothCommands']('bottom'); break;
      case /top/.test(clothType): commandCloth = state.rootGetters['communication/commands/getClothCommands']('top'); break;
      default:
        break;
    }

    // console.log('commandCloth WITHOUT default', commandCloth);
    for (const [key, value] of Object.entries(commandCloth)) {
      commandCloth[key] = {
        ...commandCloth[key],
        defaultValue: value.defaultValue = { clothBarCode }
      }
    }

    for (const param of recommandedParams) {
      const name = param.name.slice(0, -1)
      if (param.params.isLeftAndRigthLinked) {
        commandCloth[`setLeft${inflect.capitalize(name)}`].defaultValue.value = param.params.both
        commandCloth[`setRight${inflect.capitalize(name)}`].defaultValue.value = param.params.both
      } else {
        commandCloth[`setLeft${inflect.capitalize(name)}`].defaultValue.value = param.params.left
        commandCloth[`setRight${inflect.capitalize(name)}`].defaultValue.value = param.params.right
      }
    }
    // console.log('commandCloth WITH default', commandCloth)

    const stringCommands = []
    for (const key of Object.keys(commandCloth)) stringCommands.push(await state.dispatch('constructStringCommand', commandCloth[key]))


    //provisoire 
    console.log('majCommande')
    console.log(commandCloth)
    
    const CommandWhithoutGarmentByBarCode = state.rootGetters['communication/commands/getCommandWhithoutGarmentByBarCode'](clothBarCode)
    console.log(cloth)
  
    console.log(cloth)
    const wearingParams = cloth.wearingParams
    console.log(wearingParams)
    const newClothCommand = []
    console.log(wearingParams.length)
    console.log(wearingParams)
    
    for (const wp of wearingParams) {
      console.log(wp)
      newClothCommand.push(`Clothes:Parameters::set_right_${wp.name.slice(0, -1)}::{"garmentBarCode":"${clothBarCode}"::"value":"${wp.params.isLeftAndRigthLinked ? wp.params.both : wp.params.right}"}`)//le :: value etait un ,value
      newClothCommand.push(`Clothes:Parameters::set_left_${wp.name.slice(0, -1)}::{"garmentBarCode":"${clothBarCode}"::"value":"${wp.params.isLeftAndRigthLinked ? wp.params.both : wp.params.left}"}`)
      //newClothCommand.push('heyyy')
    }
    console.log(newClothCommand)
    state.commit('UPDATE_LIST_OF_COMMAND', [...CommandWhithoutGarmentByBarCode, ...newClothCommand])
    state.commit('SET_ACTUAL_COMMAND', [...CommandWhithoutGarmentByBarCode, ...newClothCommand])
    console.log(newClothCommand)
    console.log(state)
    //fin provisoire
    
    console.log('commandCloth STRING', stringCommands)
    
    const actualCommand = JSON.parse(localStorage.getItem('commands'))
    state.commit('PUSH_LIST_OF_COMMAND', newClothCommand)
    state.commit('SET_ACTUAL_COMMAND', [...actualCommand, ...newClothCommand])

    // Notify backend Visualisation ( remote-peer )
  },
  updateValueCommand: (state, currentCloth) => {
    const CommandWhithoutGarmentByBarCode = state.rootGetters['communication/commands/getCommandWhithoutGarmentByBarCode'](currentCloth.barCode)
    const wearingParams = currentCloth.wearingParams

    const newClothCommand = []
    console.log("passe")
    console.log(currentCloth)
   
    for (const wp of wearingParams) {
    
      console.log(wearingParams.length)
      console.log(wearingParams)


      newClothCommand.push(`Clothes:Parameters::set_right_${wp.name.slice(0, -1)}::{"garmentBarCode":"${currentCloth.barCode}"::"value":"${wp.params.isLeftAndRigthLinked ? wp.params.both : wp.params.right}"}`)//le :: value etait un ,value
      newClothCommand.push(`Clothes:Parameters::set_left_${wp.name.slice(0, -1)}::{"garmentBarCode":"${currentCloth.barCode}"::"value":"${wp.params.isLeftAndRigthLinked ? wp.params.both : wp.params.left}"}`)
    }

    state.commit('UPDATE_LIST_OF_COMMAND', [...CommandWhithoutGarmentByBarCode, ...newClothCommand])
    state.commit('SET_ACTUAL_COMMAND', [...CommandWhithoutGarmentByBarCode, ...newClothCommand])
  },
  updateBarCodeCommand: (state, {currentClothe, newClothe}) => { 
    console.log("garment",currentClothe)
    const CommandWhithoutGarmentByBarCode = state.rootGetters['communication/commands/getCommandWhithoutGarmentByBarCode'](currentClothe.barCode)
    const wearingParams = currentClothe.wearingParams
    const newClothCommand = []
    console.log("passe")
    let cont = 0;
    for (const wp of wearingParams) {
      cont = cont + 1;
      console.log(cont);

      newClothCommand.push(`Clothes:Parameters::set_right_${wp.name.slice(0, -1)}::{"garmentBarCode":"${newClothe.barCode}"::"value":"${wp.params.isLeftAndRigthLinked ? wp.params.both : wp.params.right}"}`)
      newClothCommand.push(`Clothes:Parameters::set_left_${wp.name.slice(0, -1)}::{"garmentBarCode":"${newClothe.barCode}"::"value":"${wp.params.isLeftAndRigthLinked ? wp.params.both : wp.params.left}"}`)
    }
    state.commit('UPDATE_LIST_OF_COMMAND', [...CommandWhithoutGarmentByBarCode, ...newClothCommand])
    state.commit('SET_ACTUAL_COMMAND', [...CommandWhithoutGarmentByBarCode, ...newClothCommand])
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,

};


