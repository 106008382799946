<template lang="pug">
.cloakroom__wraper
  v-card-title.d-flex
    span.mr-auto {{ $t('sideBarLeft.title[0].name') }}
    v-icon.mr-4 mdi-reorder-horizontal
    v-icon mdi-filter
  v-expansion-panels(
    v-if="Object.keys(clothesList).length > 0",
    accordion,
    flat
  )
    template(v-for="(clothesKey, i) in Object.keys(clothesList)")
      v-expansion-panel(:key="i")
        v-expansion-panel-header {{ clothesKey }}
        v-expansion-panel-content
        Article(
          style="{z-index: 50; position: relative}"
          v-for="cloth in clothesList[clothesKey]",
          :key="cloth.id",
          :cloth="cloth"
        )

          
      v-divider
  .cloakroomContent.d-flex.align-center.justify-center.px-5(v-else)
    v-card-text.text-center {{ $t('sideBarLeft.emptyCloakroom') }}
</template>

<script>
import Vuex from "vuex";
import Article from "@/components/visualisation/cloakroom/Article.component";

export default {
  name: "Cloakroom",

  components: { Article },
  data: () => ({}),
  methods: {
    ...Vuex.mapActions({
      getItems: "clothes/getCloakroom",
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      clothesList: "clothes/cloakroomOrderByTypeCloth",
    }),
  },
    // watch: {
  //   clothesList(newVal) {
  //     console.log("newVal", newVal);
  //   },
  // },
  async created() {
    // console.log(this);
    // this.cloakroom = await this.$store.dispatch("clothes/getCloakroom");
    await this.getItems();
  },

  mounted() {
    // Check if the page was already refreshed
    if (!sessionStorage.getItem('refreshed')) {
      // Set the flag and refresh the page
      sessionStorage.setItem('refreshed', 'true');
      window.location.reload();
    }
    // If 'refreshed' is set, do not refresh the page again
  }
};
</script>

<style lang="scss">
.cloakroom__wraper {
  height: calc(100% - 58px - 64px);
  .v-card__title {
    .v-icon {
      cursor: pointer;
    }
  }
  .cloakroomContent {
    height: 100%;
  }

  .v-expansion-panels {
    max-height: 100%;
    overflow: scroll;
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 0;
      }
    }
  }
}
</style>