import UsersService from "@/services/users.service";

import profils from '@/plugins/store/modules/users/profils.fake';
import { router } from '@/plugins/router'

const us = new UsersService();

const state = {
  // initiator: {},
  users: [],
  user: {},
  status: '', // loading | success | error
};

const getters = {
  current: (state) => state.user,
  list: (state) => state.users,
  isLogin: () => !!localStorage.getItem('userToken'),
  status: (state) => state.status,
};

const mutations = {
  SET_USERS: (state, users) => { state.users = users; },
  SET_USER: (state, user) => { state.user = { ...user.user, ...state.user }; },
  UNSET_USER: (state) => { state.user = {}; },
  CHECKOUT_ERROR: (state) => { state.status = 'error'; },
  CHECKOUT_SUCCESS: (state) => { state.status = 'success'; },
  CHECKOUT_LOADING: (state) => { state.status = 'loading'; },
  LOGIN: (state, token) => { localStorage.setItem('userToken', token) },
  // LOGOUT: () => { localStorage.removeItem('userToken') },
  LOGOUT: () => { 
    localStorage.removeItem('userToken'); 
    localStorage.removeItem('userEmail');  
    localStorage.removeItem('accessToken');  
    sessionStorage.removeItem('refreshed');
  },
};


const actions = {
  initUser: async (state, { idUser, localLogin = true }) => {
    console.log('localLogin', localLogin);
    try {
    await state.dispatch('users/setUser', idUser, { root: true })
    } catch (error) {
      console.log('==================error==================');
      console.log(error);
      console.log('====================================');
    }
    

    // router.push({ name: 'Profils' })

    const profileId = localStorage.getItem('profileId')
    const profileList = state.rootGetters['users/profils/list']

    if (localLogin && profileList.length > 0) {
      state.dispatch('users/profils/setCurrentProfil', profileList[0], { root: true });
      return;
    }

    if (!localLogin) {
      if (profileId) {
        const profileFound = profileList.find(el => el.id == profileId)
        state.dispatch('users/profils/setCurrentProfil', profileFound, { root: true })
        router.push({ name: 'Cloakroom' })
      } else {
        if (profileList.length === 1) {
          state.dispatch('users/profils/setCurrentProfil', profileList[0], { root: true })
          var avatar = await state.dispatch('users/profils/avatars/get',profileList[0].id, { root: true })
          console.log("avatar.default",avatar.avatar.parameters.default)
          if (avatar.avatar.parameters.default) { //firstConnection with a profile
            console.log("entre")
            router.push({ name: 'firstConnection' })
          }
          else router.push({ name: 'Cloakroom' })
        }
        else {
          if (profileList.length === 0) { //TODO ajout d'un profil dans le cas où il n'y en avait pas
            console.log("2 ")
            const user = state.rootGetters['users/current']
            await state.dispatch('users/profils/createProfil', user, { root: true})
            const profileList = state.rootGetters['users/profils/list']
            state.dispatch('users/profils/setCurrentProfil', profileList[0], { root: true })
            router.push({ name: 'firstConnection' })
          }
          else router.push({ name: 'Profils' })
        }
      }
    }
  },
  localLogin: async (state, oldToken) => {
    state.commit('users/CHECKOUT_LOADING', null, { root: true });

    const loginRes = await us.tokenLogin(oldToken).catch(error => {
      state.commit('CHECKOUT_ERROR', { name: error.response.data.name }, { root: true })
      console.log(error)
      state.commit('users/CHECKOUT_ERROR', null, { root: true })
    })

    state.commit('users/CHECKOUT_SUCCESS', null, { root: true });
    const { token, idUser } = loginRes.data
    state.commit('LOGIN', token);
    await state.dispatch('users/initUser', { idUser, localLogin: true }, { root: true })
  },
  login: async (state, user) => {
    state.commit('users/CHECKOUT_LOADING', null, { root: true });

    const initiator = state.rootGetters['initiator']
    const loginRes = await us.login(user, initiator).catch(error => {
      state.commit('CHECKOUT_ERROR', { name: error.response.data.name }, { root: true })
      console.log(error)
      state.commit('users/CHECKOUT_ERROR', null, { root: true })
    })

    state.commit('users/CHECKOUT_SUCCESS', null, { root: true });

    console.log(loginRes)
    const { token, idUser } = loginRes.data
    console.log(loginRes.data);
    state.commit('LOGIN', token);
    await state.dispatch('users/initUser', { idUser }, { root: true })
    // await state.dispatch('users/profils/setCurrentProfil', profil, { root: true })


    // const currentProfil = state.rootGetters['users/profils/current']
    // if (currentProfil && Object.keys(currentProfil).length > 0) router.push({ name: 'Cloakroom' })
    // else router.push({ name: 'Profils' })
  },
  logout: async (state) => {
    us.logout()
    state.commit('UNSET_USER');
    state.commit('LOGOUT');
    router.push({ name: 'Login' })
  },

  setUser: async (state, idUser) => {
    const user = (await us.get(idUser)
      .catch((err) => {
        console.log({ err });
        state.commit('CHECKOUT_ERROR');
      }));
    if (user && user.data) {
      const currentUser = user.data
      state.commit('CHECKOUT_SUCCESS');
      state.commit('SET_USER', currentUser);
      state.dispatch('users/profils/setListProfil', currentUser.user.Profils, { root: true })
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    profils
  }
};