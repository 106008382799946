import ClothesService from "@/services/clothes.service";

const { groupBy, isJson, dateToISOString } = require("../../../utils/utils");

const cs = new ClothesService()

const state = {
  cloakroom: {},
  seletedClothes: [],
  cloth: {},
  clothParam: {},
  clothes: [] //TODO
};

const getters = {
  cloakroom: (state) => state.cloakroom,
  cloakroomOrderByTypeCloth: (state) => groupBy(state.cloakroom.clothes, 'clothType'),
  selectedList: (state) => state.seletedClothes,
  current: (state) => state.cloth,
  currentWearingParam: (state) => state.clothParam,
  totalList: (state) => state.clothes, //TODO
};

const mutations = {
  SET_CLOAKROOM: (state, cloakroom) => { state.cloakroom = cloakroom; },
  SET_CLOTH: (state, cloth) => {
    const c = cloth
    let wearingParams = typeof c.wearingParams === "string"
      ? JSON.parse(c.wearingParams)
      : c.wearingParams
    delete c.wearingParams
    if (wearingParams) {
      wearingParams = wearingParams.sort((paramA, paramB) => {
        if (paramA.name > paramB.name) return 1
        else if (paramA.name < paramB.name) return -1
        else return 0
      });
    }
    state.cloth = { ...c, wearingParams }
  },
  SET_CLOTH_PARAM: (state, param) => { state.clothParam = param; },
  SET_CURRENT_PARAM_ON_CURRENT_CLOTH: (state, payload) => {
    const { currentWearingParam: param, current } = payload
    const indexToUpdate = current.wearingParams.findIndex(c => c.name === param.name)
    current.wearingParams.splice(indexToUpdate, 1, param)
    state.cloth = current
  },
  ADD_SELECTED_CLOTH_TO_SELECTEDCLOTHES: (state, cloth) => {
    console.log('===============cloth xxxx=====================');
    console.log(cloth);
    console.log('====================================');
     state.seletedClothes.push(cloth); },
  DELETE_SELECTED_CLOTH_FROM_SELECTEDCLOTHES: (state, clothRef) => {
    const dressingCloth = state.seletedClothes
    const clothToUndressId = dressingCloth.findIndex(el => el.garmentReference === clothRef)
    dressingCloth.splice(clothToUndressId, 1)
    state.seletedClothes = JSON.parse(JSON.stringify(dressingCloth))
  },

  DELETE_ALL_CLOTHES_FROM_SELECTEDCLOTHES: (state) => { //TODO
    state.seletedClothes = []
  },

  UPDATE_CLOTHES: (state, cloth) => {
    console.log("cloakroom.clothes avant :", state.cloakroom.clothes)
    const indexToUpdate = state.cloakroom.clothes.findIndex(c => c.id === cloth.id)
    console.log("cloakroom.clothes pendant :", state.cloakroom.clothes)
    state.cloakroom.clothes.splice(indexToUpdate, 1, cloth)
    console.log("cloakroom.clothes après :", state.cloakroom.clothes)
    // state.cloakroom.clothes = state.cloakroom.clothes;
  },
  UPDATE_SELECTED_CLOTHES: (state, cloth) => {
    const seletedClothes = state.seletedClothes
    const indexToUpdate = seletedClothes.findIndex(c => c.id === cloth.id)
    seletedClothes.splice(indexToUpdate, 1, cloth)
    state.seletedClothes = seletedClothes;
  },
  SET_CLOTHES: (state, clothes) => {state.clothes = clothes}, //TODO

  DELETE_FROM_SELECTED_CLOTHES: (state, cloth) => { //TODO
    const seletedClothes = state.seletedClothes
    const indexToDelete = seletedClothes.findIndex(c => c.id === cloth.id)
    seletedClothes.splice(indexToDelete, 1)
    state.seletedClothes = seletedClothes;
  },

  CHANGE_SELECTED_CLOTHES: (state, [oldClothe, newClothe]) => { //TODO
    const seletedClothes = state.seletedClothes
    const indexToChange = seletedClothes.findIndex(c => c.id === oldClothe.id)
    seletedClothes.splice(indexToChange, 1, newClothe)
    state.seletedClothes = seletedClothes;
  },
};


const actions = {
  selectCloth: (state, cloth) => {
    
    //console.log("LAAAAAA")
    console.log(state)
    //JSON.parse(cloth)
    //console.log(current)
    console.log("--------- ::::::: ", cloth)
    const selectedList = state.rootGetters['clothes/selectedList']
    const indexFound = selectedList.findIndex(el => el.id == cloth.id)
    console.log('=================indexFound=selectedList==================');
    console.log(cloth.id);
    console.log('====================================');
    if (indexFound === -1) {
      state.commit('ADD_SELECTED_CLOTH_TO_SELECTEDCLOTHES', JSON.parse(JSON.stringify(cloth)))
      if (selectedList.length == 1)
        state.commit('SET_CLOTH', JSON.parse(JSON.stringify(cloth)))
      //console.log("ICIIIII")
      let current = state.rootGetters['clothes/current']
      console.log(current)
      //cloth=JSON.parse(JSON.stringify(cloth))
      console.log(cloth)
      
      state.dispatch('communication/commands/initClothCommandWhenAClothWasAdded', {
        cloth: current,
        clothBarCode: cloth.barCode,
        clothType: cloth.clothType,
        recommandedClothParams: cloth.wearingRecommendation
      }, { root: true })
      //console.log('fin')
      console.log(state)

    } else state.commit('CHECKOUT_ERROR', { name: "ModaliveInterfaceClothAlreadyWearing" }, { root: true })
    
  },
  setCurrentCloth: (state, cloth) => {
    state.commit('SET_CLOTH', JSON.parse(JSON.stringify(cloth)))
  },
  setCurrentParam: (state, param) => {
    state.commit('SET_CLOTH_PARAM', param)
  },
  changeParamsValuesLeft: (state, value) => {
    console.log(value)
    let current = state.rootGetters['clothes/current']
    const currentWearingParam = state.rootGetters['clothes/currentWearingParam']
    currentWearingParam.params.left = value
    state.commit('SET_CURRENT_PARAM_ON_CURRENT_CLOTH', { currentWearingParam, current })
    current = state.rootGetters['clothes/current']
    state.dispatch('saveParams', current)
    state.dispatch('communication/commands/updateValueCommand', current, { root: true })
    console.log("hii")
    console.log(current)
  },
  changeParamsValuesRight: (state, value) => {
    console.log(value)
    let current = state.rootGetters['clothes/current']
    const currentWearingParam = state.rootGetters['clothes/currentWearingParam']
    currentWearingParam.params.right = value
    state.commit('SET_CURRENT_PARAM_ON_CURRENT_CLOTH', { currentWearingParam, current })
    current = state.rootGetters['clothes/current']
    state.dispatch('saveParams', current)
    state.dispatch('communication/commands/updateValueCommand', current, { root: true })
    console.log("hii")
    console.log(current)
  },
  changeParamsValuesBoth: (state, value) => {
    console.log(value)
    let current = state.rootGetters['clothes/current']
    const currentWearingParam = state.rootGetters['clothes/currentWearingParam']
    currentWearingParam.params.both = value
    state.commit('SET_CURRENT_PARAM_ON_CURRENT_CLOTH', { currentWearingParam, current })
    current = state.rootGetters['clothes/current']
    state.dispatch('saveParams', current)
    state.dispatch('communication/commands/updateValueCommand', current, { root: true })
    console.log("hii")
    console.log(current)
  },
  toggleLinked: (state, currentWearingParam) => {
    const current = state.rootGetters['clothes/current']
    currentWearingParam.params.isLeftAndRigthLinked = !currentWearingParam.params.isLeftAndRigthLinked
    state.commit('SET_CURRENT_PARAM_ON_CURRENT_CLOTH', { currentWearingParam, current })
  },
  saveParamsBeforeChangeTab: (state) => {
    const current = state.rootGetters['clothes/current']
    state.commit('UPDATE_CLOTHES', current)
    state.commit('UPDATE_SELECTED_CLOTHES', current)
  },


  addAClothe: (state, param) => { //TODO
    const clothe = {
      garmentReference: param.garmentReference,
      barCode: param.barCode,
      name: param.name,
      color: param.color,
      size: param.size,
      wearingRecommendation: param.wearingRecommendation,
      createdAt: dateToISOString(new Date()),
      updateAt: dateToISOString(new Date())
    }
    state.dispatch('postClothe', clothe)
  },

  deleteAClotheByBarCode: (state, barCode) => { //TODO
    state.dispatch('deleteBarCode', barCode)
  },

  undressCloth: (state) => {
    const clothRef = state.rootGetters['clothes/current'].garmentReference
    state.commit('DELETE_SELECTED_CLOTH_FROM_SELECTEDCLOTHES', clothRef)

    const allCommands = JSON.parse(localStorage.getItem('commands'))
    const withoutClothCommands = allCommands.filter(el => {
      const [namespace, , value] = el.split('::')
      // const namespace = [mainNamespace, subNamespace].join('::')
      if (isJson(value)) {
        const { clothRef: ref } = JSON.parse(value)
        return !(/Clothes:Parameters:?:?/i.test(namespace) && ref === clothRef)
      }
      return true
    })

    state.commit('communication/commands/UPDATE_LIST_OF_COMMAND', withoutClothCommands, { root: true })
    state.commit('communication/commands/SET_ACTUAL_COMMAND', withoutClothCommands, { root: true })

  },
  
  undressAllClothes: (state) => { //TODO
    const list = state.rootGetters['clothes/selectedList']
    list.forEach((clothe) => {
      const clothRef = clothe.garmentReference
      const allCommands = JSON.parse(localStorage.getItem('commands'))
      const withoutClothCommands = allCommands.filter(el => {
        const [namespace, , value] = el.split('::')
        if (isJson(value)) {
          const { clothRef: ref } = JSON.parse(value)
          return !(/Clothes:Parameters:?:?/i.test(namespace) && ref === clothRef)
        }
        return true
      })
    console.log("withoutClothCommands",withoutClothCommands)
    console.log("passe")
    state.commit('communication/commands/UPDATE_LIST_OF_COMMAND', withoutClothCommands, { root: true })
    state.commit('communication/commands/SET_ACTUAL_COMMAND', withoutClothCommands, { root: true })
    })

    state.commit('DELETE_ALL_CLOTHES_FROM_SELECTEDCLOTHES')
    //manque la partie commande

  },

  setCloakroom: async (state) => {
    await state.dispatch('getCloakroom')
    return state.rootGetters['cloakroom']
  },

  changeSelectedClothes: (state, [oldClothe, newClothe]) => { //TODO modifier 
    state.commit('CHANGE_SELECTED_CLOTHES', [JSON.parse(JSON.stringify(oldClothe)), JSON.parse(JSON.stringify(newClothe))] )
    state.dispatch('updateSelectedClothes',JSON.parse(JSON.stringify(newClothe)))
  },

  upSize: async (state, clothe) => { //TODO
    const clotheReference = clothe.garmentReference
    const clothes = await state.dispatch('getSizes',clotheReference)
    const sizeIni = clothe.size[0].value
    var sizeMini = 999
    const idIni = clothe.id
    var id = idIni
    console.log("clothes",clothes)
    if (clothe.size[0].country === 'un') {
      var sizeValue = 0;
      console.log("sizeIni",sizeIni)
      switch (sizeIni.toUpperCase()) {
        case "XXS" : sizeValue = 10; break;
        case "XS" : sizeValue = 11; break;
        case "S" : sizeValue = 12; break;
        case "M" : sizeValue = 13; break;
        case "L" : sizeValue = 14; break;
        case "XL" : sizeValue = 15; break;
        case "XXL" : sizeValue = 16; break;
        default : sizeValue = 17; break;
      }
      console.log("sizeValue",sizeValue)
      clothes.forEach((clotheP) => {
        var sizeP = 0
        switch (clotheP.size[0].value.toUpperCase()) {
          case "XXS" : sizeP = 10; break;
          case "XS" : sizeP = 11; break;
          case "S" : sizeP = 12; break;
          case "M" : sizeP = 13; break;
          case "L" : sizeP = 14; break;
          case "XL" : sizeP = 15; break;
          case "XXL" : sizeP = 16; break;
          default : sizeP = 17; break;
        }
        console.log("sizeValue",sizeValue)
        if (sizeP > sizeValue && sizeP < sizeMini) {sizeMini = sizeP, id = clotheP.id}
      })
    }
    else {
      clothes.forEach((clotheP) => {
        console.log("clotheP",clotheP)
        var sizeP = clotheP.size[0].value
        console.log("sizeP",sizeP)
        if (sizeP > sizeIni && sizeP < sizeMini) {sizeMini = sizeP, id = clotheP.id}

      })
    }
    if (sizeMini != 999) { //if current size is not the max size
      const newClothe = await state.dispatch('getById',id)
      await state.dispatch('addClotheToCloakroom',id)
      await state.dispatch('getCloakroom')
      const profil = state.rootGetters['users/profils/current']
      const currentClothe = state.rootGetters['clothes/current']
      await state.dispatch('deleteClotheInCloakroom', [profil.id , currentClothe])
      await state.dispatch('getCloakroom')
      state.dispatch('communication/commands/updateBarCodeCommand',{currentClothe ,newClothe }, { root: true })
      const cloakroom = state.rootGetters['clothes/cloakroom']
      cloakroom.clothes.forEach((clotheC) => {
        if (clotheC.id === newClothe.id) {
          clotheC.wearingParams = clothe.wearingParams
          state.dispatch('setCurrentCloth',clotheC)
          state.dispatch('changeSelectedClothes', [clothe, clotheC])
        }
      })
    }
  },

  downSize: async (state, clothe) => { //TODO
    const clotheReference = clothe.garmentReference
    const clothes = await state.dispatch('getSizes',clotheReference)
    const sizeIni = clothe.size[0].value
    var sizeMax = -1
    const idIni = clothe.id
    var id = idIni
    if (clothe.size[0].country === 'un') {
      var sizeValue = 0;
      console.log("sizeIni",sizeIni)
      switch (sizeIni.toUpperCase()) {
        case "XXS" : sizeValue = 10; break;
        case "XS" : sizeValue = 11; break;
        case "S" : sizeValue = 12; break;
        case "M" : sizeValue = 13; break;
        case "L" : sizeValue = 14; break;
        case "XL" : sizeValue = 15; break;
        case "XXL" : sizeValue = 16; break;
        default : sizeValue = 17; break;
      }
      console.log("sizeValue",sizeValue)
      clothes.forEach((clotheP) => {
        var sizeP = 0
        switch (clotheP.size[0].value.toUpperCase()) {
          case "XXS" : sizeP = 10; break;
          case "XS" : sizeP = 11; break;
          case "S" : sizeP = 12; break;
          case "M" : sizeP = 13; break;
          case "L" : sizeP = 14; break;
          case "XL" : sizeP = 15; break;
          case "XXL" : sizeP = 16; break;
          default : sizeP = 17; break;
        }
        console.log("sizeValue",sizeValue)
        if (sizeP < sizeValue && sizeP > sizeMax) {sizeMax = sizeP, id = clotheP.id}
      })
    }
    else {
      clothes.forEach((clotheP) => {
        var sizeP = clotheP.size[0].value
        if (sizeP < sizeIni && sizeP > sizeMax) {sizeMax = sizeP, id = clotheP.id}
      })
      await state.dispatch('getCloakroom')
    }
    if (sizeMax != -1) { //if current size is not the min size
      const newClothe = await state.dispatch('getById',id)
      await state.dispatch('addClotheToCloakroom',id)
      const profil = state.rootGetters['users/profils/current']
      const currentClothe = state.rootGetters['clothes/current']
      await state.dispatch('deleteClotheInCloakroom', [profil.id , currentClothe])
      await state.dispatch('getCloakroom')
      state.dispatch('communication/commands/updateBarCodeCommand',{currentClothe ,newClothe }, { root: true })
      const cloakroom = state.rootGetters['clothes/cloakroom']
      cloakroom.clothes.forEach((clotheC) => {
        if (clotheC.id === newClothe.id) {
          clotheC.wearingParams = clothe.wearingParams
          state.dispatch('setCurrentCloth',clotheC)
          state.dispatch('changeSelectedClothes', [clothe, clotheC])
        }
      })
    }
  },

  deleteFromSelectedClothes: (state, clothe) => {
    state.commit('DELETE_FROM_SELECTED_CLOTHES',clothe)
  },

  /** DB CALL */
  deleteBarCode: (state, barCode) => { //TODO
    return new Promise((resolve, reject) => {
      cs.deleteByBarCode(barCode)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => { reject(error) })
    })
  },
  postClothe: (state, cloth) => { //TODO
    return new Promise((resolve, reject) => {
      cs.post(cloth)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => { reject(error) })
    })
  },
  
  deleteCurrentClotheFromCloakroom : (state) => { //TODO
      const profil = state.rootGetters['users/profils/current']
      const clothe = state.rootGetters['clothes/current']
      const seletedClothes = state.rootGetters['clothes/selectedList']
      if (seletedClothes.length > 0) {
        state.dispatch('deleteFromSelectedClothes', clothe)
      }
      state.dispatch('deleteClotheInCloakroom', [profil.id, clothe])
  },

  deleteClotheInCloakroom :(state, idAndClothe) => { //TODO
    console.log("clothe delete :",idAndClothe[1])
    return new Promise((resolve,reject) => {
      cs.deleteClotheInCloakroom(idAndClothe[0],idAndClothe[1])
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {reject(error)})
    })
  },


  getClothes: async (state) => { //TODO
    const clothesGet = await cs.gets() 
    const clothes = clothesGet.data.clothes
    state.commit('SET_CLOTHES', clothes)
    return clothes

  },
  addClotheToCloakroom: (state, idClothe) => { //TODO
    return new Promise((resolve, reject) => {
      const profil = state.rootGetters['users/profils/current']
      cs.postCloakroom(profil.id, idClothe)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {reject(error)})
    })
  },

  getCloakroom: async (state) => {
    const profil = state.rootGetters['users/profils/current']
    try {
    const cloakroomRes = await cs.getCloakroom(profil.id)
    console.log('cloakroomRes', cloakroomRes)
    } catch (error) {
      console.log('=================error cloakroomRes===================');
      console.log(error);
      console.log('====================================');
    }
    const cloakroomRes = await cs.getCloakroom(profil.id)
    const cloakroom = cloakroomRes.data.cloakroom
    state.commit('SET_CLOAKROOM', cloakroom)
    // return cloakroom
  },
  saveParams: async (state, cloth) => {
    const profil = state.rootGetters['users/profils/current']

    await cs.patchParams(profil.id, cloth)
      .catch(error => {
        state.commit('CHECKOUT_ERROR', error, { root: true })
      })
    
    state.commit('CHECKOUT_SUCCESS', null, { root: true })
  },

  getSizes: async (state,clotheReference) => { //TODO
    const clothesGet = await cs.getSizes(clotheReference) 
    const clothes = clothesGet.data.clothes
    return clothes
  },

  getById: async (state, id) => { //TODO
    const clothesGet = await cs.get(id) 
    const clothes = clothesGet.data.cloth
    return clothes
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};