<template lang="pug">
BoxRoundedGrey.sceneSettings__.sceneSettings__artificialLigth.mb-6
  v-card-title.pt-0.d-flex.justify-space-between
    span.smallt {{$t('sideBarLeft.scene.artificialLight')}}
    v-icon(
      v-if="nbArtificialLight >= 0 && nbArtificialLight < 4",
      @click="addArtificialLight"
    ) mdi-plus-thick
    v-icon(v-else, color="lGrey") mdi-plus-thick

  template(v-if="nbArtificialLight > 0")
    v-expansion-panels.elevation-0(accordion, flat)
      v-expansion-panel.elevation-0(
        v-for="(item, i) in nbArtificialLight",
        :key="i + 1",
        color="lGrey"
      )
        v-expansion-panel-header.elevation-0(
          expand-icon="mdi-lightbulb",
          color="lGrey",
          disable-icon-rotate
        ) 
          | {{$t('sideBarLeft.scene.light')}} {{ `${i + 1}` }}
        v-expansion-panel-content.elevation-0(color="lGrey") 
          SelfSwitch.px-4(
            :title="$t('sideBarLeft.scene.temperature')",
            :valuesList="getValuesListArticialLight(i + 1, 'SetColorTemperature')",
            :command="getCommandArticialLight(i + 1, 'SetColorTemperature')",
            :onClick="setSceneCommand"
          )
            SelectSwitch.mt-4.px-4(
            :title="$t('sideBarLeft.scene.inclination')",
            :valuesList="getValuesListArticialLight(i + 1, 'SetInclinason')",
            :command="getCommandArticialLight(i + 1, 'SetInclinason')",
            :onClick="setSceneCommand",
            :widthSelectedBox= "windowWidth > 1850 ? '62' : '45'"
          )
          SelectSwitch.mt-4.px-4(
            :title="$t('sideBarLeft.scene.hourAngle')",
            :valuesList="getValuesListArticialLight(i + 1, 'SetHourAngle')",
            :command="getCommandArticialLight(i + 1, 'SetHourAngle')",
            :onClick="setSceneCommand",
            :widthSelectedBox= "windowWidth > 1850 ? '62' : '45'"
          )
          SelfSwitch.mt-4.px-4(
            :title="$t('sideBarLeft.scene.diffuseLight')",
            :valuesList="getValuesListArticialLight(i + 1, 'IsDiffuse')",
            :command="getCommandArticialLight(i + 1, 'IsDiffuse')",
            :onClick="setSceneCommand"
          )
          SelfSwitch.mt-4.px-4(
            :title="$t('sideBarLeft.scene.mainLight')",
            :valuesList="getValuesListArticialLight(i + 1, 'IsMain')",
            :command="getCommandArticialLight(i + 1, 'IsMain')",
            :onClick="setSceneCommand"
          )

  v-card-text(v-else) {{$t('sideBarLeft.scene.noAddedLight')}}
</template>

<script>
const inflection = require("inflection");

import Vuex from "vuex";

import SelectSwitch from "@/components/_actions/SelectSwitch.component";
import SelfSwitch from "@/components/_actions/Switch.component";
import BoxRoundedGrey from "@/components/_design/BoxRoundedGrey.component";

export default {
  name: "SceneSettings__ArtificialLigths",

  components: { SelectSwitch, SelfSwitch, BoxRoundedGrey },
  data: () => ({
    windowWidth: window.innerWidth,
    activeLightId: 0,
    nbArtificialLight: 0,
  }),
  computed: {
    ...Vuex.mapGetters({
      getInfoCommandScene: "scenes/getInfoCommand",
      getCommand: "communication/commands/getCommand",
    }),

    // ArticialLight
    getValuesListArticialLight() {
      return (lightId, shortCommand) => {
        return this.getInfoCommandScene(
          `artificialLight${lightId}${shortCommand}`
        ).valuesList;
      };
    },
    getCommandArticialLight() {
      return (lightId, shortCommand) => {
        return this.getCommand(
          inflection
            .underscore(`artificialLight${lightId}${shortCommand}`)
            .replace(/(\d)/, "_$1")
        );
      };
    },
  },
  methods: {
    ...Vuex.mapActions({
      setSceneCommand: "scenes/setSceneCommand",
    }),
    addArtificialLight() {
      this.nbArtificialLight += 1;
    },
  },
  async mounted() {},
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0;
  overflow: hidden;
}
</style>