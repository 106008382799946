<template lang="pug">
.all
  home(v-if="stateFirst==='home'" :upperCase="upperCase" @next-event="redirectFromHome()")

  informationProfil(:upperCase="upperCase" v-if="stateFirst==='informationProfil'" @next-event="switchComponent('videoTaking')")

  videoTaking(v-if="stateFirst==='videoTaking'" @backMorpho-event="switchRemind()" :isStreamStart="isStreamStart" :upperCase="upperCase" @resultsOk-event="switchComponent('resultsOk')" @badResults-event="switchComponent('badResults')" @inactivity-event="switchComponent('inactivity')" @update:isStreamStart="setIsStreamStart($event)")
  
  results(v-if="stateFirst==='resultsOk' || stateFirst==='badResults' || stateFirst === 'inactivity'" :state="stateFirst" :upperCase="upperCase" @retry-event="switchComponent('videoTaking')" @remind-event="switchRemind()")

  router-link( :to="{ name: 'Visualisation'}")  
    v-btn.goBack(:color="this.colors.white") 
      v-icon mdi-home
</template>



<script>
import Vuex from "vuex";
import BorderGradient from "@/components/_design/BorderGradient.component";
import home from "@/components/visualisation/firstConnection/home.component";
import informationProfil from "@/components/visualisation/firstConnection/informationProfil.component";
import videoTaking from "@/components/visualisation/firstConnection/videoTaking.component";
import results from "@/components/visualisation/firstConnection/results.component";

export default {
  name: "firstConnection" ,

  components : {
    BorderGradient,
    home,
    informationProfil,
    videoTaking,
    results,
    
  },
  data: () => ({
    colors: {
      bad: '#E84B65',
      good: '#00F6A8',
      magenta: '#ff00ff',
      black: '#000000',
      blackalt: '#010220',
      white: '#FFFFFF',
    },
    remind: false,

    isStreamStart: false,

    stateFirst:"home",
    hasAvatar:false,
  }),
  
  methods: {
    ...Vuex.mapActions ({
      get: "users/profils/avatars/get",
      patch: "users/profils/avatars/patch",
      setAsSelectedState: "states/selectState",
    }),
    switchComponent(component) {
      this.stateFirst = component;
      console.log(component)
    },

    setIsStreamStart(value) {
      this.isStreamStart = value;
    },

    upperCase(title) {
      return title.toUpperCase();
    },

    redirectFromHome() {
      this.remind ? this.switchComponent('videoTaking') : this.switchComponent('informationProfil'); 
    }, 

    switchRemind() {
      this.remind = true;
      this.switchComponent('home');
    }
  },
  computed : {
    ...Vuex.mapGetters({
      currentProfil : "users/profils/current",
      states: "states/list",


    }),
  },

}


</script>



<style lang="scss">

$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

.nextBtn {
  position:absolute;
  bottom:5%;
  color:white;
  border-style: solid;
  border-color:black;
  left:calc(50% - 80px);
  z-index:5;
  width:160px;
}

.goBack {
  position: absolute;
  top: 0.5%;
  right: 0.25%;
}


</style>
