const axios = require("axios");

class ApiService {

  //in URL : ?accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXllciI6eyJpZCI6MX0sImluaXRpYXRvciI6eyJpZCI6MX0sInN1YiI6IjEyMzQ1Njc4OTAiLCJpYXQiOjE1MTYyMzkwMjJ9.LAsNZnU1Pqv6J56IjnqRD7smMh26hnHVU2y-Xhk8moY
  constructor() {
    //this.baseURL = 'http://localhost:8888/v1'
    this.baseURL = 'https://api.modalive.fr/v1'
    //this.token = 
  }

  getBaseURL() { return this.baseURL }
  getToken() { return localStorage.getItem('userToken') }

  async doRequest(method, url, params = {}) {
    return await axios(this.requestOptions(method, url, params))
  }

  addAuth(token) { axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; }
  deleteAuth() { delete axios.defaults.headers.common['Authorization'] }

  requestOptions(method, url, params = null) {
    const { qs, data, headers } = params;
    const token = this.getToken();
    const options = {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    };
    options.baseURL = this.getBaseURL();
    options.responseType = 'json';
    options.url = qs ? encodeURI(`${url}?${qs}`) : encodeURI(url);
  
    if (token) this.addAuth(token);
    if (headers) options.headers = headers;
  
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  
    switch (true) {
      case /post/i.test(method): options.method = 'POST'; break;
      case /get/i.test(method): options.method = 'GET'; break;
      case /update|put|patch/i.test(method): options.method = 'PATCH'; break;
      case /delete/i.test(method): options.method = 'DELETE'; break;
      default: break;
    }
  
    if (data) options.data = data;
    return options;
  }
  

  async getPayloadToken(token) {
    const payload = await this.doRequest('get', `/decodeToken?token=${token}`)
    return payload.data;
  }
}

module.exports = ApiService;