import artificialLights from '@/plugins/store/modules/scenes/artificialLights';

// const filterArray = (oldArray, typeList) => {
//   // oldArray: array
//   // typeList: string OR number
//   return oldArray
//     .map(el => el.name)
//     .filter(el => {
//       if (/\D+/i.test(el) && /string/.test(typeList)) return true
//       if (/\d+/.test(el) && /number/.test(typeList)) return true
//       return false
//     })
// }


const state = {
  autoScene: {
    namespace: 'Scene:Scene_exposition',
    key: 'auto_scene',
    defaultValue: true,
    value: null,
    valuesList: [
      { name: 'Auto', command: { key: "auto_scene", value: true } },
      { name: 'Off', command: { key: "auto_scene", value: false } },
    ]
  },
  selectNaturalLight: {
    namespace: 'Scene:Scene_exposition',
    key: 'select_natural_light',
    defaultValue: 'no_natural_light',
    value: null,
    valuesList: [
      { name: 'Direct', command: { key: "select_natural_light", value: 'direct_sunlight' } },
      { name: 'Off', command: { key: "select_natural_light", value: 'no_natural_light' } },
      { name: 'Diffuse', command: { key: "select_natural_light", value: 'diffuse_daylight' } },
    ]
  },
  selectArtificialLight: {
    namespace: 'Scene:Scene_exposition',
    key: 'select_artificial_light',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "select_artificial_light", value: true } },
      { name: 'Non', command: { key: "select_artificial_light", value: false } },
    ]
  },

  diffuseDaylightWithSky: {
    namespace: 'Scene:Scene_exposition',
    key: 'diffuse_daylight_with_sky',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "diffuse_daylight_with_sky", value: true } },
      { name: 'Non', command: { key: "diffuse_daylight_with_sky", value: false } },
    ]
  },
  diffuseDaylightWithHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'diffuse_daylight_with_hour_angle',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "diffuse_daylight_with_hour_angle", value: true } },
      { name: 'Non', command: { key: "diffuse_daylight_with_hour_angle", value: false } },
    ]
  },
  diffuseDaylightSetHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'diffuse_daylight_set_hour_angle',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "diffuse_daylight_set_hour_angle", value: null } },
      { name: '1', command: { key: "diffuse_daylight_set_hour_angle", value: 1 } },
      { name: '2', command: { key: "diffuse_daylight_set_hour_angle", value: 2 } },
      { name: '3', command: { key: "diffuse_daylight_set_hour_angle", value: 3 } },
      { name: '4', command: { key: "diffuse_daylight_set_hour_angle", value: 4 } },
      { name: '5', command: { key: "diffuse_daylight_set_hour_angle", value: 5 } },
      { name: '6', command: { key: "diffuse_daylight_set_hour_angle", value: 6 } },
      { name: '7', command: { key: "diffuse_daylight_set_hour_angle", value: 7 } },
      { name: '8', command: { key: "diffuse_daylight_set_hour_angle", value: 8 } },
      { name: '9', command: { key: "diffuse_daylight_set_hour_angle", value: 9 } },
      { name: '10', command: { key: "diffuse_daylight_set_hour_angle", value: 10 } },
      { name: '11', command: { key: "diffuse_daylight_set_hour_angle", value: 11 } },
      { name: '12', command: { key: "diffuse_daylight_set_hour_angle", value: 12 } },
      { name: '13', command: { key: "diffuse_daylight_set_hour_angle", value: 13 } },
      { name: '14', command: { key: "diffuse_daylight_set_hour_angle", value: 14 } },
      { name: '15', command: { key: "diffuse_daylight_set_hour_angle", value: 15 } },
      { name: '16', command: { key: "diffuse_daylight_set_hour_angle", value: 16 } },
      { name: '17', command: { key: "diffuse_daylight_set_hour_angle", value: 17 } },
      { name: '18', command: { key: "diffuse_daylight_set_hour_angle", value: 18 } },
      { name: '19', command: { key: "diffuse_daylight_set_hour_angle", value: 19 } },
      { name: '20', command: { key: "diffuse_daylight_set_hour_angle", value: 20 } },
      { name: '21', command: { key: "diffuse_daylight_set_hour_angle", value: 21 } },
      { name: '22', command: { key: "diffuse_daylight_set_hour_angle", value: 22 } },
      { name: '23', command: { key: "diffuse_daylight_set_hour_angle", value: 23 } },
      { name: '24', command: { key: "diffuse_daylight_set_hour_angle", value: 24 } },
    ]
  },
  directSunlightSetInclinason: {
    namespace: 'Scene:Scene_exposition',
    key: 'direct_sunlight_set_inclinason',
    defaultValue: null,
    value: null,
    valuesList: [
      //{ name: '', command: { key: "direct_sunlight_set_inclinason", value: null } },
      { name: '0', command: { key: "direct_sunlight_set_inclinason", value: 0 } },
      { name: '25', command: { key: "direct_sunlight_set_inclinason", value: 25 } },
      { name: '45', command: { key: "direct_sunlight_set_inclinason", value: 45 } },
      { name: '65', command: { key: "direct_sunlight_set_inclinason", value: 65 } },
      { name: '90', command: { key: "direct_sunlight_set_inclinason", value: 90 } },
    ]
  },
  directSunlightSetHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'direct_sunlight_set_hour_angle',
    defaultValue: null,
    value: null,
    valuesList: [
      //{ name: '', command: { key: "direct_sunlight_set_hour_angle", value: null } },
      { name: '1', command: { key: "direct_sunlight_set_hour_angle", value: 1 } },
      { name: '2', command: { key: "direct_sunlight_set_hour_angle", value: 2 } },
      { name: '3', command: { key: "direct_sunlight_set_hour_angle", value: 3 } },
      { name: '4', command: { key: "direct_sunlight_set_hour_angle", value: 4 } },
      { name: '5', command: { key: "direct_sunlight_set_hour_angle", value: 5 } },
      { name: '6', command: { key: "direct_sunlight_set_hour_angle", value: 6 } },
      { name: '7', command: { key: "direct_sunlight_set_hour_angle", value: 7 } },
      { name: '8', command: { key: "direct_sunlight_set_hour_angle", value: 8 } },
      { name: '9', command: { key: "direct_sunlight_set_hour_angle", value: 9 } },
      { name: '10', command: { key: "direct_sunlight_set_hour_angle", value: 10 } },
      { name: '11', command: { key: "direct_sunlight_set_hour_angle", value: 11 } },
      { name: '12', command: { key: "direct_sunlight_set_hour_angle", value: 12 } },
      { name: '13', command: { key: "direct_sunlight_set_hour_angle", value: 13 } },
      { name: '14', command: { key: "direct_sunlight_set_hour_angle", value: 14 } },
      { name: '15', command: { key: "direct_sunlight_set_hour_angle", value: 15 } },
      { name: '16', command: { key: "direct_sunlight_set_hour_angle", value: 16 } },
      { name: '17', command: { key: "direct_sunlight_set_hour_angle", value: 17 } },
      { name: '18', command: { key: "direct_sunlight_set_hour_angle", value: 18 } },
      { name: '19', command: { key: "direct_sunlight_set_hour_angle", value: 19 } },
      { name: '20', command: { key: "direct_sunlight_set_hour_angle", value: 20 } },
      { name: '21', command: { key: "direct_sunlight_set_hour_angle", value: 21 } },
      { name: '22', command: { key: "direct_sunlight_set_hour_angle", value: 22 } },
      { name: '23', command: { key: "direct_sunlight_set_hour_angle", value: 23 } },
      { name: '24', command: { key: "direct_sunlight_set_hour_angle", value: 24 } },
    ]
  },

  // ARTIFICIAL LIGHTS
  artificialLight1SetColorTemperature: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_1_set_color_temperature',
    defaultValue: null,
    value: null,
    valuesList: [
      //{ name: '', command: { key: "artificial_light_1_set_color_temperature", value: null } },
      { name: 'Orangé', command: { key: "artificial_light_1_set_color_temperature", value: 2700 } },
      { name: 'Jaune', command: { key: "artificial_light_1_set_color_temperature", value: 3000 } },
      { name: 'Neutre', command: { key: "artificial_light_1_set_color_temperature", value: 4000 } },
    ]
  },
  artificialLight1IsDiffuse: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_1_is_diffuse',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_1_is_diffuse", value: true } },
      { name: 'Non', command: { key: "artificial_light_1_is_diffuse", value: false } },
    ]
  },
  artificialLight1IsMain: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_1_is_main',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_1_is_main", value: true } },
      { name: 'Non', command: { key: "artificial_light_1_is_main", value: false } },
    ]
  },
  artificialLight1SetInclinason: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_1_set_inclinason',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_1_set_inclinason", value: null } },
      { name: '0', command: { key: "artificial_light_1_set_inclinason", value: 0 } },
      { name: '25', command: { key: "artificial_light_1_set_inclinason", value: 25 } },
      { name: '45', command: { key: "artificial_light_1_set_inclinason", value: 45 } },
      { name: '65', command: { key: "artificial_light_1_set_inclinason", value: 65 } },
      { name: '90', command: { key: "artificial_light_1_set_inclinason", value: 90 } },
    ]
    // [
    //   { name: 'zénithal', value: 85 },
    //   { name: 'haut|mural', value: 30 },
    //   { name: 'table|commode', value: -30 },
    //   { name: 'bas', value: -85 },
    // ]
  },
  artificialLight1SetHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_1_set_hour_angle',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_1_set_hour_angle", value: null } },
      { name: '1', command: { key: "artificial_light_1_set_hour_angle", value: 1 } },
      { name: '2', command: { key: "artificial_light_1_set_hour_angle", value: 2 } },
      { name: '3', command: { key: "artificial_light_1_set_hour_angle", value: 3 } },
      { name: '4', command: { key: "artificial_light_1_set_hour_angle", value: 4 } },
      { name: '5', command: { key: "artificial_light_1_set_hour_angle", value: 5 } },
      { name: '6', command: { key: "artificial_light_1_set_hour_angle", value: 6 } },
      { name: '7', command: { key: "artificial_light_1_set_hour_angle", value: 7 } },
      { name: '8', command: { key: "artificial_light_1_set_hour_angle", value: 8 } },
      { name: '9', command: { key: "artificial_light_1_set_hour_angle", value: 9 } },
      { name: '10', command: { key: "artificial_light_1_set_hour_angle", value: 10 } },
      { name: '11', command: { key: "artificial_light_1_set_hour_angle", value: 11 } },
      { name: '12', command: { key: "artificial_light_1_set_hour_angle", value: 12 } },
      { name: '13', command: { key: "artificial_light_1_set_hour_angle", value: 13 } },
      { name: '14', command: { key: "artificial_light_1_set_hour_angle", value: 14 } },
      { name: '15', command: { key: "artificial_light_1_set_hour_angle", value: 15 } },
      { name: '16', command: { key: "artificial_light_1_set_hour_angle", value: 16 } },
      { name: '17', command: { key: "artificial_light_1_set_hour_angle", value: 17 } },
      { name: '18', command: { key: "artificial_light_1_set_hour_angle", value: 18 } },
      { name: '19', command: { key: "artificial_light_1_set_hour_angle", value: 19 } },
      { name: '20', command: { key: "artificial_light_1_set_hour_angle", value: 20 } },
      { name: '21', command: { key: "artificial_light_1_set_hour_angle", value: 21 } },
      { name: '22', command: { key: "artificial_light_1_set_hour_angle", value: 22 } },
      { name: '23', command: { key: "artificial_light_1_set_hour_angle", value: 23 } },
      { name: '24', command: { key: "artificial_light_1_set_hour_angle", value: 24 } },

    ]
  },
  artificialLight2SetColorTemperature: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_2_set_color_temperature',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_2_set_color_temperature", value: null } },
      { name: 'Orangé', command: { key: "artificial_light_2_set_color_temperature", value: 2700 } },
      { name: 'Jaune', command: { key: "artificial_light_2_set_color_temperature", value: 3000 } },
      { name: 'Neutre', command: { key: "artificial_light_2_set_color_temperature", value: 4000 } },
    ]
  },
  artificialLight2IsDiffuse: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_2_is_diffuse',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_2_is_diffuse", value: true } },
      { name: 'Non', command: { key: "artificial_light_2_is_diffuse", value: false } },
    ]
  },
  artificialLight2IsMain: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_2_is_main',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_2_is_main", value: true } },
      { name: 'Non', command: { key: "artificial_light_2_is_main", value: false } },
    ]
  },
  artificialLight2SetInclinason: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_2_set_inclinason',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_2_set_inclinason", value: null } },
      { name: '0', command: { key: "artificial_light_2_set_inclinason", value: 0 } },
      { name: '25', command: { key: "artificial_light_2_set_inclinason", value: 25 } },
      { name: '45', command: { key: "artificial_light_2_set_inclinason", value: 45 } },
      { name: '65', command: { key: "artificial_light_2_set_inclinason", value: 65 } },
      { name: '90', command: { key: "artificial_light_2_set_inclinason", value: 90 } },
    ]
    // [
    //   { name: 'zénithal', command: { key: "artificial_light_2_set_inclinason", value: 85 }},
    //   { name: 'haut|mural', command: { key: "artificial_light_2_set_inclinason", value: 30 }},
    //   { name: 'table|commode', command: { key: "artificial_light_2_set_inclinason", value: -30 }},
    //   { name: 'bas', command: { key: "artificial_light_2_set_inclinason", value: -85 }},
    // ]
  },
  artificialLight2SetHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_2_set_hour_angle',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_2_set_hour_angle", value: null } },
      { name: '1', command: { key: "artificial_light_2_set_hour_angle", value: 1 } },
      { name: '2', command: { key: "artificial_light_2_set_hour_angle", value: 2 } },
      { name: '3', command: { key: "artificial_light_2_set_hour_angle", value: 3 } },
      { name: '4', command: { key: "artificial_light_2_set_hour_angle", value: 4 } },
      { name: '5', command: { key: "artificial_light_2_set_hour_angle", value: 5 } },
      { name: '6', command: { key: "artificial_light_2_set_hour_angle", value: 6 } },
      { name: '7', command: { key: "artificial_light_2_set_hour_angle", value: 7 } },
      { name: '8', command: { key: "artificial_light_2_set_hour_angle", value: 8 } },
      { name: '9', command: { key: "artificial_light_2_set_hour_angle", value: 9 } },
      { name: '10', command: { key: "artificial_light_2_set_hour_angle", value: 10 } },
      { name: '11', command: { key: "artificial_light_2_set_hour_angle", value: 11 } },
      { name: '12', command: { key: "artificial_light_2_set_hour_angle", value: 12 } },
      { name: '13', command: { key: "artificial_light_2_set_hour_angle", value: 13 } },
      { name: '14', command: { key: "artificial_light_2_set_hour_angle", value: 14 } },
      { name: '15', command: { key: "artificial_light_2_set_hour_angle", value: 15 } },
      { name: '16', command: { key: "artificial_light_2_set_hour_angle", value: 16 } },
      { name: '17', command: { key: "artificial_light_2_set_hour_angle", value: 17 } },
      { name: '18', command: { key: "artificial_light_2_set_hour_angle", value: 18 } },
      { name: '19', command: { key: "artificial_light_2_set_hour_angle", value: 19 } },
      { name: '20', command: { key: "artificial_light_2_set_hour_angle", value: 20 } },
      { name: '21', command: { key: "artificial_light_2_set_hour_angle", value: 21 } },
      { name: '22', command: { key: "artificial_light_2_set_hour_angle", value: 22 } },
      { name: '23', command: { key: "artificial_light_2_set_hour_angle", value: 23 } },
      { name: '24', command: { key: "artificial_light_2_set_hour_angle", value: 24 } },
    ]
  },
  artificialLight3SetColorTemperature: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_3_set_color_temperature',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_3_set_color_temperature", value: null } },
      { name: 'Orangé', command: { key: "artificial_light_3_set_color_temperature", value: 2700 } },
      { name: 'Jaune', command: { key: "artificial_light_3_set_color_temperature", value: 3000 } },
      { name: 'Neutre', command: { key: "artificial_light_3_set_color_temperature", value: 4000 } },
    ]
  },
  artificialLight3IsDiffuse: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_3_is_diffuse',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_3_is_diffuse", value: true } },
      { name: 'Non', command: { key: "artificial_light_3_is_diffuse", value: false } },
    ]
  },
  artificialLight3IsMain: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_3_is_main',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_3_is_main", value: true } },
      { name: 'Non', command: { key: "artificial_light_3_is_main", value: false } },
    ]
  },
  artificialLight3SetInclinason: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_3_set_inclinason',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_3_set_inclinason", value: null } },
      { name: '0', command: { key: "artificial_light_3_set_inclinason", value: 0 } },
      { name: '25', command: { key: "artificial_light_3_set_inclinason", value: 25 } },
      { name: '45', command: { key: "artificial_light_3_set_inclinason", value: 45 } },
      { name: '65', command: { key: "artificial_light_3_set_inclinason", value: 65 } },
      { name: '90', command: { key: "artificial_light_3_set_inclinason", value: 90 } },
    ]
    // [
    //   { name: 'zénithal', command: { key: "artificial_light_3_set_inclinason", value: 85 }},
    //   { name: 'haut|mural', command: { key: "artificial_light_3_set_inclinason", value: 30 }},
    //   { name: 'table|commode', command: { key: "artificial_light_3_set_inclinason", value: -30 }},
    //   { name: 'bas', command: { key: "artificial_light_3_set_inclinason", value: -85 }},
    // ]
  },
  artificialLight3SetHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_3_set_hour_angle',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_3_set_hour_angle", value: null } },
      { name: '1', command: { key: "artificial_light_3_set_hour_angle", value: 1 } },
      { name: '2', command: { key: "artificial_light_3_set_hour_angle", value: 2 } },
      { name: '3', command: { key: "artificial_light_3_set_hour_angle", value: 3 } },
      { name: '4', command: { key: "artificial_light_3_set_hour_angle", value: 4 } },
      { name: '5', command: { key: "artificial_light_3_set_hour_angle", value: 5 } },
      { name: '6', command: { key: "artificial_light_3_set_hour_angle", value: 6 } },
      { name: '7', command: { key: "artificial_light_3_set_hour_angle", value: 7 } },
      { name: '8', command: { key: "artificial_light_3_set_hour_angle", value: 8 } },
      { name: '9', command: { key: "artificial_light_3_set_hour_angle", value: 9 } },
      { name: '10', command: { key: "artificial_light_3_set_hour_angle", value: 10 } },
      { name: '11', command: { key: "artificial_light_3_set_hour_angle", value: 11 } },
      { name: '12', command: { key: "artificial_light_3_set_hour_angle", value: 12 } },
      { name: '13', command: { key: "artificial_light_3_set_hour_angle", value: 13 } },
      { name: '14', command: { key: "artificial_light_3_set_hour_angle", value: 14 } },
      { name: '15', command: { key: "artificial_light_3_set_hour_angle", value: 15 } },
      { name: '16', command: { key: "artificial_light_3_set_hour_angle", value: 16 } },
      { name: '17', command: { key: "artificial_light_3_set_hour_angle", value: 17 } },
      { name: '18', command: { key: "artificial_light_3_set_hour_angle", value: 18 } },
      { name: '19', command: { key: "artificial_light_3_set_hour_angle", value: 19 } },
      { name: '20', command: { key: "artificial_light_3_set_hour_angle", value: 20 } },
      { name: '21', command: { key: "artificial_light_3_set_hour_angle", value: 21 } },
      { name: '22', command: { key: "artificial_light_3_set_hour_angle", value: 22 } },
      { name: '23', command: { key: "artificial_light_3_set_hour_angle", value: 23 } },
      { name: '24', command: { key: "artificial_light_3_set_hour_angle", value: 24 } },
    ]
  },
  artificialLight4SetColorTemperature: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_4_set_color_temperature',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_4_set_color_temperature", value: null } },
      { name: 'Orangé', command: { key: "artificial_light_4_set_color_temperature", value: 2700 } },
      { name: 'Jaune', command: { key: "artificial_light_4_set_color_temperature", value: 3000 } },
      { name: 'Neutre', command: { key: "artificial_light_4_set_color_temperature", value: 4000 } },
    ]
  },
  artificialLight4IsDiffuse: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_4_is_diffuse',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_4_is_diffuse", value: true } },
      { name: 'Non', command: { key: "artificial_light_4_is_diffuse", value: false } },
    ]
  },
  artificialLight4IsMain: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_4_is_main',
    defaultValue: false,
    value: null,
    valuesList: [
      { name: 'Oui', command: { key: "artificial_light_4_is_main", value: true } },
      { name: 'Non', command: { key: "artificial_light_4_is_main", value: false } },
    ]
  },
  artificialLight4SetInclinason: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_4_set_inclinason',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_4_set_inclinason", value: null } },
      { name: '0', command: { key: "artificial_light_4_set_inclinason", value: 0 } },
      { name: '25', command: { key: "artificial_light_4_set_inclinason", value: 25 } },
      { name: '45', command: { key: "artificial_light_4_set_inclinason", value: 45 } },
      { name: '65', command: { key: "artificial_light_4_set_inclinason", value: 65 } },
      { name: '90', command: { key: "artificial_light_4_set_inclinason", value: 90 } },
    ]
    // [
    //   { name: 'zénithal', command: { key: "artificial_light_4_set_inclinason", value: 85 }},
    //   { name: 'haut|mural', command: { key: "artificial_light_4_set_inclinason", value: 30 }},
    //   { name: 'table|commode', command: { key: "artificial_light_4_set_inclinason", value: -30 }},
    //   { name: 'bas', command: { key: "artificial_light_4_set_inclinason", value: -85 }},
    // ]
  },
  artificialLight4SetHourAngle: {
    namespace: 'Scene:Scene_exposition',
    key: 'artificial_light_4_set_hour_angle',
    defaultValue: null,
    value: null,
    valuesList: [
      // { name: '', command: { key: "artificial_light_4_set_hour_angle", value: null } },
      { name: '1', command: { key: "artificial_light_4_set_hour_angle", value: 1 } },
      { name: '2', command: { key: "artificial_light_4_set_hour_angle", value: 2 } },
      { name: '3', command: { key: "artificial_light_4_set_hour_angle", value: 3 } },
      { name: '4', command: { key: "artificial_light_4_set_hour_angle", value: 4 } },
      { name: '5', command: { key: "artificial_light_4_set_hour_angle", value: 5 } },
      { name: '6', command: { key: "artificial_light_4_set_hour_angle", value: 6 } },
      { name: '7', command: { key: "artificial_light_4_set_hour_angle", value: 7 } },
      { name: '8', command: { key: "artificial_light_4_set_hour_angle", value: 8 } },
      { name: '9', command: { key: "artificial_light_4_set_hour_angle", value: 9 } },
      { name: '10', command: { key: "artificial_light_4_set_hour_angle", value: 10 } },
      { name: '11', command: { key: "artificial_light_4_set_hour_angle", value: 11 } },
      { name: '12', command: { key: "artificial_light_4_set_hour_angle", value: 12 } },
      { name: '13', command: { key: "artificial_light_4_set_hour_angle", value: 13 } },
      { name: '14', command: { key: "artificial_light_4_set_hour_angle", value: 14 } },
      { name: '15', command: { key: "artificial_light_4_set_hour_angle", value: 15 } },
      { name: '16', command: { key: "artificial_light_4_set_hour_angle", value: 16 } },
      { name: '17', command: { key: "artificial_light_4_set_hour_angle", value: 17 } },
      { name: '18', command: { key: "artificial_light_4_set_hour_angle", value: 18 } },
      { name: '19', command: { key: "artificial_light_4_set_hour_angle", value: 19 } },
      { name: '20', command: { key: "artificial_light_4_set_hour_angle", value: 20 } },
      { name: '21', command: { key: "artificial_light_4_set_hour_angle", value: 21 } },
      { name: '22', command: { key: "artificial_light_4_set_hour_angle", value: 22 } },
      { name: '23', command: { key: "artificial_light_4_set_hour_angle", value: 23 } },
      { name: '24', command: { key: "artificial_light_4_set_hour_angle", value: 24 } },
    ]
  },
};

const getters = {
  list: state => key => state[key].valuesList,
  getInfoCommand: state => key => state[key]
};

const mutations = {

};


const actions = {
  setSceneCommand: (state, value) => {
    console.log(value)
    state.dispatch('communication/commands/updateListOfCommand', [value.command], { root: true })
    state.dispatch('communication/commands/sendCommand', null, { root: true })

  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    artificialLights
  }
};