<template lang="pug">
  div.scroll
    v-btn.act(@click="giveClothes") Refresh <!-- a refresh button (for change in dataBase) -->
    input.filt(type="text" id="filter" placeholder="filtrer ici" @keyup="filter") 
    div <!-- a filter section to filter clothes by name -->
      table.clothe(name="clothesDB" id="clothesDB") <!-- a dynamique table -->
        tbody(id="tbody")
        tr
          th id
          th name
          th barCode

</template>

<script>
  import Vuex from 'vuex';

  export default {
    
    name: "ClothesList",
    data: () => ({
    }),


    methods: {
      ...Vuex.mapActions({
          clothesGet: `clothes/getClothes`
      }),
      giveClothes() { //function to set the dynamique table with clothes 
        this.clothesGet();
        var access = document.getElementById("clothesDB");
        var tableBody = document.getElementById("tbody");
        while (tableBody.hasChildNodes()) { //removes lines in table
          tableBody.lastChild.removeChild(tableBody.lastChild.lastChild);
          tableBody.lastChild.removeChild(tableBody.lastChild.lastChild);
          tableBody.lastChild.removeChild(tableBody.lastChild.lastChild);
          tableBody.removeChild(tableBody.lastChild);
        }
        this.clothes.forEach((clothe) => {
          var tr = document.createElement("tr");
          var cellId = document.createElement("td");
          var cellName = document.createElement("td");
          var cellBarCode = document.createElement("td");
          var cellIdText = document.createTextNode( clothe.id );
          var cellNameText = document.createTextNode( clothe.name );
          var cellBarCodeText = document.createTextNode( clothe.barCode );
          cellId.appendChild(cellIdText);
          cellName.appendChild(cellNameText);
          cellBarCode.appendChild(cellBarCodeText);
          tr.appendChild(cellId);
          tr.appendChild(cellName);
          tr.appendChild(cellBarCode);
          tableBody.appendChild(tr);
        });
        access.appendChild(tableBody)
      },

      filter() { //function to filter clothes by name
        var table = document.getElementById("clothesDB");
        var filter = document.getElementById("filter").value.toUpperCase(); //gets and capitalizes the entry
        var line = table.getElementsByTagName("tr");
        for(var i = 0; i < line.length; i++) { //for each line, gets the name and tests with entry
          var cell = line[i].getElementsByTagName("td")[1]; //gets the name
          if (cell) {
            var text = cell.innerText.toUpperCase();
            if (text.indexOf(filter) > -1) { //sets display if text matchs with entry 
              line[i].style.display = ""
            }
            else {
              line[i].style.display = "none"
            }
          }
        }

      },
    },
    
    computed: {
      ...Vuex.mapGetters({
        clothes: `clothes/totalList`
      })
    }
  }


</script>

<style lang="scss">

.scroll {
  overflow: scroll;
  height: 90%;
}

.act {
  width:50%;
  float:right;
}
.filt {
  width:50%;
  top:5px;
  left:5px;
  position: relative;
}

table {
  color: black;
  width:100%;
  border-collapse: collapse;
}
td, th {
  border: 1px solid;
}

.act {
  width: 30%;
  font-size : 1em;
}

</style>
