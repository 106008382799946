<template lang="pug">
#side-bar-right.side-bar

  ClothParamsCard
  FramingCard
  
</template>

<script>
import FramingCard from "@/components/visualisation/framing/framing.component";
import ClothParamsCard from "@/components/visualisation/clothParams/clothParams.component";

import BorderGradient from "@/components/_design/BorderGradient.component";

export default {
  name: "SideBarRight",
  components: {
    FramingCard,
    ClothParamsCard,
    BorderGradient,
  },
  data: () => ({}),
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
#side-bar-right {
  right: 0;
}

</style>
