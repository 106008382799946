<template lang="pug">
.profil__wraper
  v-card-title.d-flex
    span.mr-auto {{ $t('sideBarLeft.title[1].name') }}
  .profils-content.d-flex.flex-wrap.justify-space-between.px-4
    template(v-for="(profil, n) in profilList")
      v-card.profilActive.profil.elevation-5(
        outlined,
        v-if="profil == profilCurrent",
        :key="n"
      )
        v-img(v-if="profil.thumbnail", :src="profil.thumbnail")
        v-icon.d-flex.align.justify-center(v-else, x-large) mdi-account
        v-card-title.py-1 {{ profil.name }}
      v-card.profil(
        outlined,
        v-else,
        :key="n",
        @mousedown="setCurrentProfil(profil)"
      )
        v-img(v-if="profil.thumbnail", :src="profil.thumbnail")
        v-icon.d-flex.align.justify-center(v-else, x-large) mdi-account
        v-card-title.py-1 {{ profil.name }} 
  router-link( :to="{ name: 'firstConnection'}") <!-- ne s'active que si un profil est sélectionné, permet de prendre ses mesures -->
    Button.takeMeasures(v-if="profilCurrent.name !=null")
      span prendre mes mesures 
</template>

<script>
import Vuex from "vuex";
import Button from "@/components/_actions/Button.component";

export default {
  name: "Profil",

  components: {Button},
  data: () => ({}),
  methods: {
    ...Vuex.mapActions({
      setCurrentProfil: "users/profils/setCurrentProfil",
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      profilList: "users/profils/list",
      profilCurrent: "users/profils/current",
    }),
  },
  async mounted() {},
};
</script>

<style lang="scss">
.profil__wraper {
  height: calc(100% - 58px);
  .profil {
    max-width: 50%;
    width: 48%;
    margin: 0 0 4% 0;
    .v-image,
    .v-icon {
      height: 100px;
    }
  }
  .profilActive {
    border: 2px solid var(--v-dGrey-base);
    * {
      font-weight: bolder;
    }
  }
  .takeMeasures {
    width:95%;
    position:absolute;
    bottom:68px;
    left:2.5%
  }

} 
</style>