<template lang="pug">
#side-bar-left.side-bar
  BorderGradient.secondView.ma-2(noPadding)
    v-card.d-flex.flex-column.elevation-0(height="100%")
      router-view
      .bgGrdientAndSecondNav.mt-auto
        .bgGradiend
        v-bottom-navigation.secondNav.d-flex.align-center.mt-auto.elevation-0
          router-link.v-btn(:to="{ name: 'Profils' }")
            v-btn
              span {{ $t('sideBarLeft.bottomNavigation.buttons[1].name') }}
              v-icon mdi-account
          v-divider(vertical, color="dGrey")
          router-link.v-btn(:to="{ name: 'Cloakroom' }")
            v-btn
              span {{ $t('sideBarLeft.bottomNavigation.buttons[0].name') }}
              v-icon mdi-hanger
          v-divider(vertical, color="dGrey")
          router-link.v-btn(:to="{ name: 'Replays' }")
            v-btn
              span Replay
              v-icon mdi-replay
          v-divider(vertical, color="dGrey")
          router-link.v-btn(:to="{ name: 'SceneSettings' }")
            v-btn
              span Scene
              v-icon mdi-tune
</template>

<script>
import BorderGradient from "@/components/_design/BorderGradient.component";

export default {
  name: "SideBarLeft",
  components: { BorderGradient },
  data: () => ({}),
};
</script>

<style lang="scss">
#side-bar-left {
  left: 0;
  .secondView {
    height: calc(100% - 16px);
  }
  .bgGrdientAndSecondNav {
    position: relative;
    min-height: 56px;
    max-height: 56px;
    height: 56px;
    .bgGradiend,
    .secondNav {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    .bgGradiend {
      top: -2px;
      background: linear-gradient(
        135deg,
        #00ffff 0%,
        #ff00ff 33%,
        #ff48b7 67%,
        #ffff00 100%
      );
    }
    .secondNav {
      top: 0;
      height: 100%;
      position: relative;
      background-color: transparent;
      .v-divider {
        z-index: 3;
      }
      .router-link-exact-active {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -2px;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 101;
          border-top: 5px solid var(--v-dGrey-base);
        }
        * {
          font-weight: bolder;
        }
      }
      .v-btn {
        min-width: 1px; //redéfinition du minimum pour éviter le débordement.
        width: 100%;
        height: 100%;
        background-color: #f0f0f0e0;
        .router-link {
          * {
            color: initial;
          }
        }
      }
    }
  }
}
</style>
