 <!-- <template lang="pug">
  v-card.side-bar__article.elevation-0.info.mb-5(
    outlined
    @click="setAsSelectedCloth(cloth)"
  )
    v-img(
      id="my-image"
      v-if="cloth.thumbnailUrl || cloth.clothType"
      :src="cloth.thumbnailUrl ? cloth.thumbnailUrl : (/^top$/i.test(cloth.clothType) ? 'https://youfoodishpeople.com/wp-content/uploads/2020/10/tshirt-2.jpg' : 'https://contents.mediadecathlon.com/p1786958/k$2b0a8a97ea3b1154f2f3734009451fe2')") 
        
    div( v-else )
      v-icon mdi-image-broken
    v-card-title {{ cloth.name }}
    //- v-card-text {{ cloth.wearingParams[0].name }} / left: {{ cloth.wearingParams[0].params.left }}
</template> -->
<template lang="pug">
  div
    v-card.side-bar__article.elevation-0.info.mb-5(
      outlined
      @click="setAsSelectedCloth(cloth)"
    )
      div( v-if="variantMatchingSKU.sku == cloth.barCode && variantMatchingSKU.image" )
        v-img(
          id="my-image"
          :src="variantMatchingSKU.image"
        )
        //- div( v-else )
        //-   v-icon mdi-image-broken
        v-card-title {{ variantMatchingSKU ? cloth.name : 'No matching variant' }}
</template>


<script>
  import Vuex from 'vuex';

  export default {
    name: 'Article',
    props: {
      cloth: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      img: null,

    }),
    methods: {
      ...Vuex.mapActions({
        setAsSelectedCloth: 'clothes/selectCloth',
      }),
    },
    computed: {
  ...Vuex.mapState(['productList', 'fromShop', 'shopCustomer']),
  itemsToDisplay() {
      return this.fromShop ? this.productList : [];
  },
  variantMatchingSKU() {
      for(let product in this.productList){
        // console.log('====================================');
        // console.log('ok');
        // console.log('====================================');
          for(let variant of this.productList[product].variants){
              if(variant.sku === this.cloth.barCode){
                  return variant;
              }
          }
      }
      return null;
  },
},


  mounted() {
    console.log('=================this.productList===================');
    console.log('this.productList : ', this.productList);
    console.log('====================================');
    this.img = document.getElementById('my-image');
    this.img.style.position = 'relative';

    this.img.addEventListener('mousemove', (event) => {
      const rect = this.img.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const width = rect.right - rect.left;
      const height = rect.bottom - rect.top;

      this.img.style.transformOrigin = `${(x / width) * 100}% ${(y / height) * 100}%`;
      this.img.style.transform = 'scale(3)';
    });

    this.img.addEventListener('mouseout', () => {
      this.img.style.transform = 'scale(1)';
    });
  }

  };
</script>

<style lang="scss" scoped>
  .side-bar__article{
    width: 56%;
    // height: 30%;
  }


</style>

