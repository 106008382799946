

import ApiService from './api.service';


class UsersService extends ApiService {
  constructor() {
    super()
  }

  login(user, initiator) {
    return super.doRequest('post', `/login?initiator=${initiator.id || initiator.userName}`, { data: user });
  }

  isValidToken(token) {
    return super.doRequest('post', `/valid/token`, { data: { token } });
  }

  tokenLogin(token) {
    return super.doRequest('patch', `/login/token`, { data: { token } });
  }

  logout() {
    super.deleteAuth()
  }

  isExist(id) {
    return super.doRequest('get', `/user/is/exist?idUser=${id}`)
  }

  gets() {
    return super.doRequest('get', '/users');
  }

  get(id) {
    return super.doRequest('get', `/user/${id}`);
  }

  post(data) {
    return super.doRequest('post', '/users', { data });
  }

  patch(id, data) {
    return super.doRequest('patch', `/user/${id}`, { data });
  }

  delete(id) {
    return super.doRequest('delete', `/user/${id}`);
  }

  getsFake() {
    return super.doRequest('get', '/fakeDatas/user.json');
  }
}

export default UsersService;
