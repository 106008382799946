import Vue from 'vue'
import App from './views/App.vue'
// import AuthWrapper from './AuthWrapper.vue'

import { i18n } from './plugins/translate'
import { router } from './plugins/router'
import { store } from './plugins/store';
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


/**
  https://developpement-web-facile.com/traduire-son-site-vuejs-pour-le-rendre-multi-lingue/
  https://materialdesignicons.com/
  https://dev.to/jbull328/lets-access-a-webcam-with-vue-js-3f70
  https://icon-icons.com/fr/icone/drapeau-de-royaume-uni/5800
*/
