<template lang="pug">
  .all <!--divise le code --> 
    .tl <!-- the first quarter for 3D files -->
      BorderGradient.topLeft.d-flex(noPadding)
        h3 Déposer le fichier 3D ici
        br 
        form(action="") <!-- drag and drop or search files -->
          label(for="file3D") Choisissez votre fichier 3D
            br 
            input#3Dfile(type="file", name="3Dfile")
            input.acInput(type="submit", value="Envoyer")
    .tr <!-- the second quarter for thumbnail files -->
      BorderGradient.topRight.d-flex(noPadding)
        h3 Déposer la miniature ici
        br
        form(action="") <!-- drag and drop or search files -->
          label(for="thumbnail") Choisissez votre miniature
          br
          input#thumbnail(type="file", name="thumbnail")
          input.acInput(type="submit", value="Envoyer")
    .bl <!-- the third quarter to create or delete clothes from BDD -->
      BorderGradient.d-flex.bottomLeft(noPadding ) 
        h3  Remplissage des données du vêtement
        br
        p
          label(for="garmentReference") garmentReference : 
          input.acInput(name="garmentReference" id="garmentReference" v-model="param.garmentReference")
        p
          label(for="barCode") barCode : 
          input.acInput(name="barCode" id="barCode" v-model="param.barCode")
        p
          label(for="name") name : 
          input.acInput(name="name" id="name" v-model="param.name")
        p(text="type :") type :
          label(for="top")  top :
          input(name="type" type="radio" @click="param.garmentTypeId='1'" id="top" )
          label(for="bottom")  bottom :
          input(name="type" type="radio" @click="param.garmentTypeId='2'" id="bottom")
        p 
          label(for="color") color : 
          input.acInput(name="color" id="color" v-model="param.color")
        p
          label(for="size") size : 
          input.acInput(name="size" id="size" v-model="param.size")
        p 
          label(for="wearingRecommendation") wearingRecommendation : 
          input.acInput(name="wearingRecommendation" id="wearingRecommendation" v-model="param.wearingRecommendation")
        p
          v-btn.acInput.sub(@click="send") Envoyer
        h3 Suppression des données d'un vêtement
        br
        p
          label(for="barCodeSup") barCode à supprimer : 
          input.acInput(name="barCodeSup" id="barCodeSup" v-model="barCodeSup")
        p
          v-btn.acInput.sub(@click="sup") Envoyer  
        router-link.v-btn( :to="{ name: 'Cloakroom'}") <!-- retour menu -->
          v-btn.AC
    .br <!-- the last quarter to manipulate profiles and cloakrooms -->
      BorderGradient.d-flex.bottomRight(noPadding)  <!-- interface to manipulate clothes in cloakrooms and profiles -->
        h3  Gestion vestiaires
        .bll <!-- visualisation of cloakroom and profiles -->
          BorderGradient.d-flex.bottomRight(noPadding)
            v-card.d-flex.flex-column.elevation-0( height="100%" )
              router-view
              .bgGrdientAndSecondNav.mt-auto
                .bgGradiend
                v-bottom-navigation.secondNav.d-flex.align-center.mt-auto.elevation-0
                  router-link.v-btn( :to="{ name: 'ProfilsAdmin'}")
                    v-btn
                      span {{ $t("sideBarLeft.bottomNavigation.buttons[1].name") }}
                      v-icon mdi-account
                  v-divider( vertical color="dGrey" )
                  router-link.v-btn( :to="{name: 'CloakroomAdmin'}" )
                    v-btn
                      span {{ $t("sideBarLeft.bottomNavigation.buttons[0].name") }}
                      v-icon mdi-hanger
        
        .blm <!-- visualisation of all clothes in data base -->
          BorderGradient.d-flex.bottomRight(noPadding)
            ClothesList
        .blr <!-- add or delete clothes in cloakroom -->
          BorderGradient.d-flex.bottomRight(noPadding)
            h3 Ajout du vêtement 
            br 
            br
            p Ajout du vêtement d'id {{idAdd}} au vestiaire
            input.acInput(type="input" v-model="idAdd")
            br
            v-btn.AC(@click="addCloakroom") Ajout
            br
            br 
            p Suppression du vêtement du vestiaire
            v-btn.AC(@click="set") Retrait
            

</template>

<script>
  import Vuex from "vuex";
  import BorderGradient from "@/components/_design/BorderGradient.component";
  import ClothesList from "@/components/ClothesList.component";

  export default {
    name: "AddClothes",
    components: {
      BorderGradient,
      ClothesList,
      },
    data: () => ({
      barCodeSup: "",
      idAdd: "",
      param : {
        wearingRecommendation: null,
        size: null,
        color: null,
        name: "",
        barCode: "",
        garmentReference: "",
        thumbnailUrl: null,
        garmentUrl: null,
        garmentTypeId:"1",
      },
    }),
    methods: {
      ...Vuex.mapActions({
        sendAction: `clothes/addAClothe`,
        supAction: `clothes/deleteAClotheByBarCode`,
        addClotheToCloakroom: `clothes/addClotheToCloakroom`,
        deleteCurrentClotheFromCloakroom : `clothes/deleteCurrentClotheFromCloakroom`,
      }),
      send () { //to add a clothe in dataBase
        if (this.param.barCode === "")  { console.log("error, barCode is empty"); alert("error, barCode is empty"); }
        else if (this.param.name === "") { console.log("error, name is empty"); alert("error, name is empty"); }
        else if (this.param.garmentReference === "") { console.log("error, garmentReference is empty"); alert("error, garmentReference is empty"); }
        else {
          this.sendAction(this.param);
          this.param.wearingRecommendation = null;
          this.param.size = null;
          this.param.color = null;
          this.param.name = "";
          this.param.barCode = "";
          this.param.garmentReference = "";
          this.garmentTypeId = "1";
        }
      },
      sup () { //to delete a clothe from dataBase
        if (this.barCodeSup === "") {
          console.log("error, barCodeSup is empty");
          alert("error, barCodeSup is empty")
        }
        else {
          this.supAction(this.barCodeSup);
          this.barCodeSup = "";
        }
      },
      set () { //to delete a clothe from a cloakroom
        if (this.current.value === null) {
          console.log("error, no selected clothe ");
          alert("error, no selected clothe ");
        }
        else {
          this.deleteCurrentClotheFromCloakroom();
        }
      },
      addCloakroom () { //to add a clothes to cloakroom with clothe.id
        if (this.idAdd === "") {
          console.log("error, idAdd is empty");
          alert("error, idAdd is empty");
        }
        else {
          this.addClotheToCloakroom(this.idAdd);
          this.idAdd = "";
        }
      }
    },
    computed: {
      ...Vuex.mapGetters({
        getCloakroom: `clothes/cloakroomOrderByTypeCloth`,
        current: `clothes/current`,
      })
    }
  };

</script>

<style lang="scss">

  .all {
    background-color: #1e1e1e;
    height: calc( 100vh  - 64px);
  }
  .topRight,.topLeft,.bottomLeft, .bottomRight {
    height : calc(100% - 10px);
    width : calc(100% - 10px);
    position : relative;
    left: 5px;
    top: 5px;
  }
  .tr,.tl,.br,.bl,.blr,.bll, .blm {
    height : 30%;
    width : 50%;
    position : relative;
    float:left;
  }
  .tr,.br, .blr {
    float:right;
  }
  .br,.bl {
    height: 70%;
  }
  .blr,.bll, .blm {
    width:33%;
    height:95%;
  }
  .blm {
    display:inline-block;
  }
  .acInput {
    border-style: solid;
    border-color:black;
  }
  .sub {
    position: relative;
    left:45%;
  }
</style>