<template lang="pug">
v-card#visualisation.d-flex.flex-column(dark, tile)
  .videoFeature.d-flex.justify-center.align-center
    //- img.videoOn(ref="myVisualisationStream")
    video.videoOn(ref="myVisualisationStream",autoplay, controls=false)

  v-card.modalOptionVideo.ma-2.elevation-0.d-flex.align-center.justify-center.pb-6

    //- v-card-title {{ $t("visualisation.options.title") }}


    v-overlay(:value="overlay")
      .container 
        h3.title {{ $t('source.title') }}
        v-checkbox(:label="`${$t('source.autoReplay')}`" v-model="form.autoReplay" :color="colors.magenta")
        div(style="display: flex; align-items: center;")
          v-checkbox(:label="`${$t('source.ref')}`" v-model="form.replayAsRef" :color="colors.magenta") 
          v-tooltip(top)
            template(#activator="{ on }")
              v-icon(small style="margin-left: 10px;" v-on="on") mdi-information-outline
            span {{$t('source.info')}}
        
        v-radio-group(v-model="form.selectedSource" v-if="!form.replayAsRef") 
          p {{ $t('source.sourceChoice') }}
          v-radio(v-for="(source, index) in form.sourcesList" :key="index" :label="source" :value="source" :color="colors.magenta")
        
        p.close(@click="toggleOverlay()") {{ $t('source.close') }}

    v-card-actions.d-flex.flex-column
      .mb-3
        v-btn(:disabled="isDisabled" v-if="isVideoOn", @click="toggleIsVideoOn")
          v-icon mdi-video
        v-btn(v-else, @click="toggleIsVideoOn")
          v-icon mdi-video-off
        //- v-btn.mx-3(x-large color="red" @click="startVisualisation")
        v-btn.mx-3(:disabled="isDisabledRec",x-large, color="red"  @click="triggerPoseEvent")
          v-icon(x-large) mdi-record-rec
        v-btn(@click="toggleOverlay()")
          v-icon mdi-dots-vertical
      div
        v-btn.endOfSession(@click="endOfSessionAction")
          | {{ $t('visualisation.endVisualisation') }}
  v-card#selfVideo
    video(ref="myStream")
</template>

<script>
import Vuex from "vuex";

import Button from "@/components/_actions/Button.component";
// import SignalingClient from '@/services/visualisation/signaling.service'

export default {
  name: "Visualisation",

  
  components: { Button },
  data: () => {
    const sourcesList = ['HP Omen 15', 'Iphone 14']
    return {
      overlay: false,
      showInfo: false,
      adjustment: 45,
      showAdjustment: false,

      xyMove: { x: 50, y: 50 },
      xyZoom: { x: 50, y: 50 },

      form: {
        sourcesList,
        selectedSource: sourcesList[0],
        autoReplay: true,
        replayAsRef: false,
      },

      colors: {
        bad: '#E84B65',
        good: '#00F6A8',
        magenta: '#ff00ff',
        black: '#000000',
        blackalt: '#010220',
        white: '#FFFFFF',
      },


      inVideoRef: null,
      outVideoRef: null,
      isVideoOn: false,//correspond à l'image de la camera barré ou non
      isDisabled: false,//mettre a true si on veut pouvoir activer le bouton dès le debut
      isDisabledRec: true,//mettre a true si on veut pouvoir activer le bouton rec dès le debut
      optionsButton: {
        fab: false,
        top: false,
        bottom: true,
        right: false,
        left: true,
        direction: "top",
        hover: false,
        transition: false,
       },
    }
  },

  methods: {
    ...Vuex.mapActions({
      deleteReplays: "users/profils/replays/sessions/deleteReplays",
      initCommunication: "communication/initCommunication",
      sendMorphoData: "communication/sendMorphoData", 
      sendPoseData: "communication/sendPoseData", 
      sendPoseRequest: "communication/sendPoseRequest",
      disconnectSocket: "communication/disconnectSocket",
      stopStreamSoct: "communication/stopStreamSoct",
      emitClientReady: "communication/emitClientReady",
      setButtonEnable: "peer.service/setButtonEnable",
      setXyMoveNormalized: "cmdRemotePeer/setXyMoveNormalized",
      setXyZoomNormalized: "cmdRemotePeer/setXyZoomNormalized",
      stopStreamSock: "peer/stopStream",

    }),
    /*setButtonEnable(){
      this.isDisabled= false;
      this.isVideoOn=true;
    },*/

    handleClick(event) {
      // Obtenez les coordonnées du clic par rapport au coin supérieur gauche de la fenêtre du navigateur
      const clickX = event.clientX;
      const clickY = event.clientY;

      // Obtenez les coordonnées et les dimensions du coin supérieur gauche de la div
      const rect = event.currentTarget.getBoundingClientRect();
      const divX = rect.left;
      const divY = rect.top;
      const divWidth = rect.width;
      const divHeight = rect.height;

      // Calculez les coordonnées du clic en pourcentage de la div
      const x = ((clickX - divX) / divWidth) * 100;
      const y = ((clickY - divY) / divHeight) * 100;

      const xNormalized = ((clickX - divX) / divWidth) * 2 - 1;
      const yNormalized = -(((clickY - divY) / divHeight) * 2 - 1);

      if (this.settingsZoom) {
        this.xyZoom = { x: x, y: y };
        this.setXyZoomNormalized({ x: xNormalized, y: yNormalized });
        console.log(JSON.stringify(this.xyZoomNormalized));
      } else if (this.settingsMove) {
        this.xyMove = { x: x, y: y };
        this.setXyMoveNormalized({ x: xNormalized, y: yNormalized });
        console.log(JSON.stringify(this.xyMoveNormalized));
      }
    },

    toggleOverlay() {
      this.overlay = !this.overlay
      console.log(this.overlay)
    },

    displayAdjustment() {
      this.showAdjustment = true
      setTimeout(() => {
        this.showAdjustment = false
      }, 3000);
    },
    
    getInVideoRef() {
      return this.inVideoRef;
    },
    getOutVideoRef() {
      return this.outVideoRef;
    },
    getIsVideoOn() {
      return this.isVideoOn;
    },
    setInVideoRef(value) {
      this.inVideoRef = value;
    },
    setOutVideoRef(value) {
      this.outVideoRef = value;
    },
    setIsVideoOn(value) {
      this.isVideoOn = value;
    },

    toggleIsVideoOn() {
      this.isVideoOn = !this.isVideoOn;
      if (this.isVideoOn) {
        // this.startStream();
      } else {
        this.stopStreamSoct();
        this.disconnectSocket()
        this.isDisabledRec = true;
      }
    },

    async endOfSessionAction() {
      // saveReplayFavorite()
      this.peer.dataProducer.send("HOHO");
      //await this.deleteReplays();
    },

    triggerPoseEvent() {
      // alert("Starting Processing...");
      // const data = {
      //   exampleField: 'dataForMorpho',
      //   anotherField: 'moreData'
      // };
      console.log('==================poseDatas==================');
      console.log(this.poseDatas);
      console.log('====================================');
      try {
        // this.$store.getters['communication/signalingClient'].getSocket().on('consumer ready', () => {
        this.sendPoseRequest();
        this.sendPoseData(this.poseDatas)

          // this.isDisabledRec = false;
          // this.$store.getters['communication/signalingClient'].getSocket().emit("PoseRequest");
        console.log("Pose event emitted");
        // });
      } catch (error) {
        console.log('====================================');
        console.log('triggerPoseEvent AND sendPoseData ERROR: ' + error);
        console.log('====================================');
        
      }

    },

    setMediaStream(stream) {
      const video = this.$refs.myVisualisationStream;
      if (video) {
        video.srcObject = stream;

        // Add event listeners for debugging
        video.addEventListener('loadedmetadata', () => {
          console.log(`Video dimensions: ${video.videoWidth}x${video.videoHeight}`);
        });

        video.addEventListener('play', () => {
          console.log('Video playback started');
        });

        video.addEventListener('error', (event) => {
          console.error('Video playback error:', event);
        });
      } else {
        console.error('Video element not found');
      }
    },

    async startStream() {
      console.log("startStream ------");
      try {
        await this.initCommunication(this);
      } catch (error) {
        console.log('====================================');
        console.log('initCommunication ERROR: ' + error);
        console.log('====================================');
      }
      await this.emitClientReady();

      try {
        console.log('==================sendDataToMorpho==================');
        // this.sendMorphoData(data)
        this.$store.getters['communication/signalingClient'].getSocket().on('consumer ready', () => {
          // this.sendMorphoData(data);
          console.log('==================isDisabledRec==================');
          console.log(this.isDisabledRec);
          console.log('====================================');
          this.isDisabledRec = false;
        });
      } catch (error) {
        console.log('====================================');
        console.log('sendPoseData ERROR: ' + error);
        console.log('====================================');
      }
     
      
      let actualListOfCommand = JSON.parse(localStorage.getItem('commands'))

      const PoseTakingExists = actualListOfCommand.includes("PoseTaking");

      const morphoTakingExists = actualListOfCommand.includes("MorphoTaking");

      if (morphoTakingExists) {//si il y avait une commande de morpho, on l'enleve
        actualListOfCommand.shift()
        console.log('enleveCommandeMorpho')
        localStorage.setItem('commands', JSON.stringify(actualListOfCommand))
      }
      if (!PoseTakingExists) {
        actualListOfCommand.unshift("PoseTaking")
        console.log('ajoutCommandePose')
        localStorage.setItem('commands', JSON.stringify(actualListOfCommand))
      }
      
      // const data = {
      //   exampleField: 'dataForMorpho',
      //   anotherField: 'moreData'
      // };

         // Check the video resolution after a short delay to ensure the stream has started
    setTimeout(this.checkVideoResolution, 3000);
    },

    stopStream() {
      const videoElement = this.$refs.myVisualisationStream;
      const stream = videoElement.srcObject;

      // if (stream) {
        // Stop all video tracks to deactivate the camera
        stream.getTracks().forEach(track => track.stop());
        videoElement.srcObject = null;
      // }

      console.log("Camera deactivated.");
    },
    async checkVideoResolution() {

      console.log('====================================');
      console.log('Stream started.', this.peer);
      console.log('====================================');

    const video = this.$refs.myVisualisationStream;
    if (video) {
      console.log(`Video resolution: ${video.videoWidth}x${video.videoHeight}`);
      if (video.videoWidth === 1920 && video.videoHeight === 1080) {
        console.log("The video is streaming in HD.");
      } else {
        console.log("The video is not streaming in HD.");
      }
    }
  },

  // async sendDataToMorpho() {
  //   await this.peer.sendDataToMorpho();
  // }
  },
  computed: {
    ...Vuex.mapGetters({
      replayCurrent: "users/profils/replays/current",
      peer: "communication/peerClient",
      settingsZoom: "states/settingsZoom",
      settingsMove: "states/settingsMove",
      xyMoveNormalized: "cmdRemotePeer/xyMoveNormalized",
      xyZoomNormalized: "cmdRemotePeer/xyZoomNormalized",
    }),

    ...Vuex.mapState(['productList']),
    ...Vuex.mapState(['shopCustomer']),
    ...Vuex.mapState(['morphoDatas']),
    ...Vuex.mapState(['poseDatas']),

    message() {
      return this.adjustment < 40 ? "plutôt large" : this.adjustment < 60 ? "bien ajusté" : "plutôt serré"
    },

    colorBar() {
      return this.adjustment >= 40 && this.adjustment <= 60 ? this.colors.good : this.colors.bad
    }
  },
  // async mounted() {
  //   this.setInVideoRef(this.$refs.myVisualisationStream);
  //   this.setOutVideoRef(this.$refs.myStream);
  //   //this.startStream();//si on veut lancer la boucle video dès le debut

  // },
  async mounted() {
    console.log('Component mounted.');
    this.setInVideoRef(this.$refs.myVisualisationStream);
    console.log('=================this.$refs.myVisualisationStream===================');
    console.log(this.$refs.myVisualisationStream);
    console.log('====================================');
    this.setOutVideoRef(this.$refs.myStream);
    console.log('Video refs set.');
  },
  watch: {
    isVideoOn: function (val) {
      if (!val) this.stopStream();
      else this.startStream();
    },
  },
};
</script>

<style lang="scss" scoped>

$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

.container {
  display: inline-block;
  background-color: $blackalt;
  color: $white;
  padding: 33px;
  border-radius: 10px;

  .title {
    text-align: center;
  }

  .infos {
    display: inline-block;
    background-color: $black;
    padding: 5px;
    border-radius: 5px;

  }

  .close {
    text-align: end;
    cursor: pointer;
  }
}

.adjustment {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  background-color: #010220;
  padding: 10px;
  border-radius: 10px;



  .content {
    display: flex;
    align-items: center;
  }

  .content > * {
    margin-right: 10px;
    margin-top: 10px;
  }
}



#visualisation {
  width: calc(100vw / 2);
  height: calc(100vh - 56px);
  z-index: 1; //passage au premier plan des boutons
  #selfVideo {
    position: absolute;
    border: 2px solid azure;
    bottom: 10px;
    right: 10px;
    width: 200px;
    display: none; //ne pas afficher le retour vidéo éphémère
  }
  .videoFeature {
    height: 100%;
    width: 100%;
    position: relative;
  }

  @media screen and (min-width:960px) {
    video {
    height: 100%;;
    width: 60%;
    border: 2px solid white;
  }
  }

  @media screen and (max-width:960px) {
    video {
    height: 100%;;
    width: 80%;
    border: 2px solid white;
  }
  }

  .videoOff {
    width: 80%;
    height: 70%;
    .v-card__text {
      height: 100%;
    }
  }
  .v-speed-dial {
    .v-btn--fab {
      bottom: 10px;
      left: 10px;
    }
    .modalOptionVideo {
      width: calc(100% - 2 * 10px);
      height: 100%;
    }
  }
}


.test {
  background-color: $good;
  width: 600px;
  height: 450px;
}

.test-2 {
  border: #E84B65 solid 2px;
  position: relative;
}

.icon {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.axes {
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: 1;
  max-height: 100%;
  width: auto;
  transform: translateX(-50%);
  pointer-events: none;
}

</style>

