<template lang="pug">
.sceneSettings__wraper.d-flex.flex-column.align-center
  v-card-title
    span.mr-auto {{ $t('sideBarLeft.scene.title') }}
    //- v-icon(v-if="!isDecorParams", @click="toggleIsDecorParams") mdi-image
    //- v-icon(v-else, @click="toggleIsDecorParams") mdi-tune

  //- template(v-if="!isDecorParams")
  template(v-if="true")
    v-card-subtitle {{ $t('sideBarLeft.scene.subtitle[0].name') }}
    SelfSwitch.px-4(
      title="Auto Scene",
      :valuesList="getValuesList('autoScene')",
      :command="getCommandC('autoScene')",
      :onClick="setSceneCommand"
    )

    v-divider.mt-7.mb-2(width="75%")

    //- .notAutoScene.d-flex.align-center.flex-column.px-4
    .notAutoScene.d-flex.align-center.flex-column.px-4(
      :class="[`${getValue('autoScene') == 'true' ? 'disabled' : ''}`]"
    )
      SelfSwitch.mb-3(
        :title="$t('sideBarLeft.scene.naturalLight')",
        :valuesList="getValuesList('selectNaturalLight')",
        :command="getCommandC('selectNaturalLight')",
        :onClick="setSceneCommand"
      )
      template(v-if="/direct/i.test(getValue('selectNaturalLight'))")
        DirectSunligth
      template(v-else-if="/diffuse/i.test(getValue('selectNaturalLight'))")
        DiffuseDayligth

      v-divider.mt-7.mb-2(width="75%")
      
      SelfSwitch.mb-3(
        :title="$t('sideBarLeft.scene.artificialLight')",
        :valuesList="getValuesList('selectArtificialLight')",
        :command="getCommandC('selectArtificialLight')",
        :onClick="setSceneCommand"
      )
      template(v-if="getValue('selectArtificialLight') == 'true'")
        ArtificialLight
      
      template <!--TODO-->
        ExpositionLight

      v-divider.mt-7.mb-2(width="75%")

      .container
        h3.title Arrière-plan

        .bgs 

          .item(@click="setSelectedBg('no-bg')")
            .bg(:style="{ 'border': isSelected('no-bg') ? `3px solid ${colors.magenta}` : '3px solid black'}")
              v-icon.icon(size="x-large") mdi-close
            h4.description(:style="{ 'color': isSelected('no-bg') ? `${colors.magenta}` : 'black'}") Aucun
              
        
          .item(v-for="(bg, index) in backgrounds" :key="index" @click="setSelectedBg(bg)")
            img.bg(:src="require(`../../../assets/bgs/${bg}.jpg`)" :style="{ 'border': isSelected(bg) ? `3px solid ${colors.magenta}` : '3px solid black'}")
            h4.description(:style="{ 'color': isSelected(bg) ? `${colors.magenta}` : 'black'}") {{ bg }}
      
      
  template(v-else)
    v-card-subtitle {{ $t('sideBarLeft.scene.subtitle[1].name') }}
    Decor
</template>

<script>
const inflection = require("inflection");
import Vuex from "vuex";

import SelfSwitch from "@/components/_actions/Switch.component";
import DirectSunligth from "@/components/visualisation/sceneSettings/DirectSunligth.component";
import DiffuseDayligth from "@/components/visualisation/sceneSettings/DiffuseDayligth.component";
import ArtificialLight from "@/components/visualisation/sceneSettings/ArtificialLight.component";
import ExpositionLight from "@/components/visualisation/sceneSettings/ExpositionLight.component";
import Decor from "@/components/visualisation/sceneSettings/Decor.component";

export default {
  name: "SceneSettings",

  components: {

    SelfSwitch,
    DirectSunligth,
    DiffuseDayligth,
    ArtificialLight,
    ExpositionLight,
    Decor,
  },
  data: () => ({

    colors: {
      bad: '#E84B65',
      good: '#00F6A8',
      magenta: '#ff00ff',
      black: '#000000',
      blackalt: '#010220',
      white: '#FFFFFF',
    },
    backgrounds: ['bg-1', 'bg-2'],
    selectedBg: 'no-bg',

    isDecorParams: true,
  }),
  computed: {
    ...Vuex.mapGetters({
      getInfoCommandScene: "scenes/getInfoCommand",
      getCommand: "communication/commands/getCommand",
    }),




    getValuesList() {
      return (shortCommand) => {
        return this.getInfoCommandScene(shortCommand).valuesList;
      };
    },
    getValue() {
      return (shortCommand) => {
        return this.getCommand(inflection.underscore(shortCommand)).replace(
          /^.+::(true|false)$/,
          "$1"
        );
      };
    },
    getCommandC() {
      return (shortCommand) => {
        return this.getCommand(inflection.underscore(shortCommand));
      };
    },
  },
  methods: {
    ...Vuex.mapActions({
      setSceneCommand: "scenes/setSceneCommand",
    }),
    toggleIsDecorParams() {
      this.isDecorParams = !this.isDecorParams;
    },

    isSelected(bg) {
      return bg === this.selectedBg
    },

    setSelectedBg(bg) {
      this.selectedBg = bg
    }


    
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>


$magenta: #ff00ff;
$bad: #E84B65;
$good: #00F6A8;
$black: #000000;
$blackalt: #010220;
$white: #FFFFFF;

.v-divider {
  flex: none;
}
.sceneSettings__wraper {
  height: calc(100% - 58px);
  overflow-x: hidden;
  overflow-y: scroll;
  .sceneSettings__ {
    width: 90%;
    overflow: hidden;
  }
  > .v-card__title,
  > .notAutoScene {
    width: 100%;
  }
  @media (max-width: 1400px) {
    .smallt {
      font-size: 18px;
    }
  }
  @media (max-width: 1300px) {
    .smallt {
      font-size: 17px;
    }
  }
}

.container {
  display: inline-block;
  background-color: lightgray;
  padding: 10px;
  border-radius: 10px;

  .title {
    text-align: center;
  }

  .bgs {
    display: flex;
    flex-flow: row wrap;

    .item {
      display: flex;
      flex-direction: column;


      .bg {
        background-color: lightgray;
        width: 100px;
        height: 100px;
        border: $black solid 3px;
        border-radius: 10px;
        position: relative;


        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .description {
        text-align: center;
      }
    }



    > * {
      margin: 10px;
    }

  }
}
</style>

