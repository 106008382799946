

import ApiService from '../api.service';


class OwnAvatarService extends ApiService {
  constructor() {
    super()
  }


  get(idProfil) {
    return super.doRequest('get', `/own/profil/${idProfil}/avatar`);
  }

  postByTypeReplay(idProfil, data) {
    return super.doRequest('post', `/own/profil/${idProfil}/avatar`, { data });
  }

  patch(idProfil, data) {
    return super.doRequest('patch', `/own/profil/${idProfil}/avatar`, { data });
  }

  delete(idProfil) {
    return super.doRequest('delete', `/own/profil/${idProfil}/avatar`);
  }
}

export default OwnAvatarService;