<template lang="pug">
.all
  BorderGradient.all
    h3.title {{ $t('authWrapper.Connection') }}
    br
    span {{ $t('authWrapper.Redirection') }}
    a(href="https://www.modalive.fr") Modalive.fr
</template>

<script>
import Vuex from "vuex";
import BorderGradient from "@/components/_design/BorderGradient.component";

export default {
  components: {
    BorderGradient,
  },

  data: () => ({}),

  props: {
    accessToken: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...Vuex.mapGetters({
      initData: "initData",
      isLogin: "users/isLogin",
    }),
  },

  methods: {
    ...Vuex.mapActions({
      initialisation: "initialisation",
    }),
  },

  async beforeMount() {
    console.log(window)
    console.log('====================================');
    console.log('====================================');
    console.log(this.accessToken);
    console.log('====================================');
    console.log('====================================');
    const accessToken = this.accessToken
      ? this.accessToken
      : localStorage.getItem("accessToken");

      console.log("--------this.isLogin-------",this.isLogin)

    await this.initialisation(accessToken);
    if (this.initData) {
      if (this.isLogin) this.$router.push({ name: "Visualisation" });
      else this.$router.push({ name: "Login" });
    }
  },
};
</script>


<style lang="scss">
.title {
  text-align: center;
}
</style>


