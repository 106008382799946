import utils from "@/utils/utils";

import sessions from '@/plugins/store/modules/users/profils/replays/sessions';


const state = {
  reference: {
    isAutoSelect: true,
    ref: {}
  },
  // reference: {},
  replay: {},
  replays: {
    saved: []
  },
  modeReplayRef: false, // false: modeSelectReplay | true: modeSelectReference
  // etatAutoSelect: null, // null: noValue  | 0: noAutoSelect | 1: autoSelect
  // selectRef: null, // null: noValue  | 0: noAutoSelect | 1: autoSelect
  // etatAutoSelect: null, // null: noValue  | 0: noAutoSelect | 1: autoSelect
};

const getters = {
  /** REPLAYS */
  savedList: (state) => state.replays.saved,
  newestsavedReplay: (state) => (utils.sorting(state.replays.saved, 'desc', { key: 'createdAt' }))[0],

  /** REPLAY */
  current: (state) => state.replay,

  /** REFERENCE */
  reference: (state) => state.reference,

  // isAutoSelect: (state) => state.etatAutoSelect,
  isAutoSelect: (state) => state.reference.isAutoSelect,
  modeReplayRef: (state) => state.modeReplayRef
};

const mutations = {
  /** REPLAYS */
  // ADD_REPLAY_TO_REPLAYS: (state, replay) => {
  //   state.replays.push(replay);
  //   state.replays = utils.sorting(state.replays, 'desc', { key: 'createdAt' })
  // },
  SET_SAVED_REPLAYS: (state, replays) => {
    state.replays.saved = replays
    state.replays.saved = state.replays.saved && state.replays.saved.length > 0
      ? utils.sorting(state.replays.saved, 'desc', { key: 'createdAt' })
      : []
  },

  /** REPLAY */
  // SET_EMPTY_REPLAY: (state) => { state.replay = {} },
  // SET_CURRENT_REPLAY: (state, replay) => { state.replay = replay },

  /** REFERENCE */
  TOGGLE_AUTO_REFERENCE: (state) => { state.reference.isAutoSelect = !state.reference.isAutoSelect },
  SET_EMPTY_REFERENCE: (state) => { state.reference = { isAutoSelect: true, ref: {} } },
  SET_CURRENT_REFERENCE: (state, ref) => { state.reference.ref = ref },
  UNSET_CURRENT_REFERENCE: (state) => {
    state.reference.ref.isReference = false
    state.replays = utils.updateItemOfArray(state.sessions.replays, state.reference.ref, { key: 'createdAt' })
  },

  TOGGLE_MODE_REPLAY_REF: (state) => { state.modeReplayRef = !state.modeReplayRef },
  TOGGLE_MODE_REPLAY_REF_OFF: (state) => { state.modeReplayRef = false },
};


const actions = {
  // addAReplay: async (state) => {
  //   const replay = {
  //     name: utils.dateToCompactString(new Date()),
  //     createdAt: utils.dateToISOString(new Date())
  //   }
  //   const isAutoSelectRef = state.getters['isAutoSelect']
  //   const refCurrent = state.getters['reference']
  //   if (isAutoSelectRef) {
  //     replay.isReference = true;
  //     if (Object.keys(refCurrent.ref).length > 0) state.commit('UNSET_CURRENT_REFERENCE')

  //     await state.dispatch('postReplay', replay)
  //     state.commit('SET_CURRENT_REFERENCE', replay)
  //   } else replay.isReference = false

  //   state.commit('ADD_REPLAY_TO_REPLAYS', replay)
  // },
  // setListSessionReplays: (state, replays) => {
  //   state.commit('SET_EMPTY_REPLAY')
  //   state.commit('SET_EMPTY_REFERENCE')
  //   state.commit('SET_REPLAYS', replays)
  // },
  // setCurrentReplay: (state, replay) => { state.commit('SET_CURRENT_REPLAY', replay) },
  setCurrentReferenceByArray: (state, replays) => {
    const newRef = replays[0]
    const { ref: oldRef } = state.getters['reference']

    if (oldRef) {
      if (newRef.createdAt != oldRef.createdAt) {
        newRef.isReference = true;
        if (Object.keys(oldRef).length > 0) state.commit('users/profils/replays/UNSET_CURRENT_REFERENCE', null, { root: true })
      }
    }
    state.commit('users/profils/replays/SET_CURRENT_REFERENCE', newRef, { root: true })
  },
  setCurrentReference: (state, ref) => {
    const newRef = ref
    const { ref: oldRef } = state.getters['reference']

    if (oldRef) {
      if (newRef.createdAt != oldRef.createdAt) {
        newRef.isReference = true;
        if (Object.keys(oldRef).length > 0) state.commit('users/profils/replays/UNSET_CURRENT_REFERENCE', null, { root: true })
      }
    }
    state.commit('users/profils/replays/SET_CURRENT_REFERENCE', newRef, { root: true })
  },
  toggleAutoReference: (state) => {
    state.commit('TOGGLE_AUTO_REFERENCE')
    const stateAutoRef = state.getters['isAutoSelect']
    if (stateAutoRef) {
      const newestReplay = state.rootGetters['users/profils/replays/sessions/newestReplay']
      state.dispatch('setCurrentReference', newestReplay)
    }
  },
  toggleModeReplayRef: (state) => { state.commit('TOGGLE_MODE_REPLAY_REF') },
  toggleModeReplayRefOff: (state) => { state.commit('TOGGLE_MODE_REPLAY_REF_OFF') },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    sessions
  }
};