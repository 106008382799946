const state = {
  framings: {
    namespace: 'Framing',
    key: 'setFraming',
    defaultValue: 'full_body',
    value: null,
    valuesList: [
      { name: "Entier", img: "", command: { key: "setFraming", value: "whole" } },
      { name: "En pied", img: "", command: { key: "setFraming", value: "full_body" } },
      { name: "Haut", img: "", command: { key: "setFraming", value: "top_body" } },
      { name: "Bas", img: "", command: { key: "setFraming", value: "bottom_body" } }
    ]
  },
};

const getters = {
  list: state => state.framings.valuesList,
  framings: state => state.framings
};

const mutations = {};


const actions = {
  selectFraming: (state, framing) => {
    console.log(framing)
    state.dispatch('communication/commands/updateListOfCommand', [framing.command], { root: true })
    state.dispatch('communication/commands/sendCommand', null, { root: true })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};