<template lang="pug">
#clothParams__bottoms.clothParams.px-3
  v-card-title {{ cloth.name }}
  v-card-subtitle Ref: {{ cloth.garmentReference }}
  v-card-actions.d-flex.flex-column
    template(v-for="wearingParam in cloth.wearingParams")
      .clothParam
        div 
          span.pl-8 {{ wearingParam.name }}
          span.pl-3(v-if="wearingParam.params.isLeftAndRigthLinked")
            v-icon.mt-n2(small, @click="toggleLinked(wearingParam)") mdi-lock
          span.pl-3(v-else)
            v-icon.mt-n2(small, @click="toggleLinked(wearingParam)") mdi-lock-off
        template(v-if="wearingParam.params.isLeftAndRigthLinked")
          ParamsSlider.leftAndRightTogether(
            :label="`${$t('sideBarRight.Params.L&R')} ${wearingParam.params.both} cm`",
            side="both",
            :wearingParam="wearingParam"
          )
        template(v-else)
          ParamsSlider.leftSeparateRight(
            :label="`${$t('sideBarRight.Params.R')}: ${wearingParam.params.right} cm`",
            side="right",
            :wearingParam="wearingParam"
          )
          ParamsSlider.leftSeparateRight(
            :label="`${$t('sideBarRight.Params.L')}: ${wearingParam.params.left} cm`",
            side="left",
            :wearingParam="wearingParam"
          )
      v-divider.mt-7.mb-5(width="75%")
  Button.undressBtn(:onClick="undressCloth")
    span {{$t('sideBarRight.removeClothe')}}
</template>

<script>
import Vuex from "vuex";
import Button from "@/components/_actions/Button.component";
import ParamsSlider from "@/components/_actions/ParamsSlider.component";

export default {
  name: "BottomsParams",

  components: { ParamsSlider, Button },
  props: {},
  data: () => ({}),
  methods: {
    ...Vuex.mapActions({
      toggleLinked: "clothes/toggleLinked",
      undressCloth: "clothes/undressCloth",
    }),
  },
  computed: {
    ...Vuex.mapGetters({
      cloth: "clothes/current",
    }),
  },
};
</script>

<style lang="scss">
.clothParams {
  .clothParam {
    width: 100%;
    span {
      text-transform: capitalize;
    }
    .leftSeparateRight {
      .caption {
        padding: 0 0 0 0;
      }
    }
    .leftAndRightTogether {
      .caption {
        padding: 0 10px 0 0;
        line-height: 15px;
      }
    }
  }
}
</style>
