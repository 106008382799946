<template lang="pug">
v-card.borderGradient.elevation-0(
  :class="[fab ? 'rounded-circle' : 'rounded-lg', forButton ? 'pa-1bis' : 'pa-1']"
)
  v-card.elevation-0.opacity(
    :class="[noPadding ? '' : 'pa-8 px-16', fab ? 'rounded-circle' : 'rounded-lg']",
    color="white",
    width="100%",
    height="100%"
  )
    slot
</template>

<script>
export default {
  name: "BorderGradient",
  props: {
    noPadding: {
      type: Boolean,
    },
    fab: {
      type: Boolean,
    },
    forButton: {
      type: Boolean,
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.pa-1bis {
  padding: 2px !important;
}
.opacity {
  opacity: 0.98;
}
.borderGradient {
  background: linear-gradient(
    135deg,
    #00ffff 0%,
    #ff00ff 33%,
    #ff48b7 67%,
    #ffff00 100%
  );
}
</style>
